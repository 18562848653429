<template>
    <div class="offer-list-banner">
        <template v-if="isGameBanner || isSpecialOperationBanner">
            <template v-if="isGameBanner">
                <div class="offer-list-banner-image-container cursor-pointer" onclick="showGameV5();">
                    <picture>
                        <source media="(min-width: 768px)" type="image/jpeg"
                                :srcset="assetManager.getImageAsset('game/' + getActiveGameSlug + '/website/banner/offer-list-banner-desktop.jpg')">
                        <img class="offer-list-banner-image"
                             :src="assetManager.getImageAsset('game/' + getActiveGameSlug + '/website/banner/offer-list-banner-mobile.jpg')">
                    </picture>
                </div>
            </template>

            <template v-if="isSpecialOperationBanner">
                <div class="offer-list-banner-image-container">
                    <picture>
                        <source media="(min-width: 768px)" type="image/jpeg"
                                :srcset="assetManager.getAssetFromDisk('marketing_files_bucket', getBannerPromoPath(getActiveSpecialOperation.encart_name))">
                        <img class="offer-list-banner-image"
                             :src="assetManager.getAssetFromDisk('marketing_files_bucket', getBannerPromoPath(getActiveSpecialOperation.list_banner_img_name))">
                    </picture>
                </div>
            </template>
        </template>

        <template v-else-if="isVoyanceByChatBanner">
            <div class="offer-list-banner-voyance-by-chat">
                <div class="banner-col-left">
                    <img :src="assetManager.getImageAsset('offer-list/voyance_by_chat_banner.png')"/>
                </div>
                <div class="banner-col-right">
                    <p class="banner-text" v-html="trans.get('offer-list.voyance_by_chat_banner_title')"/>
                </div>
            </div>
        </template>

        <template v-else-if="isDefaultBanner">
            <div class="offer-list-banner-default"
                 :style="{'background-image': 'url(' + assetManager.getImageAsset('offer-list/default_banner.jpg') + ')'}">
                <div class="banner-col-left" v-html="defaultBannerTitle"/>
                <div class="banner-col-right" v-html="trans.get('offer-list.default-banner-subtitle')"/>
            </div>
        </template>

        <template v-if="isPrimeOfferList">
            <div class="offer-list-banner-prime-list">
                <div class="banner-col-left">
                    <img class="max-w-8 mx-1"
                         :src="assetManager.getImageAsset('prime/gift.png')"
                         :alt="primeAdvantageTitle">
                </div>
                <div class="banner-col-right">
                    <h4 class="banner-title">{{ primeAdvantageTitle }}</h4>
                    <p class="banner-description">{{ primeAdvantageDescription }}</p>
                    <base-link class="banner-link" :href="ziggy_route('astro')">
                        {{ trans.get('prime.banner.back_link.text') }}
                    </base-link>
                </div>
            </div>
        </template>
    </div>
</template>

<style lang="scss">
.offer-list-banner {
    @apply px-4 md:px-2;

    &-default {
        @apply bg-center bg-cover bg-no-repeat shadow-small content-center grid grid-cols-2 h-20 px-2 rounded-md text-offerlist-banner w-full mb-4;

        .banner-col-left {
            @apply text-2xl leading-6 text-left self-center pr-6 lg:pl-4 lg:text-3xl;
        }

        .banner-col-right {
            @apply text-lg leading-4.5 lg:text-2xl lg:leading-6 pl-7 lg:pl-4 font-bold text-right self-center;

            sup {
                @apply text-xs;
            }
        }
    }

    &-image-container {
        @apply relative overflow-hidden rounded-md shadow-small mb-4 h-20 w-full;
    }

    &-image {
        @apply h-full absolute left-1/2 max-w-none transform -translate-x-1/2;
    }

    &-voyance-by-chat {
        @apply bg-white flex items-center shadow-small p-3 my-4 rounded-md;

        .banner-col-left {
            @apply flex-none pr-3;

            img {
                @apply h-16 md:h-20 lg:h-24;
            }
        }

        .banner-col-right {
            @apply flex-grow;

            .banner-text {
                @apply text-primary text-base lg:text-3xl font-medium;
            }
        }
    }

    &-prime-list {
        @apply bg-white shadow-small p-2.5 my-4 rounded-md;

        .banner-col-left {
            @apply table-cell align-middle pr-2.5;
        }

        .banner-col-right {
            @apply table-cell w-full align-top overflow-hidden;

            .banner-title {
                @apply text-xl font-medium mt-0 mb-1 text-dark uppercase;
            }

            .banner-description {
                @apply text-sm text-main mb-1;
            }

            .banner-link {
                @apply text-sm text-main mb-1;
            }
        }
    }
}
</style>


<script>
import {mapGetters} from "vuex";
import CategoryMixin from "../categories/CategoriesMixin";
import BaseLink from "../elements/BaseLink";

export default {
    name: "OfferListBanner",
    components: {BaseLink},
    props: {
        offerListType: String,
        categoryId: Number,
        timeLeftPrimePromotion: {
            type: String,
            default: '',
        },
    },
    mixins: [CategoryMixin],
    methods: {
        getBannerPromoPath(img) {
            return '/img_promos/' + this.getActiveSpecialOperation.service_id + '/' + this.getActiveSpecialOperation.id + '/' + img;
        }
    },
    computed: {
        ...mapGetters('game', ['getActiveGameSlug']),
        ...mapGetters('special_operation', ['getActiveSpecialOperation']),
        ...mapGetters('user', ['getLoggedUserInfos']),
        isGameBanner() {
            return !this.isPrimeOfferList && this.getActiveGameSlug && this.getLoggedUserInfos.id;
        },
        isSpecialOperationBanner() {
            return !this.isPrimeOfferList
                && this.getActiveSpecialOperation !== undefined
                && this.isAstrologyCategory()
                && this.getActiveSpecialOperation.encart_name !== ''
                && this.getActiveSpecialOperation.list_banner_img_name !== '';
        },
        isVoyanceByChatBanner() {
            return this.offerListType === 'voyance_by_chat';
        },
        isDefaultBanner() {
            return !this.isPrimeOfferList && !this.getLoggedUserInfos.id && this.isAstrologyCategory()
                || this.config.get('offer-lists.display_astro_banner_on_all_lists');
        },
        defaultBannerTitle() {
            let customKey = 'offer-list.custom-banner-title.' + this.categoryId;
            if (this.trans.get(customKey) !== customKey) {
                return this.trans.get(customKey);
            }
            return this.trans.get('offer-list.default-banner-title');
        },
        isPrimeOfferList() {
            return this.offerListType === 'prime_promotion'
                || this.offerListType === 'prime_free_call'
                || this.offerListType === 'prime_free_call_masters_never_consulted'
                || this.offerListType === 'prime_free_chat';
        },
        primeAdvantageTitle() {
            return this.trans.get('prime.banner.' + this.offerListType + '.title');
        },
        primeAdvantageDescription() {
            if (this.offerListType === 'prime_promotion') {
                return this.trans.get('prime.banner.' + this.offerListType + '.description', {
                    time_left_in_hours: this.timeLeftPrimePromotion
                });
            }
            return this.trans.get('prime.banner.' + this.offerListType + '.description');
        },
    },
}
</script>
