import ChatMixin from "../chat/ChatMixin";
import {ziggy_route} from "../ZiggyMixin";
import ValidationLang from "../ValidationLang";
import requests from "../chat/requests";

export default {
    namespaced: true,

    state: () => ({
        show: false,
        loading: false,
        additionalParams: {},
        emailCatcherTriggered: false,
    }),

    mutations: {
        show(state, name) {
            state.show = name;
        },
        hide(state) {
            state.show = false;
        },
        addParam(state, param) {
            Vue.set(state.additionalParams, param.name, param.param);
        },
        resetParam(state, paramName) {
            Vue.set(state.additionalParams, paramName, null);
        },
        changeLoadingState(state, etat) {
            state.loading = etat;
        },
        resetAllParams(state) {
            state.additionalParams = {};
        },
        emailCatcherTriggered(state) {
            state.emailCatcherTriggered = true;
        },
    },

    actions: {
        openModal({commit}, modal) {
            if (modal.param !== undefined) {
                commit('addParam', {'name': modal.name, 'param': modal.param});
            }

            commit('show', modal.name);
        },
        closeModal({commit}) {
            commit('hide');
        },
        closeModalAndReload({commit}) {
            commit('hide');
            document.location.reload();
        },
        openModalMessage({commit}, param = undefined) {
            let name = globals.MESSAGE;
            if (param !== undefined) {
                commit('addParam', {'name': name, 'param': param})
            }
            commit('show', name);
        },
        openModalImage({commit}, param = undefined) {
            let name = globals.IMAGE;
            if (param !== undefined) {
                commit('addParam', {'name': name, 'param': param})
            }
            commit('show', name);
        },
        setModalIsLoading({commit}, statusLoading) {
            commit('changeLoadingState', statusLoading);
        },
        disconnect({commit}) {
            commit('hide');
            commit('changeLoadingState', false);
            commit('resetAllParams');
        },
        initEmailCatcher({commit, dispatch, getters}) {
            let openEmailCatcher = () => {
                let modalV4 = document.getElementById("popup");
                let isModalV4AlreadyOpen = typeof (modalV4) != 'undefined' && modalV4 != null;

                if (
                    window.globalVue.config.get('app.email_catcher_enabled') === true &&
                    window.email_catcher_enabled === 'true' &&
                    (ChatMixin.methods.meFromUserStore().id === null || ChatMixin.methods.meFromUserStore().id === undefined) &&
                    !getters.isEmailCatcherTriggered &&
                    !getters.isModalAlreadyOpen &&
                    !isModalV4AlreadyOpen
                ) {
                    document.cookie = "emailCatcher=triggered";
                    commit('emailCatcherTriggered');
                    dispatch('openModal', {
                        name: globals.EMAIL_CATCHER,
                    });
                }
            }

            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                let idleTime = 10000;
                let idleInterval = setInterval(openEmailCatcher, idleTime);

                document.addEventListener('scroll', () => {
                    clearInterval(idleInterval);
                    idleInterval = setInterval(openEmailCatcher, idleTime);
                });

                document.addEventListener('keydown', () => {
                    clearInterval(idleInterval);
                    idleInterval = setInterval(openEmailCatcher, idleTime);
                });

                document.addEventListener('mousemove', () => {
                    clearInterval(idleInterval);
                    idleInterval = setInterval(openEmailCatcher, idleTime);
                });
            } else {
                document.addEventListener("mouseout", (event) => {
                    if (event.clientY <= 0) {
                        openEmailCatcher();
                    }
                });
            }
        },
        forceDisplayPopup({dispatch, rootGetters}, popup_name) {
            switch (popup_name) {
                case 'birthday_coupon':
                    dispatch('openModal', {
                        name: globals.BIRTHDAY_COUPON,
                        param: {
                            birthday_coupon: rootGetters['coupons/getCoupon'],
                        },
                    });
                    break;
                case 'whatsapp_catcher':
                    dispatch('openModal', {name: globals.WHATSAPP_CATCHER});
                    break;
                case 'too_many_failed_pay_per_use_payment_attempts':
                    dispatch('openModal', {name: globals.FAILED_PAY_PER_USE_PAYMENT});
                    break;
                default:
                    break;
            }
        },
        openModalLog({}, type) {
            axios.post(ziggy_route('popup.log'), {
                type: type,
            });
        },
        loadClientInformation({commit, getters})
        {
            requests.getClientInformation(getters.getSelectedParticipants, (ClientInformationRessource) => {
                commit('setClientInformation', ClientInformationRessource);
            });
        },
    },

    getters: {
        isShown: (state) => (name) => {
            return state.show === name;
        },
        getLoading: (state) => {
            return state.loading
        },
        getParam: (state) => {
            return state.additionalParams !== undefined ? state.additionalParams : null;
        },
        isEmailCatcherTriggered: (state) => {
            let cookie = document.cookie.match(/^(.*;)?\s*emailCatcher\s*=\s*[^;]+(.*)?$/);
            return state.emailCatcherTriggered || cookie !== null;
        },
        isModalAlreadyOpen: (state) => {
            return state.show !== false;
        },
    },
};
