<template>

    <base-alert
        v-if="this.meFromUserStore().is_master && this.interlocutor().type === 'client' && !this.hasLiveSessionBillingStarted && this.isLiveConversationInProgress"
        elevated text-center :delay="10000" class="mt-5 mx-2 md:mx-0">
        <font-awesome-icon :icon="['fas', 'circle-exclamation']" class="text-primary" />
        <template v-if="this.hasPrimeFreeChatAdvantage()">
            {{ trans.get('chat.user_use_free_chat_prime') }}
        </template>
        <template v-else>
            {{ trans.get('chat.the_user_will_be_billed_from_the_first_message', {name: this.interlocutor().name}) }}
        </template>
    </base-alert>

</template>
<script>
import ChatMixin from './ChatMixin';
import BaseAlert from '../elements/BaseAlert';
import {mapGetters} from 'vuex';

export default {
    name: 'ConversationMessagesTooltip',
    components: {BaseAlert},
    mixins: [ChatMixin],
    computed: {
        ...mapGetters('chat', [
            'hasLiveSessionBillingStarted',
            'isLiveConversationInProgress',
            'getChatLiveSession',
        ]),
    },
};
</script>
