<template>
    <base-modal>
        <modal-heading>
            {{ trans.get('generic.modals.conversation_report.report_a_problem') }}
        </modal-heading>

        <div v-if="!postFormSucceed">
            <form @submit="postForm">

                <modal-paragraph>
                    <p class="font-smaller">
                        {{ trans.get('generic.modals.conversation_report.problem_with_this_consultation') }}
                    </p>
                </modal-paragraph>

                <modal-paragraph>
                    <base-select v-model="subject"
                                 :placeholder="trans.get('generic.modals.conversation_report.reason_for_report')"
                                 :label="trans.get('generic.modals.conversation_report.reason_for_report')"
                                 :items="subjectItems"
                                 :error="subjectError"
                                 block outlined>
                    </base-select>
                </modal-paragraph>

                <modal-paragraph>
                    <base-textarea
                        v-model="message"
                        outlined
                        :placeholder="trans.get('generic.modals.conversation_report.optional_message')"
                        :label="trans.get('generic.modals.conversation_report.optional_message')"
                        :rows="5"
                        :max-length="1000">
                    </base-textarea>
                </modal-paragraph>

                <div class="grid grid-cols-2 gap-4 mt-5">
                    <base-button type="button" outlined @click="closeModal">
                        {{ trans.get('generic.cancel') }}
                    </base-button>
                    <base-button type="submit" :loading="formLoading">
                        {{ trans.get('generic.validate') }}
                    </base-button>
                </div>
            </form>
        </div>

        <div v-else>
            <modal-paragraph>
                {{ trans.get('generic.modals.conversation_report.the_problem_has_been_reported') }}
            </modal-paragraph>

            <base-button autofocus block @click="closeModal">{{ trans.get('generic.close') }}</base-button>
        </div>
    </base-modal>
</template>

<script>
import ModalParagraph from "./elements/ModalParagraph";
import BaseButton from "../elements/BaseButton";
import ModalHeading from "./elements/ModalHeading";
import {mapActions, mapGetters} from "vuex";
import BaseSelect from "../elements/BaseSelect";
import BaseTextarea from "../elements/BaseTextarea";
import ValidationLang from "../ValidationLang";

export default {
    name: "ModalConversationReport",
    components: {BaseTextarea, BaseSelect, ModalParagraph, BaseButton, ModalHeading},
    computed: {
        ...mapGetters('modals', ['getParam']),
    },
    methods: {
        ...mapActions('modals', ['closeModal']),
        postForm(e) {
            e.preventDefault();

            this.subjectError = '';

            this.formLoading = true;
            axios.post('/conversation/report', {
                subject: this.subject,
                message: this.message,
                conversation_id: this.conversation_id,
                conversation_type: this.conversation_type
            }).then((res) => {
                this.postFormSucceed = true;
            }).catch((errorResponse) => {
                let errorsInResponse = errorResponse.response.data.errors;
                let validationLang = new ValidationLang();
                let validationErrorsMap = validationLang.getMessageTranslated(errorsInResponse);
                this.subjectError = validationErrorsMap.get('subject').join('<br/>');
            }).finally(() => {
                this.formLoading = false;
            });
        },
    },
    data() {
        return {
            subject: null,
            subjectItems: [
                {
                    name: this.trans.get('generic.modals.conversation_report.suspicion_of_fraudulent_customer'),
                    value: 'suspicion_of_fraudulent_customer'
                },
                {
                    name: this.trans.get('generic.modals.conversation_report.inappropriate_behavior'),
                    value: 'inappropriate_behavior'
                },
                {
                    name: this.trans.get('generic.modals.conversation_report.customer_on_answering_machine'),
                    value: 'customer_on_answering_machine'
                },
                {
                    name: this.trans.get('generic.modals.conversation_report.network_problem'),
                    value: 'network_problem'
                },
                {
                    name: this.trans.get('generic.modals.conversation_report.minor_person'),
                    value: 'minor_person'
                },
                {
                    name: this.trans.get('generic.modals.conversation_report.incorrect_date_of_birth'),
                    value: 'incorrect_date_of_birth'
                },
                {
                    name: this.trans.get('generic.modals.conversation_report.other'),
                    value: 'other'
                },
            ],
            message: '',
            subjectError: '',
            conversation_id: null,
            conversation_type: null,
            postFormSucceed: false,
            formLoading: false,
        }
    },
    mounted() {
        this.conversation_id = this.getParam['conversation-report']['conversation_id'];
        this.conversation_type = this.getParam['conversation-report']['conversation_type'];
    },
}
</script>
