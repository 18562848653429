<template>
    <base-modal :logo="'global/logo_prime.svg'">
        <base-title>
            {{ trans.get('chat.modals.start_free_prime_chat_live_activation_confirm_title') }}
        </base-title>

        <div class="flex py-4">
            <div class="flex-none">
                <font-awesome-icon :icon="['far', 'messages']" class="text-star text-4xl mr-4"/>
            </div>

            <div class="flex-grow">
                <base-title :level="3">
                    {{ trans.get('chat.modals.prime_advantage_free_chat') }}
                </base-title>

                <base-paragraph>
                    {{ trans.get('chat.modals.prime_advantage_free_chat_description') }}
                </base-paragraph>
            </div>
        </div>

        <base-button color="prime" @click="startChat" :loading="this.loading" block>
            {{ trans.get('chat.modals.start_chat_live_button_label') }}
        </base-button>
    </base-modal>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import ChatMixin from '../chat/ChatMixin';
import {ziggy_route} from '../ZiggyMixin';
import BaseButton from '../elements/BaseButton';
import BaseTitle from "../elements/BaseTitle";
import BaseParagraph from "../elements/BaseParagraph";

export default {
    name: 'ModalChatConfirmationForPrimeFreeLiveChat',
    components: {
        BaseParagraph,
        BaseTitle,
        BaseButton
    },
    mixins: [ChatMixin],
    computed: {
        ...mapGetters('modals', ['getParam']),
        billingTypeIsPPU() {
            return this.billing_type === 'pay_per_use';
        },
    },
    methods: {
        ...mapActions('chat', ['startLiveChat', 'loadConversations']),
        ...mapActions('payment', ['loadPaymentInfo']),
        ...mapActions('modals', ['closeModal', 'openModal', 'setModalIsLoading']),
        async startChat() {
            let valid = true;

            if (this.billingTypeIsPPU) {
                if (this.general_conditions_value === true) {
                    this.general_conditions_error = '';
                } else {
                    this.general_conditions_error = this.trans.get('validation.general_conditions.required');
                    valid = false;
                }

                if (this.right_of_withdrawal_value === true) {
                    this.right_of_withdrawal_error = '';
                } else {
                    this.right_of_withdrawal_error = this.trans.get('validation.right_of_withdrawal.required');
                    valid = false;
                }
            }

            if (valid === true) {
                this.loading = true;

                let participants = [
                    this.meFromUserStore(),
                    this.offer,
                ];

                this.closeModal();
                await this.loadConversations();

                await this.loadPaymentInfo();
                await this.startLiveChat({
                    participants: participants,
                    billing_type: this.billing_type,
                    billing_advantage: 'prime_free_chat'
                });

                this.loading = false;
            }
        },
    },
    data() {
        return {
            offer: {},
            available_credits: 0,
            billing_type: 'credit',
            loading: false,
        };
    },
    async mounted() {
        this.setModalIsLoading(true);

        await axios.get(ziggy_route('chat.offer.get', {
            offerId: this.getParam['chat-confirmation-for-prime-free-live-chat']['offerId'],
        })).then(response => {
            this.offer = response.data.data;
            this.available_credits = this.getParam['chat-confirmation-for-prime-free-live-chat']['available_credits'];
        })
            .catch(error => {
                this.openModal({
                    name: globals.GENERIC_ERROR,
                });
            });

        this.setModalIsLoading(false);
    },
};
</script>
