<template>
    <component :is="type" :class="classes">
        <slot></slot>
    </component>
</template>

<script>
export default {
    name: "BaseTitle",
    props: {
        level: {
            type: Number,
            default: 1,
            validator: function (value) {
                return [1, 2, 3, 4, 5, 6].indexOf(value) !== -1
            }
        },
        color: {
            type: String,
            default: 'primary',
            validator: function (value) {
                return ['primary', 'secondary', 'available', 'busy', 'neutral', 'light', 'white', 'danger', 'prime', 'whatsapp'].indexOf(value) !== -1;
            },
        },
        center: Boolean,
    },
    computed: {
        type() {
            return 'h' + this.level;
        },
        classes() {
            return {
                'text-xl font-bold mb-4 leading-6': this.level === 1,
                'text-lg font-bold mb-2': this.level === 2,
                'text-base font-bold mb-2': this.level === 3,
                'font-bold mb-2': this.level === 4,
                'text-primary': this.color === 'primary',
                'text-secondary': this.color === 'secondary',
                'text-status-available': this.color === 'available',
                'text-status-busy': this.color === 'busy',
                'text-status-offline': this.color === 'offline',
                'text-neutral': this.color === 'text-neutral-500',
                'text-light': this.color === 'light',
                'text-white': this.color === 'white',
                'text-danger': this.color === 'danger',
                'text-prime': this.color === 'prime',
                'text-whatsapp': this.color === 'whatsapp',
                'text-center': this.center,
            }
        }
    }
};
</script>
