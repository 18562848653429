<template>
    <base-radio-input
        v-model="pack"
        :name="name"
        :value="value"
        :disabled="disabled"
        input-hidden
        class="mr-2 text-center"
    >
        <template>
            <div class="base-radio-ppu">
                <div class="base-radio-ppu-price-container">
                    <p class="base-radio-ppu-title">{{ title }}</p>
                    <p class="base-radio-ppu-price">{{ cost_by_minute / 100 | formatMoneyPerMinute }}</p>
                </div>
                <div class="base-radio-ppu-icon text-neutral-400">
                    <font-awesome-icon :icon="['far', 'circle']" size="lg"/>
                </div>
            </div>
        </template>
        <template #checked>
            <div class="base-radio-ppu base-radio-ppu--checked" :style="cssVars">
                <div class="base-radio-ppu-price-container">
                    <p class="base-radio-ppu-title">{{ title }}</p>
                    <p class="base-radio-ppu-price">{{ cost_by_minute / 100 | formatMoneyPerMinute }}</p>
                </div>
                <div class="base-radio-ppu-icon base-radio-ppu-icon--checked">
                    <font-awesome-icon :icon="['far', 'circle-check']" size="lg"/>
                </div>
            </div>
        </template>
    </base-radio-input>
</template>

<style lang="scss">

.base-radio-ppu {
    @apply relative w-full mb-4 border border-neutral-400 text-neutral-500 rounded-lg cursor-pointer;

    &--checked {
        @apply text-white bg-no-repeat bg-center bg-cover border-pack2-from;
        background-image: var(--bg-pack2);
    }

    &--disabled {
        @apply bg-disabled text-neutral-400 pointer-events-none;
    }
}

.base-radio-ppu-icon {
    @apply absolute top-full left-2/4 bg-white rounded-full transform -translate-y-1/2 -translate-x-1/2;

    &--disabled {
        @apply bg-disabled;
    }

    &--checked {
        @apply text-pack2-from;
    }
}

.base-radio-ppu-title {
    @apply w-full text-center select-none;
}

.base-radio-ppu-price-container {
    @apply w-full flex flex-wrap content-center min-h-20;
}

.base-radio-ppu-price {
    @apply w-full font-bold text-lg xs:text-2xl select-none;
}
</style>

<script>
import BaseRadioInput from "../BaseRadioInput";

export default {
    name: "BaseRadioPpuType",
    components: {BaseRadioInput},
    model: {
        prop: 'selectedPack',
        event: 'change'
    },
    props: {
        cost_by_minute: Number,
        credits: Number,
        description: String,
        disabled: Boolean,
        displayedPack: Object,
        name: String,
        packNumber: Number,
        selectedPack: Number|String,
        title: String,
        value: Number|String
    },
    computed: {
        pack: {
            get() {
                return this.selectedPack;
            },

            set(value) {
                this.$emit('change', value);
            }
        },
        cssVars() {
            return {
                '--bg-pack1': "url('" + this.bgImageUrl + "'), linear-gradient(to right, var(--color-pack1-from), var(--color-pack1-to))",
                '--bg-pack2': "url('" + this.bgImageUrl + "'), linear-gradient(to right, var(--color-pack2-from), var(--color-pack2-to))",
                '--bg-pack3': "url('" + this.bgImageUrl + "'), linear-gradient(to right, var(--color-pack3-from), var(--color-pack3-to))"
            }
        },
    },
    data() {
        return {
            bgImageUrl: this.assetManager.getImageAsset('bg_recharge_pack.png')
        }
    }
}
</script>
