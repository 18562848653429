<template>
    <div class="offer-card offer-card-loader">
        <div class="flex w-full">
            <div class="offer-card-col-left">
                <base-avatar color="neutral-secondary" skeleton-loader x-large/>
            </div>

            <div class="offer-card-col-right">
                <div class="offer-card-col-right-header">
                    <p class="offer-card-name">
                        <span class="skeleton-text">&nbsp;</span>
                    </p>
                    <p class="offer-card-consultations-count">
                        <span class="skeleton-text">&nbsp;</span>
                    </p>
                </div>

                <div class="offer-card-col-right-content">
                    <p class="offer-card-description">
                        <span class="skeleton-text leading-4 max-h-4 mb-1">&nbsp;</span>
                        <span class="skeleton-text leading-4 max-h-4">&nbsp;</span>
                    </p>
                </div>

                <div class="offer-card-col-right-footer">
                    <p class="offer-card-rating"><span class="skeleton-text">&nbsp;</span></p>
                    <p class="offer-card-price"><span class="skeleton-text">&nbsp;</span></p>
                </div>
            </div>
        </div>

        <div class="offer-card-actions">
            <base-button class="offer-card-action" color="neutral-secondary" small skeleton-loader/>
            <base-button class="offer-card-action" color="neutral-secondary" small skeleton-loader/>
        </div>
    </div>
</template>

<style lang="scss">
.offer-card-loader {

    .offer-card-name {
        @apply pr-8;
    }

    .offer-card-consultations-count {
        @apply w-1/4;
    }

    .offer-card-price {
        @apply w-3/12;
    }

    .offer-card-rating {
        @apply w-2/12;
    }

    .skeleton-text {
        @apply block bg-neutral-300 animate-pulse rounded h-3/4;
    }
}
</style>

<script>
import BaseAvatar from "../elements/BaseAvatar.vue";
import BaseButton from "../elements/BaseButton.vue";

export default {
    name: 'OfferCardLoader',
    components: {BaseButton, BaseAvatar}
}
</script>
