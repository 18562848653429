<template>
    <div class="conversation-list-to-display-header">
        <div class="conversation-list-header-to-be-processed-choice">
            <base-button :color="allConversationTabState" small pill class="base-tab all-conversation"
                         @click="displayAllConversationList">
                {{ trans.get('chat.header.list_to_display.all_conversations') }}
            </base-button>
            <base-button :color="toBeProcessedTabState" small pill class="base-tab to-be-processed"
                         @click="clickToBeProcessedConversationList">
                {{
                    trans.get('chat.header.list_to_display.to_be_processed', {'to_be_processed_sum': getToBeProcessedConversations.length})
                }}
            </base-button>
        </div>
        <div v-if="this.getConversationsListToDiplay === 'to-be-processed'" class="conversation-list-title">
            {{ trans.get('chat.header.list_to_display.follow_up_title') }}
        </div>
    </div>
</template>

<style lang="scss">
.conversation-list-to-display-header {
    @apply flex flex-wrap border-b border-neutral-300;
}

.conversation-list-title {
    @apply flex bg-neutral-100 h-10 w-full text-sm text-primary font-semibold p-3;
}

.conversation-list-header-to-be-processed-choice {
    @apply flex items-center w-full h-10;
}

.base-tab {
    @apply flex rounded-full w-1/2 m-2;
}

</style>

<script>
import BaseButton from "../elements/BaseButton";
import ConversationListSearchbar from "./ConversationListSearchbar";
import {mapActions, mapGetters} from "vuex";
import ChatMixin from "./ChatMixin";

export default {
    name: "ConversationListHeaderListToDisplayChoice",
    components: {BaseButton},
    mixins: [ChatMixin],
    methods: {
        ...mapActions('chat', ['displayAllConversationList', 'displayToBeProcessedConversationList']),

        clickToBeProcessedConversationList() {
            if (this.getToBeProcessedConversations.length > 0) {
                this.displayToBeProcessedConversationList();
            }
        },
    },
    computed: {
        ...mapGetters('chat', ['iAmMaster', 'getConversationsListToDiplay', 'getToBeProcessedConversations']),
        toBeProcessedTabState() {
            if (this.getConversationsListToDiplay === 'to-be-processed') {
                return 'primary';
            } else {
                if (this.getToBeProcessedConversations.length > 0) {
                    return 'light';
                } else {
                    return 'white'
                }
            }
        },
        allConversationTabState() {
            if (this.getConversationsListToDiplay === 'all') {
                return 'primary';
            } else {
                return 'light';
            }
        }
    }

}
</script>
