<template>
    <div>
        <div :class="inputClass">

            <div v-if="hasPrepend" class="flex-none self-center">
                <slot name="prepend"/>
            </div>

            <div v-else-if="hasIcon" :class="iconClass" @click="focusInput">
                <slot name="icon"></slot>
            </div>

            <div class="relative flex-grow">
                <input
                    :id="id"
                    :name="name"
                    :type="type"
                    :disabled="disabled"
                    :class="fieldClass"
                    :placeholder="placeholder"
                    :autocomplete="autocomplete"
                    :value="value"
                    :inputmode="inputmode"
                    :readonly="readonly"
                    @input="updateValue($event.target.value)"
                    @keydown="$emit('keydown', $event)"
                    @blur="$emit('blur', $event)"
                    @focus="$emit('focus', $event)"
                />

                <label v-if="hasLabel" :for="id" :class="labelClass">{{ label }}</label>
            </div>

            <div v-if="hasIconAppend" :class="iconClass" @click="focusInput">
                <slot name="icon-append"></slot>
            </div>

            <div v-else-if="hasAppend" class="flex-none self-center">
                <slot name="append"/>
            </div>
        </div>

        <div v-if="hasError" :class="messageClass" v-html="error"></div>
    </div>
</template>

<style lang="scss">
.base-text-input {
    @apply flex rounded-md focus-within:border-primary;

    &:not(&.base-text-input--outlined) {
        @apply bg-white;
    }

    &--outlined {
        @apply bg-white border border-neutral-400;
    }

    &--disabled {
        @apply bg-neutral-300 border-disabled;
    }

    &--x-small {
        @apply h-8;
    }

    &--small {
        @apply h-10;
    }

    &--medium {
        @apply h-12;
    }

    &--large {
        @apply h-14;
    }

    &--danger {
        @apply border-danger;

        &:focus-within {
            @apply border-danger;
        }
    }

    .base-text-input-field {
        @apply leading-normal w-full h-full px-4 text-dark appearance-none placeholder-neutral-500 border-none focus:outline-none bg-transparent;

        &:disabled {
            @apply cursor-not-allowed;
        }

        &--icon, &--prepend {
            @apply pl-0;
        }

        &--append {
            @apply pr-0;
        }

        &--label {
            @apply placeholder-transparent focus:placeholder-neutral-500;
        }

        &--x-small {
            @apply text-xs pb-1 pt-3.5;
            &:not(&.base-text-input-field--label) {
                @apply py-2;
            }
        }

        &--small {
            @apply pb-0.5 pt-3.5;
            &:not(&.base-text-input-field--label) {
                @apply py-2;
            }
        }

        &--medium {
            @apply pb-1 pt-5;
            &:not(&.base-text-input-field--label) {
                @apply py-3;
            }
        }

        &--large {
            @apply pb-2 pt-6;
            &:not(&.base-text-input-field--label) {
                @apply py-4;
            }
        }

        &:focus ~ .base-text-input-label,
        &:not(:placeholder-shown) ~ .base-text-input-label {
            @apply text-primary;
            transform: translate(0, -0.3rem) scale(0.7);
        }

        &:focus ~ .base-text-input-label.base-text-input-label--danger,
        &:not(:placeholder-shown) ~ .base-text-input-label.base-text-input-label--danger {
            @apply text-danger;
        }
    }

    .base-text-input-label {
        @apply leading-normal font-normal left-4 absolute top-0 h-full block pointer-events-none origin-top-left text-neutral-500;
        transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;

        &--icon, &--prepend {
            @apply left-0;
        }

        &--disabled {
            @apply text-dark;
        }

        &--x-small {
            @apply text-xs leading-8;
        }

        &--small {
            @apply leading-10;
        }

        &--medium {
            @apply leading-12;
        }

        &--large {
            @apply leading-14;
        }
    }
}

.base-text-input-icon {
    @apply inline-flex items-center justify-center text-primary cursor-text;

    &--disabled {
        @apply text-neutral-500;
    }

    &--danger {
        @apply text-danger;
    }

    &--x-small {
        @apply text-base px-2;
    }

    &--small {
        @apply text-base px-2.5;
    }

    &--medium {
        @apply text-2xl px-2;
    }

    &--large {
        @apply text-3xl px-3;
    }
}

.base-text-input-message {
    @apply mt-1 text-sm;

    &--error {
        @apply text-danger;
    }
}
</style>

<script>
export default {
    name: "BaseTextInput",
    props: {
        id: {
            type: String,
            default() {
                return `text-input-${this._uid}`;
            }
        },
        type: {
            type: String,
            default: "text",
            validator: function (value) {
                return ['text', 'email', 'password', 'hidden'].indexOf(value) !== -1
            }
        },
        autocomplete: String,
        autofocus: Boolean,
        disabled: Boolean,
        error: String,
        inputmode: String,
        label: String,
        large: Boolean,
        name: String,
        outlined: Boolean,
        placeholder: String,
        readonly: String,
        small: Boolean,
        xSmall: Boolean,
        value: String | Number,
    },

    computed: {
        hasPrepend() {
            return !!this.$slots.prepend;
        },
        hasAppend() {
            return !!this.$slots.append;
        },
        hasIcon() {
            return !!this.$slots.icon;
        },
        hasIconAppend() {
            return !!this.$slots['icon-append'];
        },
        hasLabel() {
            return this.label !== undefined && this.label.length > 0;
        },
        hasError() {
            return this.error !== undefined && this.error.length > 0;
        },
        inputClass() {
            return {
                'base-text-input': true,
                'base-text-input--outlined': this.outlined,
                'base-text-input--danger': this.hasError,
                'base-text-input--x-small': this.xSmall,
                'base-text-input--small': this.small,
                'base-text-input--medium': !this.xSmall && !this.small && !this.large,
                'base-text-input--large': this.large,
                'base-text-input--disabled': this.disabled,
            };
        },
        fieldClass() {
            return {
                'base-text-input-field': true,
                'base-text-input-field--icon': this.hasIcon,
                'base-text-input-field--prepend': this.hasPrepend,
                'base-text-input-field--append': this.hasAppend,
                'base-text-input-field--label': this.hasLabel,
                'base-text-input-field--x-small': this.xSmall,
                'base-text-input-field--small': this.small,
                'base-text-input-field--medium': !this.xSmall && !this.small && !this.large,
                'base-text-input-field--large': this.large,
            }
        },
        iconClass() {
            return {
                'base-text-input-icon': true,
                'base-text-input-icon--danger': !this.disabled && this.hasError,
                'base-text-input-icon--disabled': this.disabled,
                'base-text-input-icon--x-small': this.xSmall,
                'base-text-input-icon--small': this.small,
                'base-text-input-icon--medium': !this.xSmall && !this.small && !this.large,
                'base-text-input-icon--large': this.large,
            };
        },
        labelClass() {
            return {
                'base-text-input-label': true,
                'base-text-input-label--danger': this.hasError,
                'base-text-input-label--x-small': this.xSmall,
                'base-text-input-label--small': this.small,
                'base-text-input-label--medium': !this.xSmall && !this.small && !this.large,
                'base-text-input-label--large': this.large,
                'base-text-input-label--icon': this.hasIcon,
                'base-text-input-label--prepend': this.hasPrepend,
                'base-text-input-label--disabled': this.disabled,
            };
        },
        messageClass() {
            return {
                'base-text-input-message': true,
                'base-text-input-message--error': this.hasError
            };
        }
    },

    methods: {
        updateValue: function (value) {
            this.$emit('input', value);
        },
        focusInput: function () {
            document.getElementById(this.id).focus();
        },
        blurInput: function () {
            document.getElementById(this.id).blur();
        },
    },

    mounted() {
        if (this.autofocus) {
            this.focusInput();
        }
    }
};
</script>
