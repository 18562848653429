export default {
    namespaced: true,

    state: {
        specialOperation: {},
    },

    mutations: {
        setActiveSpecialOperation: (state, specialOperation) => {
            state.specialOperation = specialOperation;
        }
    },

    getters: {
        getActiveSpecialOperation: (state) => state.specialOperation,
    },

    actions: {
        fetchActiveSpecialOperation({commit}) {
            if (window.active_special_operation !== 'null') {
                commit('setActiveSpecialOperation', JSON.parse(window.active_special_operation)[0]);
            }
        },
    },
}
