<template>
    <base-modal>
        <div v-if="this.hasBirthdayCouponActive">
            <modal-heading class="text-center">
                {{ trans.get('modals.birthday_coupon.happy_birthday') }}
            </modal-heading>

            <modal-paragraph class="text-center">
                <p class="text-black font-bold">
                    {{ trans.get('modals.birthday_coupon.your_gift_is_waiting') }}<br/>
                    {{ trans.get('modals.birthday_coupon.enjoy_your_gift', {'amount': this.getCouponAmount}) }}
                </p>
            </modal-paragraph>

            <div class="w-full text-center">
                <img class="inline-block max-h-48"
                     :src="assetManager.getImageAsset(config.get('assets.popup_birthday_coupon_active_image_path'))" />
            </div>

            <div class="grid grid-cols-1 gap-4 mt-5">
                <base-button autofocus @click="closeModal">
                    {{ trans.get('modals.birthday_coupon.i_use_my_coupon') }}
                </base-button>
            </div>
        </div>
        <div v-else>
            <modal-heading class="text-center">
                {{ trans.get('modals.birthday_coupon.sorry_coupon_unavailable') }}
            </modal-heading>

            <modal-paragraph class="text-center">
                <p class="text-black font-bold">
                    {{ trans.get('modals.birthday_coupon.dont_worry') }}
                </p>
            </modal-paragraph>

            <div class="w-full text-center">
                <img class="inline-block max-h-48"
                     :src="assetManager.getImageAsset(config.get('assets.popup_birthday_coupon_unavailable_image_path'))" />
            </div>

            <div class="grid grid-cols-1 gap-4 mt-5">
                <base-button autofocus @click="closeModal">
                    {{ trans.get('generic.ok') }}
                </base-button>
            </div>
        </div>

    </base-modal>
</template>

<script>

import BaseButton from "../elements/BaseButton";
import ModalParagraph from "./elements/ModalParagraph";
import ModalHeading from "./elements/ModalHeading";
import {mapActions, mapGetters} from "vuex";
import axios from 'axios';
import {ziggy_route} from '../ZiggyMixin';

export default {
    name: "ModalBirthdayCoupon",
    components: {BaseButton, ModalParagraph, ModalHeading},
    methods: {
        ...mapActions('modals', ['closeModal']),
    },
    computed: {
        ...mapGetters('modals', ['getParam']),
        getCouponAmount() {
            return this.$options.filters.formatMoneyShort(this.birthday_coupon.amount / 100);
        },
        hasBirthdayCouponActive() {
            return this.birthday_coupon !== null;
        },
    },
    data() {
        return {
            birthday_coupon: null,
        };
    },
    mounted()
    {
        this.birthday_coupon = this.getParam['birthday-coupon']['birthday_coupon'];
    },
}
</script>

<style scoped>

</style>
