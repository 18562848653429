<template>
    <development>
        <div>
            <development-heading>Audio player</development-heading>

            <development-heading :level="2">Props :</development-heading>
            <ul>
                <li><strong>src</strong>: String</li>
            </ul>

            <development-heading>Full example</development-heading>
            <base-audio-player ref="audio-player"
                               :src="assetManager.getAudioAsset('example/audio-example.mp3')"
                               @update="update"
                               @end="end"
            />

            <div class="mb-6">
                <p>Informations :</p>
                <p>Status : {{ status }}</p>
                <p>Loading : {{ loadingProgression }} %</p>
                <p>Current Time : {{ currentTime }}</p>
                <p>Playing Progression : {{ playingProgression }} %</p>
                <p>Duration : {{ duration }}</p>
                <p>Volume : {{ volume }}</p>
            </div>

            <div class="flex mb-4 gap-4">
                <span class="flex-none">{{ currentTime | formatSecondsToTimer }}</span>
                <input class="flex-grow" type="range" min="0" step="0.001" :max="100" :value="playingProgression"
                       @input="setCurrentTime"/>
                <span class="flex-none">{{ duration | formatSecondsToTimer }}</span>
            </div>

            <div class="flex mb-4 gap-4">
                <base-button @click="play">Play</base-button>
                <base-button @click="pause">Pause</base-button>
            </div>

            <div class="flex gap-4">
                <span>Volume</span>
                <input type="range" min="0" max="1" step="0.01" @input="setVolume" @change="setVolume"/>
            </div>
        </div>
    </development>
</template>
<script>
import Development from "./Development";
import DevelopmentHeading from "./elements/DevelopmentHeading";
import DevelopmentParagraph from "./elements/DevelopmentParagraph";
import BaseAudioPlayer from "../elements/BaseAudioPlayer";
import BaseButton from "../elements/BaseButton";

export default {
    components: {BaseButton, BaseAudioPlayer, DevelopmentParagraph, DevelopmentHeading, Development},
    methods: {
        setVolume(element) {
            this.$refs["audio-player"].setVolume(element.target.value);
            this.volume = this.$refs["audio-player"].volume;
        },
        setCurrentTime(element) {
            this.$refs["audio-player"].setPlayingProgression(element.target.value);
        },
        play() {
            this.$refs["audio-player"].play();
        },
        pause() {
            this.$refs["audio-player"].pause();
        },
        update() {
            this.currentTime = this.$refs["audio-player"].currentTime;
            this.duration = this.$refs["audio-player"].duration;
            this.loadingProgression = this.$refs["audio-player"].loadingProgression;
            this.status = this.$refs["audio-player"].status;
            this.playingProgression = this.$refs["audio-player"].playingProgression;
        },
        end() {
            console.log('end');
        },
    },
    mounted() {
    },
    data() {
        return {
            volume: 1,
            duration: 0,
            currentTime: 0,
            playingProgression: 0,
            loadingProgression: 0,
            status: '-',
        }
    }
}
</script>
