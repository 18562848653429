<template>
    <p class="p-3 overflow-hidden overflow-ellipsis container-dot">
        <span class="dot"></span>
        <span class="dot"></span>
        <span class="dot"></span>
    </p>
</template>


<style>
.container-dot {
    @apply pl-5;
}

.dot {
    @apply bg-black h-2 w-2 rounded-full inline-block animate-bounce;
}

.dot:nth-of-type(2) {
    animation-delay: 300ms;
}

.dot:nth-of-type(3) {
    animation-delay: 600ms;
}
</style>

<script>

export default {
    name: "ConversationMessageContentIsTyping",
}
</script>
