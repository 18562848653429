<template>
    <div class="home-banner-bats">
        <div class="home-banner-bats-bat-overlay">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 600 400" style="enable-background:new 0 0 600 400;" xml:space="preserve">
                <g class="home-banner-bats-bat-1">
                    <path d="M306.9,145.4l-7.3,7.3l-7.3-7.3c-0.6-0.6-1.6-0.2-1.6,0.6v7.9h7.6h2.5h7.6V146C308.5,145.2,307.5,144.8,306.9,145.4z" />
                    <path class="wing-right" d="M408.4,167.5c-3.3-2.7-7.6-4.4-12.2-4.4c-9.3,0-17.1,6.6-18.9,15.4c-2.8-1.6-6.1-2.5-9.6-2.5c-8.5,0-15.7,5.5-18.3,13.1
                        c-3-6.6-9.7-11.3-17.5-11.3c-7.7,0-14.4,4.6-17.5,11.1c-6.9-4.7-11-10.7-11-17.1c0-2.4,0.6-4.7,1.6-6.9c0.2,0,0.4,0,0.7,0
                        c24.7,0,45.4-9,51.2-21.1C383,144.1,404.5,154.3,408.4,167.5z" />

                    <path class="wing-left" d="M191.6,167.5c3.3-2.7,7.6-4.4,12.2-4.4c9.3,0,17.1,6.6,18.9,15.4c2.8-1.6,6.1-2.5,9.6-2.5c8.5,0,15.7,5.5,18.3,13.1
                        c3-6.6,9.7-11.3,17.5-11.3c7.7,0,14.4,4.6,17.5,11.1c6.9-4.7,11-10.7,11-17.1c0-2.4-0.6-4.7-1.6-6.9c-0.2,0-0.4,0-0.7,0
                        c-24.7,0-45.4-9-51.2-21.1C217,144.1,195.5,154.3,191.6,167.5z" />
                    <path d="M312.3,158.2c0,5.2-6,27.3-12.9,27.3c-7,0-12.3-22.1-12.3-27.3s5.7-9.5,12.6-9.5C306.6,148.8,312.3,153,312.3,158.2z" />

                    <ellipse class="home-banner-bats-bat-eye" cx="295.2" cy="161.8" rx="1.5" ry="3" />
                    <ellipse class="home-banner-bats-bat-eye" cx="304.2" cy="161.8" rx="1.5" ry="3" />
                </g>
                <g class="home-banner-bats-bat-2">
                    <path d="M306.9,145.4l-7.3,7.3l-7.3-7.3c-0.6-0.6-1.6-0.2-1.6,0.6v7.9h7.6h2.5h7.6V146C308.5,145.2,307.5,144.8,306.9,145.4z" />
                    <path class="wing-right" d="M408.4,167.5c-3.3-2.7-7.6-4.4-12.2-4.4c-9.3,0-17.1,6.6-18.9,15.4c-2.8-1.6-6.1-2.5-9.6-2.5c-8.5,0-15.7,5.5-18.3,13.1
                        c-3-6.6-9.7-11.3-17.5-11.3c-7.7,0-14.4,4.6-17.5,11.1c-6.9-4.7-11-10.7-11-17.1c0-2.4,0.6-4.7,1.6-6.9c0.2,0,0.4,0,0.7,0
                        c24.7,0,45.4-9,51.2-21.1C383,144.1,404.5,154.3,408.4,167.5z" />

                    <path class="wing-left" d="M191.6,167.5c3.3-2.7,7.6-4.4,12.2-4.4c9.3,0,17.1,6.6,18.9,15.4c2.8-1.6,6.1-2.5,9.6-2.5c8.5,0,15.7,5.5,18.3,13.1
                        c3-6.6,9.7-11.3,17.5-11.3c7.7,0,14.4,4.6,17.5,11.1c6.9-4.7,11-10.7,11-17.1c0-2.4-0.6-4.7-1.6-6.9c-0.2,0-0.4,0-0.7,0
                        c-24.7,0-45.4-9-51.2-21.1C217,144.1,195.5,154.3,191.6,167.5z" />
                    <path d="M312.3,158.2c0,5.2-6,27.3-12.9,27.3c-7,0-12.3-22.1-12.3-27.3s5.7-9.5,12.6-9.5C306.6,148.8,312.3,153,312.3,158.2z" />

                    <ellipse class="home-banner-bats-bat-eye" cx="295.2" cy="161.8" rx="1.5" ry="3" />
                    <ellipse class="home-banner-bats-bat-eye" cx="304.2" cy="161.8" rx="1.5" ry="3" />
                </g>
                <g class="home-banner-bats-bat-3">
                    <path d="M306.9,145.4l-7.3,7.3l-7.3-7.3c-0.6-0.6-1.6-0.2-1.6,0.6v7.9h7.6h2.5h7.6V146C308.5,145.2,307.5,144.8,306.9,145.4z" />
                    <path class="wing-right" d="M408.4,167.5c-3.3-2.7-7.6-4.4-12.2-4.4c-9.3,0-17.1,6.6-18.9,15.4c-2.8-1.6-6.1-2.5-9.6-2.5c-8.5,0-15.7,5.5-18.3,13.1
                        c-3-6.6-9.7-11.3-17.5-11.3c-7.7,0-14.4,4.6-17.5,11.1c-6.9-4.7-11-10.7-11-17.1c0-2.4,0.6-4.7,1.6-6.9c0.2,0,0.4,0,0.7,0
                        c24.7,0,45.4-9,51.2-21.1C383,144.1,404.5,154.3,408.4,167.5z" />

                    <path class="wing-left" d="M191.6,167.5c3.3-2.7,7.6-4.4,12.2-4.4c9.3,0,17.1,6.6,18.9,15.4c2.8-1.6,6.1-2.5,9.6-2.5c8.5,0,15.7,5.5,18.3,13.1
                        c3-6.6,9.7-11.3,17.5-11.3c7.7,0,14.4,4.6,17.5,11.1c6.9-4.7,11-10.7,11-17.1c0-2.4-0.6-4.7-1.6-6.9c-0.2,0-0.4,0-0.7,0
                        c-24.7,0-45.4-9-51.2-21.1C217,144.1,195.5,154.3,191.6,167.5z" />
                    <path d="M312.3,158.2c0,5.2-6,27.3-12.9,27.3c-7,0-12.3-22.1-12.3-27.3s5.7-9.5,12.6-9.5C306.6,148.8,312.3,153,312.3,158.2z" />

                    <ellipse class="home-banner-bats-bat-eye" cx="295.2" cy="161.8" rx="1.5" ry="3" />
                    <ellipse class="home-banner-bats-bat-eye" cx="304.2" cy="161.8" rx="1.5" ry="3" />
                </g>
            </svg>
        </div>

        <div class="home-banner-bats-bat-overlay home-banner-bats-bat-shadow mt-12">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 600 400" style="enable-background:new 0 0 600 400;" xml:space="preserve">
                <g class="home-banner-bats-bat-1">
                    <path d="M306.9,145.4l-7.3,7.3l-7.3-7.3c-0.6-0.6-1.6-0.2-1.6,0.6v7.9h7.6h2.5h7.6V146C308.5,145.2,307.5,144.8,306.9,145.4z" />
                    <path class="wing-right" d="M408.4,167.5c-3.3-2.7-7.6-4.4-12.2-4.4c-9.3,0-17.1,6.6-18.9,15.4c-2.8-1.6-6.1-2.5-9.6-2.5c-8.5,0-15.7,5.5-18.3,13.1
                        c-3-6.6-9.7-11.3-17.5-11.3c-7.7,0-14.4,4.6-17.5,11.1c-6.9-4.7-11-10.7-11-17.1c0-2.4,0.6-4.7,1.6-6.9c0.2,0,0.4,0,0.7,0
                        c24.7,0,45.4-9,51.2-21.1C383,144.1,404.5,154.3,408.4,167.5z" />

                    <path class="wing-left" d="M191.6,167.5c3.3-2.7,7.6-4.4,12.2-4.4c9.3,0,17.1,6.6,18.9,15.4c2.8-1.6,6.1-2.5,9.6-2.5c8.5,0,15.7,5.5,18.3,13.1
                        c3-6.6,9.7-11.3,17.5-11.3c7.7,0,14.4,4.6,17.5,11.1c6.9-4.7,11-10.7,11-17.1c0-2.4-0.6-4.7-1.6-6.9c-0.2,0-0.4,0-0.7,0
                        c-24.7,0-45.4-9-51.2-21.1C217,144.1,195.5,154.3,191.6,167.5z" />
                    <path d="M312.3,158.2c0,5.2-6,27.3-12.9,27.3c-7,0-12.3-22.1-12.3-27.3s5.7-9.5,12.6-9.5C306.6,148.8,312.3,153,312.3,158.2z" />
                </g>
                <g class="home-banner-bats-bat-2">
                    <path d="M306.9,145.4l-7.3,7.3l-7.3-7.3c-0.6-0.6-1.6-0.2-1.6,0.6v7.9h7.6h2.5h7.6V146C308.5,145.2,307.5,144.8,306.9,145.4z" />
                    <path class="wing-right" d="M408.4,167.5c-3.3-2.7-7.6-4.4-12.2-4.4c-9.3,0-17.1,6.6-18.9,15.4c-2.8-1.6-6.1-2.5-9.6-2.5c-8.5,0-15.7,5.5-18.3,13.1
                            c-3-6.6-9.7-11.3-17.5-11.3c-7.7,0-14.4,4.6-17.5,11.1c-6.9-4.7-11-10.7-11-17.1c0-2.4,0.6-4.7,1.6-6.9c0.2,0,0.4,0,0.7,0
                            c24.7,0,45.4-9,51.2-21.1C383,144.1,404.5,154.3,408.4,167.5z" />

                    <path class="wing-left" d="M191.6,167.5c3.3-2.7,7.6-4.4,12.2-4.4c9.3,0,17.1,6.6,18.9,15.4c2.8-1.6,6.1-2.5,9.6-2.5c8.5,0,15.7,5.5,18.3,13.1
                            c3-6.6,9.7-11.3,17.5-11.3c7.7,0,14.4,4.6,17.5,11.1c6.9-4.7,11-10.7,11-17.1c0-2.4-0.6-4.7-1.6-6.9c-0.2,0-0.4,0-0.7,0
                            c-24.7,0-45.4-9-51.2-21.1C217,144.1,195.5,154.3,191.6,167.5z" />
                    <path d="M312.3,158.2c0,5.2-6,27.3-12.9,27.3c-7,0-12.3-22.1-12.3-27.3s5.7-9.5,12.6-9.5C306.6,148.8,312.3,153,312.3,158.2z" />
                </g>
                <g class="home-banner-bats-bat-3">
                    <path d="M306.9,145.4l-7.3,7.3l-7.3-7.3c-0.6-0.6-1.6-0.2-1.6,0.6v7.9h7.6h2.5h7.6V146C308.5,145.2,307.5,144.8,306.9,145.4z" />
                    <path class="wing-right" d="M408.4,167.5c-3.3-2.7-7.6-4.4-12.2-4.4c-9.3,0-17.1,6.6-18.9,15.4c-2.8-1.6-6.1-2.5-9.6-2.5c-8.5,0-15.7,5.5-18.3,13.1
                            c-3-6.6-9.7-11.3-17.5-11.3c-7.7,0-14.4,4.6-17.5,11.1c-6.9-4.7-11-10.7-11-17.1c0-2.4,0.6-4.7,1.6-6.9c0.2,0,0.4,0,0.7,0
                            c24.7,0,45.4-9,51.2-21.1C383,144.1,404.5,154.3,408.4,167.5z" />

                    <path class="wing-left" d="M191.6,167.5c3.3-2.7,7.6-4.4,12.2-4.4c9.3,0,17.1,6.6,18.9,15.4c2.8-1.6,6.1-2.5,9.6-2.5c8.5,0,15.7,5.5,18.3,13.1
                            c3-6.6,9.7-11.3,17.5-11.3c7.7,0,14.4,4.6,17.5,11.1c6.9-4.7,11-10.7,11-17.1c0-2.4-0.6-4.7-1.6-6.9c-0.2,0-0.4,0-0.7,0
                            c-24.7,0-45.4-9-51.2-21.1C217,144.1,195.5,154.3,191.6,167.5z" />
                    <path d="M312.3,158.2c0,5.2-6,27.3-12.9,27.3c-7,0-12.3-22.1-12.3-27.3s5.7-9.5,12.6-9.5C306.6,148.8,312.3,153,312.3,158.2z" />
                </g>
            </svg>
        </div>
    </div>
</template>

<style lang="scss">
.home-banner-bats {
    @apply w-full h-full;
}

.home-banner-bats-bat-overlay {
    @apply w-full h-full absolute top-0 left-0 pointer-events-none;
    z-index: 5;
}

.home-banner-bats-bat-overlay svg {
    @apply w-full h-full;
}

.home-banner-bats-bat-eye {
    fill: #ad4e1f;
}

.home-banner-bats-bat-shadow {
    @apply filter blur-sm opacity-40;
}

.home-banner-bats-bat-1 {
    animation-direction: normal;
    animation: move-bat-1 15s infinite;
    animation-timing-function: ease-in-out;
    transform-origin: 50% 50%;
}

.home-banner-bats-bat-2 {
    animation-direction: normal;
    animation: move-bat-2 15s infinite;
    animation-timing-function: ease-in-out;
    transform-origin: 50% 50%;
}

.home-banner-bats-bat-3 {
    animation-direction: normal;
    animation: move-bat-3 15s infinite;
    animation-timing-function: ease-in-out;
    transform-origin: 50% 50%;
}

.wing-right {
    transform-origin: 50% 50%;
    animation-direction: normal;
    animation: wing-right 1s infinite;
    animation-timing-function: ease-in-out;
}

.wing-left {
    transform-origin: 50% 50%;
    animation-direction: normal;
    animation: wing-left 1s infinite;
    animation-timing-function: ease-in-out;
}

@keyframes move-bat-1 {
    0% {
        transform: translate(250px, 150px) rotate(10deg) scale(0.8);
    }
    25% {
        transform: translate(-250px, 140px) rotate(-10deg) scale(0.8);
    }
    50% {
        transform: translate(-160px, -70px) rotate(10deg) scale(0.8);
    }
    75% {
        transform: translate(-140px, -90px) rotate(-10deg) scale(0.8);
    }
    100% {
        transform: translate(250px, 150px) rotate(10deg) scale(0.8);
    }
}

@keyframes move-bat-2 {
    0% {
        transform: scale(0.6) translate(-300px, -70px) rotate(-10deg);
    }
    25% {
        transform: scale(0.6) translate(0px, -50px) rotate(10deg);
    }
    50% {
        transform: scale(0.6) translate(150px, 50px) rotate(-10deg);
    }
    75% {
        transform: scale(0.6) translate(-100px, 30px) rotate(10deg);
    }
    85% {
        transform: scale(0.6) translate(-110px, 90px) rotate(10deg);
    }
    100% {
        transform: scale(0.6) translate(-300px, -70px) rotate(-10deg);
    }
}

@keyframes move-bat-3 {
    0% {
        transform: translate(300px, -50px) scale(0.8) rotate(10deg);
    }
    25% {
        transform: translate(160px, -80px) scale(0.6) rotate(-10deg);
    }
    50% {
        transform: translate(170px, -80px) scale(0.6) rotate(10deg);
    }
    75% {
        transform: translate(105px, -60px) scale(0.8) rotate(-10deg);
    }
    100% {
        transform: translate(300px, -50px) scale(0.8) rotate(10deg);
    }
}

@keyframes wing-right {
    0% {
        transform: translate(0px, 0px) scale(1) rotate(0deg);
    }
    50% {
        transform: translate(0px, 0px) scaleX(0.5) rotate(-25deg);
    }
    100% {
        transform: translate(0px, 0px) scale(1) rotate(0deg);
    }
}

@keyframes wing-left {
    0% {
        transform: translate(0px, 0px) scale(1) rotate(0deg);
    }
    50% {
        transform: translate(0px, 0px) scaleX(0.5) rotate(25deg);
    }
    100% {
        transform: translate(0px, 0px) scale(1) rotate(0deg);
    }
}
</style>

<script>
export default {
    name: "HomeBannerBats",
}
</script>
