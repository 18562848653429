const UserMixin = {
    methods: {
        getSegmentPhoto(segment)
        {
            switch (segment) {
                case 'gold':
                    return this.assetManager.getImageAsset('user/segment/gold.jpg');
                case 'silver':
                    return this.assetManager.getImageAsset('user/segment/silver.jpg');
                case 'diamond':
                    return this.assetManager.getImageAsset('user/segment/diamond.jpg');
                case 'customer_to_retain':
                    return this.assetManager.getImageAsset('user/segment/customer-to-retain.svg');
                case 'loyal_customer':
                    return this.assetManager.getImageAsset('user/segment/loyal-customer.svg');
                case 'customer_to_reloyalty':
                    return this.assetManager.getImageAsset('user/segment/customer-to-reloyalty.svg');
                case 'free_customer':
                    return this.assetManager.getImageAsset('user/segment/free-customer.svg');
                case 'new':
                    return this.assetManager.getImageAsset('user/segment/new.jpg');
                default:
                    return this.assetManager.getImageAsset('user/segment/customer.jpg');
            }
        },
    },
};

export default UserMixin;
