import BaseLink from './components/elements/BaseLink';
import BaseButton from './components/elements/BaseButton';

export default {
    install(Vue, options) {
        Vue.prototype.compileHtml = function (template) {
            const vm = new Vue({
                template: template,
                components: {
                    BaseLink,
                    BaseButton,
                }
            });
            vm.$mount();
            return vm.$el.outerHTML;
        };
    }
}
