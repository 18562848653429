<template>
    <development>
        <div>
            <development-heading>List Banner</development-heading>
            <offer-list-banner offer-list-type="category" :category-id="4"></offer-list-banner>

            <development-heading :level="2">Props :</development-heading>
            <ul>
                <li><strong>offerListType</strong>: String</li>
                <li><strong>categoryId</strong>: Number</li>
                <li><strong>timeLeftPrimePromotion</strong>: String</li>
            </ul>

            <development-heading>Image Banners</development-heading>
            <offer-list-banner offer-list-type="category" :category-id="4"></offer-list-banner>
            <offer-list-banner offer-list-type="category" :category-id="23"></offer-list-banner>
            <offer-list-banner offer-list-type="voyance_by_chat" :category-id="4"></offer-list-banner>

            <development-heading>Prime Banners</development-heading>
            <offer-list-banner offer-list-type="prime_free_call"></offer-list-banner>
            <offer-list-banner offer-list-type="prime_free_call_masters_never_consulted"></offer-list-banner>
            <offer-list-banner offer-list-type="prime_free_chat"></offer-list-banner>
            <offer-list-banner offer-list-type="prime_promotion" time-left-prime-promotion="5 heures"></offer-list-banner>

        </div>
    </development>
</template>

<style lang="scss">

</style>

<script>
import Development from "./Development";
import DevelopmentHeading from "./elements/DevelopmentHeading";
import OfferListBanner from "../offer-list/OfferListBanner";
export default {
    name: "DevelopmentOfferListBanner",
    components: {DevelopmentHeading, Development, OfferListBanner}
}
</script>
