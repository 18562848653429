<template>
    <p class="mb-2">
        <slot></slot>
    </p>
</template>

<script>
export default {
    name: "DevelopmentParagraph"
};
</script>
