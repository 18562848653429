<template>
    <div class="w-screen h-screen overflow-x-hidden">
        <div class="max-w-screen-sm lg:max-w-screen-md mx-auto text-center px-4">
            <img class="mx-auto w-72 md:w-96 select-none"
                 :src="this.assetManager.getImageAsset('game/grand-jeu-de-noel-2024/game/wheel/title.png')"/>

            <game-wheel ref="wheel"
                        @play="run"
                        wheel-image="game/grand-jeu-de-noel-2024/game/wheel/wheel.png"
                        button-play-image="game/grand-jeu-de-noel-2024/game/wheel/button_play.gif"
                        button-played-image="game/grand-jeu-de-noel-2024/game/wheel/button_played.png"
                        :prize-image="prizeImage"
                        :prize-action="prizeAction"
                        :after-show-prize-action="afterShowPrizeAction">
                <template #backgrounds>
                    <game-wheel-background
                        color="#000054"
                        fullscreen/>
                    <game-wheel-background
                        image="game/grand-jeu-de-noel-2024/game/wheel/background_1.png"
                        spin/>
                    <game-wheel-background
                        image="game/grand-jeu-de-noel-2024/game/wheel/background_2.png"
                        :scale="200"/>
                    <game-wheel-background
                        image="game/grand-jeu-de-noel-2024/game/wheel/background_3.png"
                    />
                    <game-wheel-background
                        snowfall
                        fullscreen/>
                </template>
            </game-wheel>

            <div class="mb-2">
                <base-link href="/">
                    <img
                        :src="this.assetManager.getImageAsset('game/grand-jeu-de-noel-2024/game/wheel/button_back.png')"
                        class="h-12 md:h-20 mx-auto select-none"/>
                </base-link>

                <base-link :href="this.assetManager.getDocumentAsset('game/reglement_grand-jeu-de-noel-2024.pdf')"
                           color="white"
                           target="_blank">
                    {{ trans.get('game.consult_regulation') }}
                </base-link>
            </div>
        </div>
    </div>
</template>

<script>
import GameWheel from "../elements/GameWheel";
import GameWheelBackground from "../elements/GameWheelBackground";
import BaseLink from "../../elements/BaseLink";
import {mapActions, mapGetters} from "vuex";

export default {
    components: {BaseLink, GameWheelBackground, GameWheel},
    methods: {
        ...mapActions('game', ['play']),
        run() {
            this.play((response) => {
                let value = response.data.data.name;

                let wheelCase = null;

                switch (value) {
                    case 'luxury_bag':
                        wheelCase = 1;
                        this.prizeImage = "game/grand-jeu-de-noel-2024/game/prize/bag.png";
                        break;

                    case 'special_refill_prospect':
                        wheelCase = 2;
                        this.prizeImage = "game/grand-jeu-de-noel-2024/game/prize/refill.png";
                        break;

                    case 'special_refill_client':
                        wheelCase = 2;
                        this.prizeImage = "game/grand-jeu-de-noel-2024/game/prize/refill.png";
                        break;

                    case 'iphone':
                        wheelCase = 3;
                        this.prizeImage = "game/grand-jeu-de-noel-2024/game/prize/iphone.png";
                        break;

                    case 'free_consult':
                        wheelCase = 5;
                        this.prizeImage = "game/grand-jeu-de-noel-2024/game/prize/free.png";
                        break;

                    case 'fast_pass':
                        wheelCase = 7;
                        this.prizeImage = "game/grand-jeu-de-noel-2024/game/prize/fast_pass.png";
                        break;

                    case '10_euros_credit':
                        wheelCase = 8;
                        this.prizeImage = "game/grand-jeu-de-noel-2024/game/prize/10.png";
                        break;

                    case '15_euros_credit':
                        wheelCase = 8;
                        this.prizeImage = "game/grand-jeu-de-noel-2024/game/prize/15.png";
                        break;

                    case '25_euros_credit':
                        wheelCase = 8;
                        this.prizeImage = "game/grand-jeu-de-noel-2024/game/prize/25.png";
                        break;

                    default:
                        wheelCase = 4;
                        let id_gif = this.getIsLastDay ? 'last_day' : Math.floor(Math.random() * 3) + 1;
                        this.prizeImage = "game/grand-jeu-de-noel-2024/game/prize/lost_" + id_gif + ".gif";
                        break;
                }

                this.setActions(response);

                this.$refs.wheel.rotateWheel(wheelCase);
            });
        },
        setActions(response) {
            this.prizeAction = function () {
                return window.location.href = '/';
            }

            if (response.data.data.hasOwnProperty('actions')) {
                let actions = response.data.data.actions;

                actions.forEach((action) => {
                    if (action.hasOwnProperty('action') && action.hasOwnProperty('value')) {
                        let type = action.action;
                        let value = action.value;

                        switch (type) {
                            case 'redirect':
                                this.prizeAction = function () {
                                    return window.location.href = value;
                                }
                                break;
                            case 'redirect_with_time':
                                this.afterShowPrizeAction = function () {
                                    setTimeout(function () {
                                        window.location.href = value;
                                    }, 4000);
                                }
                                break;
                        }
                    }
                });
            }
        }
    },
    computed: {
        ...mapGetters('game', ['getIsLastDay'])
    },
    data() {
        return {
            prizeImage: '',
            prizeAction: null,
            afterShowPrizeAction: null,
        }
    },
}
</script>
