<template>
    <div ref="offer-card" :class="{'offer-card': true, 'offer-card-highlight': offerCardHighlight}">
        <div class="flex w-full">
            <div class="offer-card-col-left">
                <a :href="offer.url">
                    <base-badge :content="number" :distance="-0.75" outlined left>
                        <base-badge v-if="hasSegment"
                                    :content="segmentContent"
                                    :color="segmentColor"
                                    outlined bottom center square>
                            <base-avatar :photo="offer.photo" color="neutral-secondary" x-large/>
                        </base-badge>
                        <base-avatar v-else :photo="offer.photo" color="neutral-secondary" x-large/>
                    </base-badge>
                </a>
            </div>

            <div class="offer-card-col-right">
                <a :href="offer.url">
                    <div class="offer-card-col-right-header">
                        <p class="offer-card-name">{{ offer.name }}</p>
                        <p class="offer-card-consultations-count">
                            {{ offer.consultations_count }}&nbsp;<font-awesome-icon
                            :icon="['fas', 'circle-phone-flip']"/>
                        </p>
                    </div>

                    <div class="offer-card-col-right-content">
                        <p class="offer-card-description">{{ offer.short_description }}</p>
                    </div>

                    <div class="offer-card-col-right-footer">
                        <p class="offer-card-rating">
                            <span v-if="offer.display_rating">{{ offer.average_mark / 10 }}<font-awesome-icon
                                :icon="['fas', 'star']"/></span>
                        </p>
                        <p class="offer-card-price">
                            <span v-if="this.offer.cost_by_minute_without_advantage !== this.offer.cost_by_minute"
                                  class="previous-price">{{
                                    offer.cost_by_minute_without_advantage / 100 | formatMoneyPerMinute
                                }}</span>
                            <span class="text-primary font-bold whitespace-nowrap">{{
                                    offer.cost_by_minute / 100 | formatMoneyPerMinute
                                }}</span>
                        </p>
                    </div>
                </a>
            </div>
        </div>

        <offer-card-actions :offer="offer"
                            :user="user"
                            :offer_list_type="offer_list_type"
                            :is_prime_enabled="is_prime_enabled"
                            :is_free_chat_enabled_for_prospect="is_free_chat_enabled_for_prospect"
                            :user_credits="user_credits"/>
    </div>
</template>

<style lang="scss">
.offer-card {
    @apply bg-white shadow-small rounded-2xl p-3;

    &-name {
        @apply flex-grow min-w-0 font-bold text-base text-primary whitespace-nowrap overflow-ellipsis overflow-hidden;
    }

    &-consultations-count {
        @apply flex-none text-base text-main font-bold whitespace-nowrap;
    }

    &-col {
        &-left {
            @apply flex-none pr-3;
        }

        &-right {
            @apply flex-grow flex flex-col min-w-0;

            &-header {
                @apply flex-grow flex min-w-0;
            }

            &-content {
                @apply flex-grow min-w-0 h-15;
            }

            &-footer {
                @apply flex items-end justify-between align-middle my-1;
            }
        }
    }

    &-description {
        @apply text-main text-sm line-clamp-3;
    }

    &-rating {
        @apply font-bold text-lg leading-5 text-star whitespace-nowrap;
    }

    &-price {
        @apply text-right text-lg leading-5;
    }

    .previous-price {
        @apply text-main text-sm leading-5 whitespace-nowrap line-through;
    }

    .offer-card-actions {
        @apply flex mt-2 gap-x-2 h-8 sm:gap-x-4;

        .offer-card-action {
            @apply w-full flex-1 min-w-0;
        }
    }

    &-highlight {
        --shine-deg: 135deg;
        background-repeat: no-repeat;
        background-position: 200% 0, 0 0;
        background-image: linear-gradient(
                var(--shine-deg),
                transparent 20%,
                transparent 40%,
                rgb(68, 68, 68, 0.1) 50%,
                rgb(68, 68, 68, 0.1) 55%,
                transparent 70%,
                transparent 100%
        );
        background-size: 250% 250%, 100% 100%;
        transition: background-position 0s ease;
        animation: offer-card-shiny-effect 2s;
    }

    @keyframes offer-card-shiny-effect {
        100% {
            background-position: -200% 0, 0 0;
        }
    }
}
</style>

<script>
import BaseAvatar from "../elements/BaseAvatar";
import BaseBadge from "../elements/BaseBadge";
import BaseButton from "../elements/BaseButton";
import OfferCardActions from "./OfferCardActions.vue";
import OfferMixin from "./OfferMixin";

export default {
    name: 'OfferCard',
    mixins: [OfferMixin],
    components: {BaseAvatar, BaseBadge, BaseButton, OfferCardActions},
    props: {
        number: Number,
        offer: {
            type: Object,
            default() {
                return {}
            }
        },
        user: Object | Array,
        offer_list_type: String,
        is_prime_enabled: Boolean,
        is_free_chat_enabled_for_prospect: Boolean,
        user_credits: Number,
    },
}
</script>
