const MediaDevicesManager = {

    methods: {
        getBrowserDevicePermission: function(constraints, successCallback, errorCallback) {
            return navigator.mediaDevices.getUserMedia(constraints).then(successCallback).catch(errorCallback);
        },
        getConnectedDevices: async function(type) {
            const devices = await navigator.mediaDevices.enumerateDevices();
            return devices.filter(device => device.kind === type);
        }
    }
}

export default MediaDevicesManager;
