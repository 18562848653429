<template>
    <div :class="itemClass" @click="selectConversation">
        <div class="flex-none self-center pr-2">
            <base-avatar
                :name="name"
                :photo="photo"
                :status="status"
                color="primary"
                outlined/>
        </div>
        <div class="flex-grow self-center min-w-0">
            <p class="conversation-search-item-name">{{ name }}</p>
            <p class="conversation-search-item-price">
                {{ trans.get('generic.cost_by_minute', {cost: cost}) }}
            </p>
        </div>
        <div v-if="isMasterStatusOffline" class="flex-none self-center">
            <base-button class="ml-2 min-w-30" color="neutral" outlined small @click="displayModalNotifMasterOnline">
                <font-awesome-icon :icon="['far', 'bell']" size="1x" />
                {{ trans.get('generic.alert_notif_master_online') }}
            </base-button>
        </div>
        <div v-else-if="isMasterStatusBusy" class="flex-none self-center">
            <base-button class="ml-2 min-w-30" color="busy" outlined small>
                {{ trans.get('generic.status_busy') }}
            </base-button>
        </div>
        <div v-else-if="isChatLiveEnabled" class="flex-none self-center">
            <base-button class="ml-2 min-w-30" outlined small @click="startLiveChat">
                <font-awesome-icon :icon="['fal', 'message-lines']" />
                {{ trans.get('generic.chat_live') }}
            </base-button>
        </div>
    </div>
</template>

<style lang="scss">
.conversation-search-item {
    @apply bg-white rounded-xl w-full flex pl-3 pr-4 py-2 cursor-pointer;

    & .conversation-search-item-name {
        @apply text-base leading-5 font-bold capitalize;
    }

    & .conversation-search-item-price {
        @apply text-sm;
    }

    & p {
        @apply whitespace-nowrap overflow-ellipsis overflow-hidden;
    }

    &--available p {
        @apply text-primary;
    }

    &--busy p {
        @apply text-status-busy;
    }

    &--offline p {
        @apply text-status-offline;
    }
}
</style>

<script>
import BaseButton from '../elements/BaseButton';
import {mapActions, mapGetters} from 'vuex';
import BaseAvatar from '../elements/BaseAvatar';
import ChatMixin from './ChatMixin';

export default {
    name: 'ConversationSearchItem',
    components: {BaseAvatar, BaseButton},
    mixins: [ChatMixin],
    props: {
        conversation: {
            type: Object
        },
    },
    computed: {
        ...mapGetters('chat', ['getConversation', 'getOfferIdFromParticipants']),
        ...mapGetters('payment', ['getCredits']),
        participants() {
            return this.conversation.participants;
        },
        name() {
            return this.getParticipantName(this.interlocutor(this.participants));
        },
        cost() {
            return this.$options.filters.formatMoney(this.conversation.cost_by_minute / 100).replaceAll(/\s/g,'');
        },
        photo() {
            return this.interlocutor(this.participants).photo;
        },
        status() {
            return this.interlocutor(this.participants).status;
        },
        isChatLiveEnabled() {
            return this.isMasterWithChatLiveEnabled(this.interlocutor(this.participants));
        },
        isMasterStatusOffline() {
            return this.status === 'offline';
        },
        isMasterStatusBusy() {
            return this.status === 'busy';
        },
        itemClass() {
            return {
                'conversation-search-item': true,
                'conversation-search-item--available': !this.isMasterStatusBusy && !this.isMasterStatusOffline,
                'conversation-search-item--busy': this.isMasterStatusBusy,
                'conversation-search-item--offline': this.isMasterStatusOffline,
            }
        }
    },
    methods: {
        ...mapActions('chat', ['setSelectedConversation', 'setConversationUrl', 'toggleConversationSearch', 'startLiveChatProcess']),
        ...mapActions('modals', ['openModal']),
        selectConversation: function () {
            this.setSelectedConversation({conversation: this.conversation, addOnTop: true});
            this.setConversationUrl(this.participants);
            this.toggleConversationSearch(false);
        },
        startLiveChat: function (event) {
            event.stopPropagation();
            this.startLiveChatProcess({offerId:this.getOfferIdFromParticipants(this.participants)});
        },
        displayModalNotifMasterOnline() {
            this.openModal({
                name: globals.CHAT_CUSTOMER_NOTIF_MASTER_ONLINE,
                param: {
                    master: this.interlocutor(this.participants),
                    userFullMobilePhoneNumber: this.meFromUserStore().mobile_phone
                }
            });
        }
    },
}
</script>
