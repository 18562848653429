<template>
    <base-modal small>
        <form @submit="submitCode">
            <modal-heading>
                {{ trans.get('generic.modals.user_phone_verification_code.confirm_your_number_to_start_the_chat') }}
            </modal-heading>

            <modal-paragraph>
                {{
                    trans.get('generic.modals.user_phone_verification_code.enter_the_code_received_on_your_mobile_phone',
                        {phone_number: userPhoneNumber},
                    )
                }}
                <base-button font-normal text lowercase class="underline text-dark hover:text-dark"
                             @click="editPhoneNumber">
                    {{ trans.get('generic.edit') }}
                </base-button>
            </modal-paragraph>

            <modal-paragraph>
                <base-code-input ref="codeInput"
                                 autofocus
                                 placeholder="X"
                                 v-model="codeValue"
                                 :error="codeError"
                                 @change="checkCodeComplete"/>
            </modal-paragraph>

            <modal-paragraph>
                <base-button font-normal text normal-case class="underline text-dark hover:text-dark"
                             @click="resendCode">
                    {{ trans.get('generic.modals.user_phone_verification_code.resend_a_code') }}
                </base-button>
            </modal-paragraph>

            <modal-paragraph>{{ anotherCodeWasSent }}</modal-paragraph>

            <modal-paragraph class="mt-4">
                {{
                    trans.get('generic.modals.user_phone_verification_code.contact_customer_service_if_there_is_a_problem',
                        {phone_number_customer_care: config.get('app.phone_number_customer_care')},
                    )
                }}
            </modal-paragraph>

            <div class="grid grid-cols-1 mt-6">
                <base-button type="submit" :disabled="!codeComplete" :loading="loading">
                    {{ trans.get('generic.validate') }}
                </base-button>
            </div>
        </form>
    </base-modal>
</template>

<script>
import ModalHeading from './elements/ModalHeading';
import ModalParagraph from './elements/ModalParagraph';
import BasePhoneInput from '../elements/BasePhoneInput';
import BaseButton from '../elements/BaseButton';
import BaseTextInput from '../elements/BaseTextInput';
import BaseCodeInput from '../elements/BaseCodeInput';
import {mapActions, mapGetters} from 'vuex';
import {ziggy_route} from '../ZiggyMixin';
import BaseLink from "../elements/BaseLink";

export default {
    name: 'ModalUserPhoneVerificationCode',
    components: {BaseLink, BaseCodeInput, BaseTextInput, BaseButton, BasePhoneInput, ModalParagraph, ModalHeading},
    computed: {
        ...mapGetters('modals', ['getParam']),
    },
    methods: {
        ...mapActions('modals', ['openModal']),
        ...mapActions('chat', ['startLiveChatProcess']),
        editPhoneNumber() {
            this.openModal({
                name: globals.USER_PHONE_VERIFICATION,
                param: {
                    phone_number: this.userPhoneNumber,
                },
            });
        },
        checkCodeComplete() {
            this.codeComplete = this.$refs.codeInput.isComplete();
        },
        resendCode() {
            axios.post(ziggy_route('phone.free-customers-phone-verification.send-code'), {
                phone: this.userPhoneNumber,
            }).then(response => {
                this.anotherCodeWasSent = this.trans.get('generic.modals.user_phone_verification_code.another_code_was_sent', {
                    phone_number: this.userPhoneNumber
                });

                setTimeout(() => this.anotherCodeWasSent = '', 5000);
            }).catch(error => {
                if (error.response.status === 429) {
                    this.anotherCodeWasSent = `${this.trans.get('generic.loading')}...`;

                    setTimeout(() => this.anotherCodeWasSent = '', 5000);
                }
            });
        },
        submitCode(e) {
            e.preventDefault();

            this.loading = true;
            axios.post(ziggy_route('phone.free-customers-phone-verification.verify-code'), {
                verificationCode: this.codeValue,
            }).then(response => {
                this.onSuccessVerificationCallback();
            }).catch(error => {
                let phoneResponseError = error.response.data.errors.phone && error.response.data.errors.phone[0],
                    verificationCodeError = error.response.data.errors.verificationCode && error.response.data.errors.verificationCode[0];

                if (phoneResponseError && phoneResponseError.code === 'ERROR_ACCOUNT_WITH_SAME_PHONE_FOUND') {
                    this.openModal({
                        name: globals.USER_PHONE_VERIFICATION_ALREADY_USED,
                        param: {
                            email: phoneResponseError.email,
                        },
                    });
                } else if (verificationCodeError && verificationCodeError.code === 'ERROR_VERIFICATION_CODE_NO_MATCH') {
                    this.codeError = verificationCodeError.message;
                }
            }).then(() => {
                this.loading = false;
            });
        },
    },
    mounted() {
        this.userPhoneNumber = this.getParam['user-phone-verification-code']['phone_number'];
        this.onSuccessVerificationCallback = this.getParam['user-phone-verification-code']['onSuccessVerificationCallback'];
    },
    data() {
        return {
            codeError: '',
            codeValue: '',
            codeComplete: false,
            userPhoneNumber: '',
            anotherCodeWasSent: '',
            loading: false,
            onSuccessVerificationCallback: () => {
            }
        };
    },
};
</script>
