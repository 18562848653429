<template>
    <development>
        <div>
            <development-heading>List - Offer Card</development-heading>

            <div class="grid lg:grid-cols-2 grid-cols-1 gap-4">
                <offer-card v-for="(data, index) in datas"
                            :key="data.offer.id"
                            :number="index+1"
                            :offer="data.offer"
                            :user="data.user"
                            :offer_list_type="data.offer_list_type"
                            :is_prime_enabled="data.is_prime_enabled"
                            :is_free_chat_enabled_for_prospect="data.is_free_chat_enabled_for_prospect"
                            :user_credits="data.user_credits"
                />
            </div>
        </div>
    </development>
</template>

<script>
import Development from "./Development";
import DevelopmentHeading from "./elements/DevelopmentHeading";
import OfferCard from "../offer/OfferCard.vue";

export default {
    name: "DevelopmentOfferCard",
    components: {OfferCard, Development, DevelopmentHeading},
    methods: {
        randomNumber(max) {
            return Math.floor(Math.random() * max);
        },
        randomBoolean() {
            return Math.random() < 0.5;
        },
    },
    mounted() {
        let id = 0;

        let offerCards = [
            {offer: {status_details: {status: 'online', can_call: true, can_chat: true}, name: "Online Master"}},
            {
                offer: {
                    status_details: {status: 'online', can_call: true, can_chat: true},
                    segment: "new",
                    offer_name: "New Master"
                }
            },
            {
                offer: {
                    status_details: {status: 'online', can_call: true, can_chat: true},
                    segment: "gold",
                    offer_name: "Gold Master"
                }
            },
            {
                offer: {
                    status_details: {status: 'online', can_call: true, can_chat: true},
                    segment: "star",
                    offer_name: "Star Master"
                }
            },
            {
                offer: {
                    status_details: {status: 'online', can_call: true, can_chat: true},
                    segment: "top",
                    offer_name: "Top Master"
                }
            },
            {
                offer: {
                    status_details: {status: 'online', can_call: true, can_chat: true},
                    segment: "up",
                    offer_name: "Up Master"
                }
            },
            {
                offer: {
                    status_details: {status: 'online', can_call: true, can_chat: true},
                    segment: "plus",
                    offer_name: "Plus Master"
                }
            },
            {
                offer: {
                    status_details: {status: 'online', can_call: true, can_chat: true},
                    segment: "super",
                    offer_name: "Super Master"
                }
            },
            {
                offer: {
                    status_details: {status: 'online', can_call: true, can_chat: true},
                    segment: "elite",
                    offer_name: "Elite Master"
                }
            },

            {
                offer: {
                    status_details: {status: 'online', can_call: false, can_chat: true},
                    offer_name: "Call Action disabled"
                }
            },
            {
                offer: {
                    status_details: {status: 'online', can_call: true, can_chat: false},
                    offer_name: "Chat Action disabled"
                }
            },

            {
                offer: {
                    id: 512,
                    status_details: {status: 'online', can_call: true, can_chat: true},
                    offer_name: "Actions voucher"
                },
                user: {active_vouchers: [{offer_id: 512}]}
            },

            {
                offer: {
                    status_details: {status: 'online', can_call: true, can_chat: true},
                    offer_name: "Actions free",
                    cost_by_minute: 200
                },
                user: {can_free_call: true, can_free_chat: true},
                is_free_chat_enabled_for_prospect: true,
                user_credits: 1000
            },
            {
                offer: {
                    status_details: {status: 'online', can_call: true, can_chat: true},
                    offer_name: "Action Call free_prime"
                },
                offer_list_type: 'prime_free_call',
            },
            {
                offer: {
                    status_details: {status: 'online', can_call: true, can_chat: true},
                    offer_name: "Actions Chat free_prime"
                },
                offer_list_type: 'prime_free_chat',
            },


            {offer: {status_details: {status: 'busy'}, offer_name: "Busy Master"}},
            {offer: {status_details: {status: 'busy', has_active_call: true}, offer_name: "Call In Progress"}},
            {offer: {status_details: {status: 'busy', has_active_chat: true}, offer_name: "Chat In Progress"}},
            {
                offer: {
                    status_details: {status: 'busy', has_active_call: true, can_chat: false},
                    is_in_prime_category: true,
                    offer_name: "Call Action fast_pass"
                },
                user: {is_prime: true}
            },
            {
                offer: {
                    status_details: {status: 'busy', has_active_call: true, can_chat: false},
                    is_in_prime_category: true,
                    offer_name: "Call Action show fast_pass presentation"
                },
                user: {is_prime: false}
            },
            {
                offer: {
                    status_details: {status: 'busy', has_active_call: true, can_chat: false},
                    is_in_prime_category: true,
                    offer_name: "Call purchase fast_pass"
                },
                user: {is_prime: true, can_buy_fast_pass: true}
            },
            {
                offer: {
                    status_details: {status: 'busy', waiting_queue: [{user_id: 375}]},
                    offer_name: "Waiting Queue Full"
                }
            },
            {
                offer: {
                    status_details: {status: 'busy', waiting_queue: [{user_id: 256}]},
                    offer_name: "Fast Pass Activated"
                },
                user: {id: 256}
            },
            {
                offer: {id: 256, status_details: {status: 'busy', offer_name: "Fast Pass Unavailable"}},
                user: {active_waiting_queue: [{offer_id: 123}]}
            },

            {offer: {status_details: {status: 'offline'}, offer_name: "Offline Master"}},
        ];

        offerCards.forEach((offerCard) => {
            let cost_by_minute = this.randomNumber(500);
            id++;

            let offer = {
                id: offerCard.offer.hasOwnProperty("id") ? offerCard.offer.id : id,
                name: offerCard.offer.hasOwnProperty("offer_name") ? offerCard.offer.offer_name : "Offer Name",
                photo: "https://cdn.kang.fr/pic/128/4264/53711/1.jpg",
                url: "#",
                consultations_count: this.randomNumber(20000),
                short_description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ea earum officia quam suscipit voluptatum. Adipisci cumque ducimus mollitia neque rem?",
                average_mark: this.randomNumber(50),
                cost_by_minute_without_advantage: cost_by_minute,
                cost_by_minute: this.randomBoolean() ? cost_by_minute : 0,
                segment: offerCard.offer.hasOwnProperty("segment") ? offerCard.offer.segment : null,
                status_details: offerCard.offer.hasOwnProperty("status_details") ? offerCard.offer.status_details : null,
                is_in_prime_category: offerCard.offer.hasOwnProperty("is_in_prime_category") ? offerCard.offer.is_in_prime_category : false,
            };

            let user = {
                id: offerCard.hasOwnProperty('user') && offerCard.user.hasOwnProperty('id') ? offerCard.user.id : id,
                is_prime: offerCard.hasOwnProperty('user') && offerCard.user.hasOwnProperty('is_prime') ? offerCard.user.is_prime : false,
                can_free_call: offerCard.hasOwnProperty('user') && offerCard.user.hasOwnProperty('can_free_call') ? offerCard.user.can_free_call : false,
                can_free_chat: offerCard.hasOwnProperty('user') && offerCard.user.hasOwnProperty('can_free_chat') ? offerCard.user.can_free_chat : false,
                can_buy_fast_pass: offerCard.hasOwnProperty('user') && offerCard.user.hasOwnProperty('can_buy_fast_pass') ? offerCard.user.can_buy_fast_pass : false,
                active_waiting_queue: offerCard.hasOwnProperty('user') && offerCard.user.hasOwnProperty('active_waiting_queue') ? offerCard.user.active_waiting_queue : [],
                active_vouchers: offerCard.hasOwnProperty('user') && offerCard.user.hasOwnProperty('active_vouchers') ? offerCard.user.active_vouchers : [],
            };

            let offer_list_type = offerCard.hasOwnProperty('offer_list_type') ? offerCard.offer_list_type : 'category';

            let is_prime_enabled = offerCard.hasOwnProperty('is_prime_enabled') ? offerCard.is_prime_enabled : true;
            let is_free_chat_enabled_for_prospect = offerCard.hasOwnProperty('is_free_chat_enabled_for_prospect') ? offerCard.is_free_chat_enabled_for_prospect : false;
            let user_credits = offerCard.hasOwnProperty('user_credits') ? offerCard.user_credits : false;

            this.datas.push({
                offer: offer,
                user: user,
                offer_list_type: offer_list_type,
                is_prime_enabled: is_prime_enabled,
                is_free_chat_enabled_for_prospect: is_free_chat_enabled_for_prospect,
                user_credits: user_credits
            });
        });
    },
    data() {
        return {
            datas: []
        }
    }
}
</script>
