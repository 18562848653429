<template>
    <base-modal>
        <template v-if="showStartConsultation">
            <modal-heading v-if="isVoucher">
                {{ trans.get('chat.modals.you_ll_be_put_in_contact_with') }}
            </modal-heading>
            <modal-heading v-else>
                {{ trans.get('chat.modals.you_ll_be_put_in_touch_with') }}
            </modal-heading>

            <div class="-mx-6">
                <div class="bg-neutral-50 flex items-center mb-5 p-5 w-full">
                    <img class="mr-5 rounded-full w-20" :src="offer.photo" alt="">
                    <div>
                        <span class="font-bold text-xl text-primary">{{ offer.name }}</span>
                        <base-list-item v-if="prospectCanConsultForFree">
                            <template #icon>
                                <font-awesome-icon :icon="['fas', 'circle-chevron-right']"/>
                            </template>
                            <template #title v-if="isChat">
                                <span v-html="trans.get('chat.free_credit_for_the_first_chat')"></span>
                            </template>
                            <template #title v-else>
                                <span v-html="trans.get('call.free_credit_for_the_first_call')"></span>
                            </template>
                        </base-list-item>
                        <base-list-item v-else-if="!isVoucher">
                            <template #icon>
                                <font-awesome-icon :icon="['fas', 'circle-chevron-right']"/>
                            </template>
                            <template #title>{{ trans.get('generic.rate') }} :</template>
                            <template #description>
                                {{ costByMinute / 100 | formatMoneyPerMinute }}
                                <template v-if="hasUserMobileTax">
                                    + {{ userMobileTax / 100 | formatMoneyPerMinute }}
                                    {{ trans.get('generic.for_mobile') }}
                                </template>
                                <template v-if="hasAdditionalCost">
                                    + {{ costByCallAdditional / 100 | formatMoneyPerCall }}
                                </template>
                            </template>
                        </base-list-item>
                    </div>
                </div>
            </div>

            <base-list class="ml-3">
                <base-list-item v-if="isCall">
                    <template #icon>
                        <font-awesome-icon :icon="['fas', 'circle-chevron-right']"/>
                    </template>
                    <template #description>
                        <strong>{{ trans.get('generic.i_want_to_be_called_back') }} : </strong>
                        {{ userPhoneNumber | formatPhoneNumber('national') }}<br/>
                        <base-link color="dark" @click="updatePhoneNumber">
                            {{ trans.get('generic.modify_my_number') }}
                        </base-link>
                    </template>
                </base-list-item>
                <base-list-item v-else-if="isChat">
                    <template #icon>
                        <font-awesome-icon :icon="['fas', 'circle-chevron-right']"/>
                    </template>
                    <template #title>{{ trans.get('chat.modals.start_chat_live_infos') }}</template>
                </base-list-item>
                <base-list-item v-if="!isVoucher">
                    <template #icon>
                        <font-awesome-icon :icon="['fas', 'circle-chevron-right']"/>
                    </template>
                    <template #title>
                        {{ trans.get('generic.credit') }} :
                    </template>
                    <template #description>{{ availableCredits / 100 | formatMoneyShort }}</template>
                </base-list-item>
            </base-list>

            <base-button class="mt-6" block :loading="this.loading" @click="submitCredit">
                <template v-if="isFastPass">
                    {{ trans.get('generic.use_fast_pass') }}
                </template>
                <template v-else-if="isVoucher">
                    {{ trans.get('generic.use_voucher') }}
                </template>
                <template v-else-if="isCall">
                    {{ trans.get('call.start_call') }}
                </template>
                <template v-else-if="isChat">
                    {{ trans.get('chat.modals.start_chat_live_button_label') }}
                </template>
                <template v-else>
                    {{ trans.get('generic.validate_my_choice') }}
                </template>
            </base-button>
        </template>

        <template v-else>
            <modal-heading>
                {{ trans.get('generic.start_my_consultation_with', {master_name: masterName}) }}
            </modal-heading>

            <div class="ml-4 mb-4">
                <base-list>
                    <base-list-item v-if="isCall">
                        <template #icon>
                            <font-awesome-icon :icon="['fas', 'circle-chevron-right']"/>
                        </template>
                        <template #description>
                            <strong>{{ trans.get('generic.i_want_to_be_called_back') }} : </strong>
                            {{ userPhoneNumber | formatPhoneNumber('national') }}<br/>
                            <base-link color="dark" @click="updatePhoneNumber">
                                {{ trans.get('generic.modify_my_number') }}
                            </base-link>
                        </template>
                    </base-list-item>
                    <base-list-item v-else-if="isChat">
                        <template #icon>
                            <font-awesome-icon :icon="['fas', 'circle-chevron-right']"/>
                        </template>
                        <template #title>{{ trans.get('chat.modals.start_chat_live_infos') }}</template>
                    </base-list-item>
                    <base-list-item>
                        <template #icon>
                            <font-awesome-icon :icon="['fas', 'circle-chevron-right']"/>
                        </template>
                        <template #title>
                            {{ trans.get(insufficientCredit ? 'generic.insufficient_credit' : 'generic.credit') }} :
                        </template>
                        <template #description>{{ availableCredits / 100 | formatMoneyShort }}</template>
                    </base-list-item>
                    <base-list-item>
                        <template #icon>
                            <font-awesome-icon :icon="['fas', 'circle-chevron-right']"/>
                        </template>
                        <template #title>{{ trans.get('generic.rate') }} :</template>
                        <template #description>{{ costByMinute / 100 | formatMoneyPerMinute }}
                            <template v-if="hasUserMobileTax">
                                + {{ userMobileTax / 100 | formatMoneyPerMinute }} {{ trans.get('generic.for_mobile') }}
                            </template>
                            <template v-if="hasAdditionalCost">
                                + {{ costByCallAdditional / 100 | formatMoneyPerCall }}
                            </template>
                        </template>
                    </base-list-item>
                </base-list>
            </div>

            <template v-if="showUseCredit">
                <modal-heading :level="2">{{ trans.get('generic.use_credit') }}</modal-heading>

                <base-radio-input-billing-type
                    v-model="value"
                    value="credit"
                    :title="this.trans.get('generic.available_credit')"
                    :description="availableCredits / 100 | formatMoney"/>
            </template>

            <template v-else-if="showBuyPack">
                <div class="mb-6">
                    <modal-heading :level="2">
                        {{ trans.get('generic.modals.payment_options.refill_credits') }}
                    </modal-heading>
                    <modal-paragraph>
                        {{ trans.get('generic.modals.payment_options.when_credits_end_consultation_is_stopped') }}
                    </modal-paragraph>
                </div>

                <div class="grid grid-cols-3">
                    <base-radio-input-pack
                        v-model="value"
                        :value="packs[0].id"
                        :cost="packs[0].cost"
                        :credits="packs[0].credits"
                        :pack-number="1"
                    />
                    <base-radio-input-pack
                        v-model="value"
                        :value="packs[1].id"
                        :cost="packs[1].cost"
                        :credits="packs[1].credits"
                        :pack-number="2"
                        most-popular
                    />
                    <base-radio-input-pack
                        v-model="value"
                        :value="packs[2].id"
                        :cost="packs[2].cost"
                        :credits="packs[2].credits"
                        :pack-number="3"
                    />
                </div>
            </template>

            <template v-if="showUsePayPerUse">
                <base-separator>{{ trans.get('generic.or') }}</base-separator>

                <template v-if="!canPaypal && !canDiagonal">
                    <div class="mb-6">
                        <modal-heading :level="2">{{ trans.get('generic.pay_per_use_label') }}</modal-heading>
                    </div>

                    <base-radio-input-billing-type
                        v-model="value"
                        name="billing_type"
                        value="cb"
                        :title="this.trans.get('generic.pay_per_use_title')"
                        :description="costByMinute / 100 | formatMoneyPerMinute"
                    />
                </template>

                <template v-else>
                    <div class="mb-6">
                        <modal-heading :level="2">{{ trans.get('generic.pay_per_use_label') }}</modal-heading>
                        <modal-paragraph>{{ trans.get('generic.pay_per_use_title') }}</modal-paragraph>
                    </div>

                    <div :class="{'grid-cols-2': !canPaypal || !canDiagonal, 'grid-cols-3': canPaypal && canDiagonal}"
                         class="grid mb-2">
                        <base-radio-ppu-type
                            v-model="value"
                            :title="trans.get('payment.credit_card')"
                            :cost_by_minute="costByMinute"
                            value="cb"
                            @change="onChangePayPerUseType"
                        />
                        <base-radio-ppu-type
                            v-model="value"
                            v-if="canDiagonal"
                            :title="trans.get('payment.direct_debit')"
                            :cost_by_minute="costByMinute"
                            value="diagonal"
                            @change="onChangePayPerUseType"
                        />
                        <base-radio-ppu-type
                            v-model="value"
                            v-if="canPaypal"
                            title="PayPal"
                            :cost_by_minute="costByMinute"
                            value="paypal"
                            @change="onChangePayPerUseType"
                        />
                    </div>
                </template>

                <template v-if="showPaymentMethodDiagonalForm">
                    <div class="flex">
                        <base-title class="flex-grow">{{ trans.get('payment.bank_transfer') }}</base-title>
                        <div>
                            <img :src="assetManager.getImageAsset('payment/brands/sepa.png')"
                                 class="h-6 inline-block" alt="Sepa"/>
                        </div>
                    </div>
                    <base-paragraph>{{ trans.get('payment.enter_bank_info') }}</base-paragraph>
                    <payment-method-diagonal-form ref="payment-method-diagonal-form"
                                                  @success="paymentMethodDiagonalCreated"
                                                  @error="loading = false"/>
                </template>

                <div v-if="billingTypeIsPayPerUse">
                    <base-checkbox v-model="generalConditionsValue"
                                   :error="generalConditionsError"
                                   @change="onChangeGeneralConditions"
                                   block>
                        <span v-html="generalConditions"></span>
                    </base-checkbox>
                    <base-checkbox v-model="rightOfWithdrawalValue"
                                   v-if="!config.get('payment-methods.ppu.hide_right_of_withdrawal_checkbox')"
                                   :error="rightOfWithdrawalError"
                                   @change="onChangeGeneralConditions"
                                   block>
                        {{ trans.get('generic.accept_right_of_withdrawal') }}
                    </base-checkbox>
                </div>
            </template>

            <div v-if="!this.showPayPalButton" class="mt-6">
                <base-button block @click="submit" :loading="this.loading">
                    <template v-if="this.isFastPass">
                        {{ trans.get('generic.use_fast_pass') }}
                    </template>
                    <template v-else>
                        {{ trans.get('generic.validate_my_choice') }}
                    </template>
                </base-button>
            </div>

            <div v-show="this.showPayPalButton" class="mt-6">
                <base-button v-if="isPaypalComponentLoading" disabled loading block/>
                <div v-show="!isPaypalComponentLoading" id="paypal-component"></div>
            </div>

            <div class="flex justify-center mt-4 ml-2 sm:ml-4 sm:mb-8">
                <div v-for="brand in config.get('payment-methods.ppu.displayed_brands')"
                     class="mr-2 sm:mr-4 max-w-12">
                    <img :src="assetManager.getImageAsset('payment/brands/' + brand + '.png')" alt="Brands"/>
                </div>
            </div>
        </template>
    </base-modal>
</template>

<script>
import BaseButton from "../../elements/BaseButton";
import BaseCheckbox from '../../elements/BaseCheckbox';
import BaseLink from "../../elements/BaseLink";
import BaseList from "../../elements/BaseList";
import BaseListItem from "../../elements/BaseListItem";
import BaseRadioInputBillingType from "../../elements/custom/BaseRadioInputBillingType";
import BaseRadioInputPack from "../../elements/custom/BaseRadioInputPack";
import BaseRadioPpuType from "../../elements/custom/BaseRadioPpuType";
import BaseSeparator from "../../elements/BaseSeparator";
import ChatMixin from "../../chat/ChatMixin";
import ModalHeading from "../../modals/elements/ModalHeading";
import ModalParagraph from "../../modals/elements/ModalParagraph";
import PaymentMethodDiagonalForm from "../../account/PaymentMethodDiagonalForm.vue";
import {mapGetters, mapActions} from "vuex";
import {ziggy_route} from "../../ZiggyMixin";
import BaseParagraph from "../../elements/BaseParagraph.vue";
import BaseTitle from "../../elements/BaseTitle.vue";

const importPhoneNumber = () => import('awesome-phonenumber');

export default {
    name: "ModalPaymentOptions",
    components: {
        BaseTitle,
        BaseParagraph,
        BaseButton,
        BaseCheckbox,
        BaseLink,
        BaseList,
        BaseListItem,
        BaseRadioInputBillingType,
        BaseRadioInputPack,
        BaseRadioPpuType,
        BaseSeparator,
        ModalHeading,
        ModalParagraph,
        PaymentMethodDiagonalForm,
    },
    mixins: [ChatMixin],
    computed: {
        ...mapGetters('modals', ['getParam', 'getLoading']),
        ...mapGetters('payment', [
            'canDiagonal',
            'canPpu',
            'getCredits',
            'getDiagonals',
            'getPacksToDisplay',
            'getPaypals',
            'isExemptedFromAdditionalCostByCall',
            'isExemptedFromMobileTax',
            'selectFirstPackByDefault',
        ]),
        showStartConsultation() {
            // TODO : Migration FREE CALL PRIME : Pour l'instant on passe par une autre popup : AjaxClassicController -> primeFreeCallActivation()
            // TODO : Migration VOUCHER : Pour l'instant on passe par une autre popup : PopupController -> case 'call-start'
            if (this.isVoucher) {
                return true
            }

            if (this.tooMuchCredit && !this.isProspect) {
                return true;
            }

            return this.prospectCanConsultForFree;
        },
        showUseCredit() {
            return !this.insufficientCredit && !this.tooMuchCredit && !this.isProspect;
        },
        showBuyPack() {
            return this.packs.length > 0;
        },
        showUsePayPerUse() {
            if (!this.canPpu || this.tooMuchCredit) {
                return false;
            }

            if (this.isCall) {
                return this.isProspect ? this.config.get('payment-methods.ppu.call.enabled_for_prospect') : true;
            }

            if (this.isChat) {
                return this.isProspect ? this.config.get('payment-methods.ppu.chat.enabled_for_prospect') : true;
            }

            return false;
        },
        showPayPalButton() {
            return this.billingTypeIsPayPerUsePaypal && this.getPaypals.length === 0;
        },
        showPaymentMethodDiagonalForm() {
            return this.billingTypeIsPayPerUseDiagonal && this.getDiagonals.length === 0;
        },
        masterName() {
            if (!this.offer) {
                return '';
            }

            return this.offer.name;
        },
        costByMinute() {
            if (!this.offer || !this.offer.cost_by_minute) {
                return 0;
            }

            return this.offer.cost_by_minute;
        },
        costByCallAdditional() {
            if (!this.offer || !this.offer.cost_by_call_additional) {
                return 0;
            }

            return this.offer.cost_by_call_additional;
        },
        userMobileTax() {
            return this.config.get('call.mobile_call_additional_cost_per_minute');
        },
        packs() {
            return this.getPacksToDisplay;
        },
        userPhoneNumber() {
            return this.meFromUserStore().favorite_phone ? '+' + this.meFromUserStore().favorite_phone : null;
        },
        billingTypeIsPayPerUse() {
            return this.billingTypeIsPayPerUseCb || this.billingTypeIsPayPerUsePaypal || this.billingTypeIsPayPerUseDiagonal;
        },
        billingTypeIsPayPerUseCb() {
            return this.value === 'cb';
        },
        billingTypeIsPayPerUsePaypal() {
            return this.value === 'paypal';
        },
        billingTypeIsPayPerUseDiagonal() {
            return this.value === 'diagonal';
        },
        billingTypeIsCredit() {
            return this.value === 'credit';
        },
        isCall() {
            return this.consultationType === 'call';
        },
        isChat() {
            return this.consultationType === 'chat';
        },
        isProspect() {
            return this.meFromUserStore().transfo === 'prospect';
        },
        isFastPass() {
            return this.fastPass;
        },
        isVoucher() {
            return this.voucher;
        },
        canPaypal() {
            return this.config.get('payment-methods.ppu.paypal') && !this.isChat;
        },
        hasAdditionalCost() {
            return this.isCall && this.costByCallAdditional > 0 && !this.isExemptedFromAdditionalCostByCall;
        },
        hasUserMobileTax() {
            let userPhoneNumberIsMobile = false;
            if (this.PhoneNumber) {
                let phoneNumber = new this.PhoneNumber(this.userPhoneNumber);
                userPhoneNumberIsMobile = phoneNumber.isMobile();
            }
            return this.isCall &&
                userPhoneNumberIsMobile &&
                this.config.get('call.display_mobile_call_additional_cost_per_minute') &&
                this.userMobileTax > 0 &&
                !this.isExemptedFromMobileTax;
        },
        insufficientCredit() {
            if (!this.offer || !this.offer.cost_by_minute) {
                return false;
            }

            return this.availableCredits < this.offer.cost_by_minute;
        },
        tooMuchCredit() {
            return !this.insufficientCredit && this.availableCredits > 2500;
        },
        prospectCanConsultForFree() {
            if (this.offer.no_free_calls) {
                return false;
            }

            if (this.isCall) {
                return this.isProspect && !this.insufficientCredit;
            } else if (this.isChat) {
                return this.isProspect && !this.insufficientCredit && this.config.get('chat.free_chat_enabled_for_prospect');
            }
        },
    },
    methods: {
        ...mapActions('chat', ['startLiveChat', 'loadConversations']),
        ...mapActions('modals', ['closeModal', 'openModal', 'setModalIsLoading']),
        ...mapActions('payment', ['loadPaymentInfo', 'createSession']),
        submit() {
            if (this.billingTypeIsCredit) {
                this.submitCredit();
            } else if (this.billingTypeIsPayPerUse) {
                this.submitPayPerUse();
            } else {
                this.submitPack();
            }
        },
        submitPack() {
            this.closeModal();

            let oid = this.offer ? this.offer.id : 0;

            ajax_popup('buy', {
                oid: oid,
                p: this.value,
                type: this.consultationType,
                apple_pay_available: window.ApplePaySession ? 'true' : 'false'
            });
        },
        submitCredit() {
            this.closeModal();

            if (this.isCall) {
                if (this.isFastPass) {
                    ajax_popup('prime-usage', {step: 'fast_pass_validation', oid: this.offer.id, ppu: 0})
                } else {
                    ajax_popup('call-start', {oid: this.offer.id, step: 2, ppu: 0});
                }
            } else if (this.isChat) {
                this.startChat('credit');
            }
        },
        submitPayPerUse() {
            if (!this.validateGeneralConditions()) {
                return false;
            }

            if (this.showPaymentMethodDiagonalForm) {
                this.loading = true;
                this.$refs['payment-method-diagonal-form'].createDiagonal();
                return false;
            }

            this.closeModal();

            if (this.isCall) {
                this.submitCallPayPerUse();
            }

            if (this.isChat) {
                this.submitChatPayPerUse();
            }
        },
        submitCallPayPerUse() {
            let payPerUsePaymentMethod = null;

            if (this.billingTypeIsPayPerUseCb) {
                payPerUsePaymentMethod = 'credit_card';
            } else if (this.billingTypeIsPayPerUsePaypal) {
                payPerUsePaymentMethod = 'paypal';
            } else if (this.billingTypeIsPayPerUseDiagonal) {
                payPerUsePaymentMethod = 'diagonal';
            } else {
                return false;
            }

            if (this.isFastPass) {
                ajax_popup('prime-usage', {
                    step: 'fast_pass_validation',
                    oid: this.offer.id,
                    ppu: 1,
                    ppu_payment_method: payPerUsePaymentMethod
                });
            } else {
                ajax_popup('call-start', {
                    oid: this.offer.id,
                    step: 2,
                    ppu: 1,
                    ppu_payment_method: payPerUsePaymentMethod
                });
            }
        },
        submitChatPayPerUse() {
            if (this.billingTypeIsPayPerUseCb) {
                this.startChat('pay_per_use');
            } else if (this.billingTypeIsPayPerUsePaypal) {
                this.startChat('pay_per_use_paypal');
            } else if (this.billingTypeIsPayPerUseDiagonal) {
                this.startChat('pay_per_use_diagonal');
            }
        },
        onChangePayPerUseType() {
            if (this.billingTypeIsPayPerUsePaypal && !this.isPaypalComponentInitiated && this.getPaypals.length === 0) {
                this.initPayPerUsePaypal();
            }
        },
        onChangeGeneralConditions() {
            if (this.paypalComponentActions === null) {
                return false;
            }

            if (this.generalConditionsValue) {
                this.generalConditionsError = '';
            }

            if (this.rightOfWithdrawalValue) {
                this.rightOfWithdrawalError = '';
            }

            if (this.generalConditionsValue && this.rightOfWithdrawalValue) {
                this.paypalComponentActions.enable();
            } else {
                this.paypalComponentActions.disable();
            }
        },
        validateGeneralConditions() {
            this.generalConditionsError = !this.generalConditionsValue ? this.trans.get('validation.general_conditions.required') : '';

            if (this.config.get('payment-methods.ppu.hide_right_of_withdrawal_checkbox')) {
                return this.generalConditionsValue;
            } else {
                this.rightOfWithdrawalError = !this.rightOfWithdrawalValue ? this.trans.get('validation.right_of_withdrawal.required') : '';
                return this.generalConditionsValue && this.rightOfWithdrawalValue;
            }
        },
        initPayPerUsePaypal() {
            this.isPaypalComponentInitiated = true;

            let callbackType = null;
            let paymentMethodType = null;

            if (this.isCall && this.billingTypeIsPayPerUsePaypal) {
                callbackType = 'call_ppu_paypal';
                paymentMethodType = 'paypal';
            } else if (this.isChat && this.billingTypeIsPayPerUsePaypal) {
                callbackType = 'chat_ppu_paypal';
                paymentMethodType = 'paypal';
            } else {
                return false;
            }

            this.createSession({
                data: {
                    sessionType: 'paypal'
                },
                successCallback: (response) => {
                    const session_id = response.data.session_id;
                    const sessionData = response.data.session_data;
                    const reference = response.data.reference;
                    const environment = response.data.adyen_authentication_environment;
                    const clientKey = response.data.adyen_authentication_client_key;
                    const locale = response.data.shopper_locale;

                    const configuration = {
                        environment: environment,
                        clientKey: clientKey,
                        locale: locale,
                        analytics: {
                            enabled: true,
                        },
                        session: {
                            id: session_id,
                            sessionData: sessionData,
                        },
                        onPaymentCompleted: (result, component) => {
                            window.globalVue.$store.dispatch('payment/startPaymentProcessing', {
                                reference: reference,
                                callbackType: callbackType,
                                offerId: this.offerId,
                                paymentMethodType: paymentMethodType
                            });
                            showModalV5(globals.PAYMENT_PROCESSING);
                        },
                        onError: (error, component) => {
                            if (error.name === 'CANCEL') {
                                window.globalVue.$store.commit('payment/setPaymentStatus', 'canceled');
                            } else {
                                window.globalVue.$store.commit('payment/setPaymentStatus', 'error');
                            }
                        },
                        paymentMethodsConfiguration: {},
                    };

                    const checkout = window.AdyenCheckout(configuration);

                    checkout.then((checkout) => {
                        const paypalConfiguration = {
                            style: {
                                layout: "vertical",
                                color: "gold"
                            },
                            onInit: (data, actions) => {
                                this.isPaypalComponentLoading = false;
                                this.paypalComponentActions = actions;
                                this.onChangeGeneralConditions();
                            },
                            onClick: (data, actions) => {
                                this.validateGeneralConditions();
                            },
                            blockPayPalCreditButton: true,
                            blockPayPalPayLaterButton: true,
                        };

                        checkout.create('paypal', paypalConfiguration).mount('#paypal-component');
                    });
                }
            });
        },
        checkModalIsLoaded() {
            if (!this.isPaymentInfoLoading && !this.isOfferInfoLoading) {
                this.setModalIsLoading(false);

                if (this.showStartConsultation) {
                    return false;
                }

                if (this.showBuyPack) {
                    this.value = (this.selectFirstPackByDefault) ? this.packs[0].id : this.packs[1].id;
                }

                if (this.showUseCredit) {
                    this.value = 'credit';
                }
            }
        },
        paymentMethodDiagonalCreated() {
            this.loadPaymentInfo(() => {
                this.loading = false;

                this.$nextTick(() => {
                    if (this.getDiagonals.length > 0) this.submitPayPerUse();
                });
            });
        },
        updatePhoneNumber() {
            this.closeModal();
            let oid = this.offer ? this.offer.id : 0;
            ajax_popup('phone_number_update', {oid: oid});
        },
        async startChat(billing_type) {
            let participants = [
                this.meFromUserStore(),
                this.offer,
            ];

            await this.loadConversations();

            let event = {participants: participants, billing_type: billing_type};

            if (this.isVoucher) {
                event['billing_advantage'] = 'master_gift_to_client';
            }

            await this.startLiveChat(event);
        }
    },
    async mounted() {
        this.setModalIsLoading(true);

        this.consultationType = this.getParam['payment-options']['consultation_type'];
        this.offerId = this.getParam['payment-options']['offer_id'];
        this.fastPass = this.getParam['payment-options']['fast_pass'];
        this.voucher = this.getParam['payment-options']['voucher'];

        importPhoneNumber().then((PhoneNumber) => {
            this.PhoneNumber = PhoneNumber.default;
        });

        this.loadPaymentInfo(() => {
            this.$nextTick(() => {
                this.availableCredits = this.getCredits;
                this.isPaymentInfoLoading = false;
                this.checkModalIsLoaded();
            });
        });

        if (this.offerId === undefined) {
            return false;
        }

        await axios.get(ziggy_route('chat.offer.get', {
            offerId: this.offerId,
        })).then(response => {
            this.$nextTick(() => {
                this.offer = response.data.data;
                this.isOfferInfoLoading = false;
                this.checkModalIsLoaded();
            });
        }).catch(error => {
            this.openModal({
                name: globals.GENERIC_ERROR,
            });
        });
    },
    data() {
        return {
            availableCredits: '',
            consultationType: '',
            fastPass: false,
            voucher: false,
            generalConditions: this.trans.get('generic.read_and_accept_the_general_conditions', {
                general_conditions_link: this.compileHtml('<base-link href="' + this.trans.get('routes.legal_documents.general_conditions_of_service') + '" target="_blank">' + this.trans.get('generic.read') + '</base-link>')
            }),
            generalConditionsError: '',
            generalConditionsValue: false,
            isOfferInfoLoading: true,
            isPaymentInfoLoading: true,
            isPaypalComponentInitiated: false,
            isPaypalComponentLoading: true,
            loading: false,
            PhoneNumber: null,
            offer: {},
            offerId: null,
            paypalComponentActions: null,
            rightOfWithdrawalError: '',
            rightOfWithdrawalValue: false,
            value: null,
        };
    },
}
</script>
