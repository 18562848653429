<template>
    <form action="ajax/go-to-second-register-step.js" method="POST" target="ajax_upload">
        <div class="flex">
            <base-text-input type="email"
                             class="flex-grow mr-2"
                             name="email"
                             v-model="email_value"
                             :label="trans.get('generic.email')"
                             :placeholder="trans.get('generic.email')"
                             :error="email_error"
                             @keydown="email_error = ''"
                             outlined/>

            <base-button type="submit" class="flex-none">
                <font-awesome-icon :icon="['fas', 'paper-plane']" size="lg"></font-awesome-icon>
            </base-button>
        </div>
    </form>
</template>

<script>
export default {
    name: 'FooterEmailCatcher',
    data() {
        return {
            email_value: '',
            email_error: '',
        }
    },
}
</script>
