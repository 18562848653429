import Lang from 'lang.js';
import moment from 'moment';

const default_locale = window.default_locale;
const fallback_locale = window.fallback_locale;
const messages = window.messages;

Vue.prototype.trans = new Lang({
  messages: messages,
  locale: default_locale,
  fallback: fallback_locale
});

if (default_locale.indexOf('viversum') !== -1 || default_locale.indexOf('cocotarot') !== -1) {
    moment.locale(fallback_locale);
} else {
    moment.locale(default_locale);
}
