<template>
    <base-modal :error="loadingError">
        <modal-heading>{{ trans.get('generic.password_lost_title') }}</modal-heading>

        <div v-if="postFormSucceed">
            <modal-paragraph>{{ trans.get('generic.email_sent') }}</modal-paragraph>

            <base-button autofocus block @click="cancel">OK</base-button>
        </div>

        <div v-else>
            <form @submit="postForm">
                <modal-paragraph>{{ trans.get('generic.enter_email_address') }}</modal-paragraph>

                <div class="mb-4">
                    <base-text-input type="email"
                                     autofocus
                                     outlined
                                     v-model="email"
                                     :label="trans.get('generic.email')"
                                     :placeholder="trans.get('generic.email')"
                                     :error="errorEmail"
                                     @keydown="errorEmail = ''">
                        <template #icon><font-awesome-icon :icon="['fal', 'envelope']"/></template>
                    </base-text-input>
                </div>

                <div class="grid grid-cols-2 gap-4">
                    <base-button type="button" outlined @click="cancel">
                        {{ trans.get('generic.cancel') }}
                    </base-button>
                    <base-button type="submit" :loading="formLoading">
                        {{ trans.get('generic.validate') }}
                    </base-button>
                </div>
            </form>
        </div>
    </base-modal>
</template>

<script>
import axios from 'axios';

import {mapState} from 'vuex';
import {mapMutations} from 'vuex';
import {mapGetters} from 'vuex';
import BaseButton from "../elements/BaseButton";
import BaseTextInput from "../elements/BaseTextInput";
import ValidationLang from "../ValidationLang.js";
import ModalHeading from "./elements/ModalHeading";
import ModalParagraph from "./elements/ModalParagraph";

export default {
    components: {ModalParagraph, ModalHeading, BaseTextInput, BaseButton},
    computed: {
        ...mapState('modals', ['additionalParams']),
        ...mapGetters('modals', ['getParam'])
    },
    methods: {
        ...mapMutations('modals', [
            'changeLoadingState', 'addParam',
        ]),
        cancel() {
            this.$emit('cancel');
        },
        postForm(e) {
            e.preventDefault();
            this.formLoading = true;
            axios.post('/password/lost', {
                email: this.email
            }).then((res) => {
                this.postFormSucceed = true;
            }).catch((errorResponse) => {
                let errorsInResponse = errorResponse.response.data.errors;
                let validationLang = new ValidationLang();
                let validationErrorsMap = validationLang.getMessageTranslated(errorsInResponse)
                this.errorEmail = validationErrorsMap.get('email').join('<br/>');
            }).finally(() => {
                this.formLoading = false;
            });
        },
    },

    async created() {
        this.changeLoadingState(true);
        await axios
            .get(this.url)
            .then(response => {
                if (response.data) {
                    this.changeLoadingState(false);
                }
                this.response = response.data;

            }).catch(error => {
                this.changeLoadingState(false);
                this.loadingError = error
            });
    },

    data() {
        return {
            url: '/ajax/get-online-offers-call-button-text.js',
            response: null,
            loadingError: '',
            email: '',
            csrf: '',
            postFormSucceed: false,
            errorEmail: '',
            formLoading: false,
        }
    },
    mounted() {
        this.csrf = window.csrf_token;
        this.email = this.getParam['password-lost']['email'];
    }
}

</script>
