<template>
    <div class="follow-up-to-one-list-item">
        <div class="follow-up-to-one-list-item-client-infos">
            <p>
                <span class="follow-up-to-one-list-item-client-name">{{ customer.name }}</span>
                <span class="text-sm whitespace-nowrap">
                    <font-awesome-icon :icon="['far', 'cake-candles']"/> {{ customer.dt_birth | formatDate }}
                </span>
            </p>
            <p class="text-sm">
                {{ trans.get('generic.modals.follow_up_to_one_last_connection_text') }} {{ conversation.dt_last_conversation | diffFromNow }}
            </p>
        </div>
        <div class="flex-none self-center">
            <base-button outlined small :disabled="!conversation.follow_up.enabled" @click="$emit('selectItem', conversation)">
                <font-awesome-icon :icon="['far', 'message-lines']" class="mx-3" />
            </base-button>
        </div>
    </div>
</template>


<style lang="scss">

.follow-up-to-one-list-item {
    @apply w-full flex p-2 border-b border-neutral-300 text-neutral-400;
}

.follow-up-to-one-list-item-client-infos {
    @apply flex-grow self-center;
}

.follow-up-to-one-list-item-client-name {
    @apply text-primary font-bold pr-2 overflow-ellipsis overflow-hidden;
}

</style>


<script>
import BaseTimer from "../elements/BaseTimer";
import BaseButton from "../elements/BaseButton";
import ChatMixin from './ChatMixin';
import {mapActions} from 'vuex';

export default {
    name: "FollowUpToOneSearchListItem",
    mixins: [ChatMixin],
    components: {BaseTimer, BaseButton},
    props: {
        conversation: Object
    },
    computed: {
        customer() {
            let customer = this.interlocutor(this.conversation.participants);

            return customer ? customer : {};
        }
    }
}
</script>
