import {makeEchoInstance} from "../../utilities/echo-factory";
import user_store from '../user/store';

const OfferListMixin = {
    Echo: makeEchoInstance(),
    props: {
        randomlyHighlightOffers: Boolean,
    },
    mounted() {
        if (this.randomlyHighlightOffers) {
            this.initRandomlyHighlightOffers();
        }
    },
    methods: {
        initStatusChangedListener() {
            let offerChannelName = 'offer_' + window.service_id;
            OfferListMixin.Echo.channel(offerChannelName).listen('Offer.OfferStatusUpdated', e => {
                let offer_status = e.resource;
                this.updateOffer({
                    id: offer_status.id,
                    status_details: offer_status,
                });
                this.$forceUpdate();
            });

            let user_infos = user_store.getters.getLoggedUserInfos(user_store.state);
            if (user_infos.id) {
                OfferListMixin.Echo.private('user.' + user_infos.id).listen('User.UserUpdated', e => {
                    window.globalVue.$store.dispatch('user/loadUserInfos', e.userResource);
                    this.$forceUpdate();
                });
            }

            OfferListMixin.Echo.connector.pusher.connection.bind('state_change', (states) => {
                if (this.offerListReady) {
                    this.handlePusherConnectionStateChange(states);
                }
            });
        },
        getOfferIndex(offer_id) {
            const index = this.offers.findIndex(existingOffer => existingOffer.id === offer_id);
            return index > -1 ? index : null;
        },
        createOffer(offer) {
            this.offers.push(offer);
        },
        updateOffer(offer) {
            let index = this.getOfferIndex(offer.id);

            if (index !== null) {
                for (const [key, value] of Object.entries(offer)) {
                    this.offers[index][key] = value;
                }
            }
        },
        createOrUpdateOffer(offer) {
            this.getOfferIndex(offer.id) ? this.updateOffer(offer) : this.createOffer(offer);
        },
        handlePusherConnectionStateChange(states) {
            if (states.previous === 'connected' && states.current === 'connecting') {
                console.log("Pusher connection lost");
                this.shouldReloadPageOnReconnectionTimeout = setTimeout(() => {
                    this.shouldReloadPageOnReconnection = true;
                }, 5000);

                this.displayPopupTimeout = setTimeout(() => {
                    window.globalVue.$store.dispatch('modals/openModalMessage', {
                        small: true,
                        title: this.trans.get('modals.pusher_connection_lost.connection_issue'),
                        description: this.trans.get('modals.pusher_connection_lost.unstable_connection_detected'),
                        buttonText: this.trans.get('modals.pusher_connection_lost.reload_page')
                    });
                }, 10000);
            }

            if (states.current === 'connected') {
                console.log("Pusher connection back");
                clearTimeout(this.shouldReloadPageOnReconnectionTimeout);
                clearTimeout(this.displayPopupTimeout);
                if (this.shouldReloadPageOnReconnection) {
                    this.shouldReloadPageOnReconnection = false;
                    window.location.reload();
                }
            }
        },
        initRandomlyHighlightOffers() {
            setInterval(() => {
                let offerCards = this.$refs['offer-card'];
                if (offerCards === undefined) return false;

                let visibleOfferCards = [];
                offerCards.forEach((offerCard, index) => {
                    if (offerCard.isInViewport()) {
                        visibleOfferCards.push(offerCard);
                    }
                });
                if (visibleOfferCards.length === 0) return false;

                let selectedOfferCard = visibleOfferCards[(Math.floor(Math.random() * visibleOfferCards.length))];
                if (selectedOfferCard === undefined) return false;

                selectedOfferCard.highlight();
            }, 12000);
        },
    },
    data() {
        return {
            displayPopupTimeout: null,
            offerListReady: false,
            offers: [],
            shouldReloadPageOnReconnection: false,
            shouldReloadPageOnReconnectionTimeout: null,
        }
    }
}

export default OfferListMixin;
