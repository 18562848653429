<template>
    <button v-if="!audioError" class="base-audio-player-pause" @click="pause"></button>
</template>

<style lang="scss">
.base-audio-player-pause {
    @apply hidden;
}
</style>

<script>
export default {
    name: "BaseAudioPlayer",
    props: {
        src: {
            type: String,
        },
        initialDuration: {
            type: Number,
            default: 0
        },
    },
    methods: {
        init() {
            this.audio = new Audio(this.src);
            this.audio.preload = 'auto';
            this.setVolume(this.volume);

            this.audio.addEventListener('loadstart', () => {
                this.status = 'loading';
                this.update();
            }, false);

            this.audio.addEventListener('loadedmetadata', () => {
                this.status = 'pause';
                this.update();
            }, false);

            this.audio.addEventListener('play', () => {
                this.status = 'playing';
                this.update();
            }, false);

            this.audio.addEventListener('timeupdate', this.update, false);

            this.audio.addEventListener('pause', () => {
                this.status = 'paused';
                this.update();
            }, false);

            this.audio.addEventListener('ended', this.end, false);

            this.audio.addEventListener('error', () => {
                this.setError(true);
                this.update();
            }, false);
        },
        play() {
            this.setError(false);
            this.pauseAll();
            if (this.audio !== null) {
                this.audio.play();
            }
        },
        pause() {
            if (this.audio !== null) {
                this.audio.pause();
            }
        },
        pauseAll() {
            let buttons = document.getElementsByClassName('base-audio-player-pause');
            for (let i = 0; i < buttons.length; i++) {
                buttons[i].click();
            }
        },
        update() {
            if (this.audio !== null) {
                this.currentTime = this.audio.currentTime;

                if (this.initialDuration > 0) {
                    this.duration = this.initialDuration;
                } else {
                    if (!Number.isNaN(this.audio.duration) && this.audio.duration !== Infinity) {
                        this.duration = this.audio.duration;
                    }
                }

                if (this.duration > 0) {
                    this.playingProgression = this.currentTime / this.duration * 100;
                }

                if (this.audio.error !== null) {
                    this.setError(true);
                } else if (!this.audio.paused && this.duration > 0) {
                    this.status = 'playing';
                }

                let buffered = this.audio.buffered;
                if (buffered.length) {
                    this.loadingProgression = Math.round(100 * buffered.end(0) / this.audio.duration);
                }
            }

            if (this.audioError) {
                this.status = 'error';
            }

            this.$emit('update');
        },
        setVolume(value) {
            const volume = Number(value);
            if (Number.isNaN(volume) || volume >= 1) {
                this.volume = 1;
            } else if (volume <= 0) {
                this.volume = 0;
            } else {
                this.volume = volume;
            }

            if (this.audio !== null) {
                this.audio.volume = this.volume;
                this.audio.muted = this.volume === 0;
            }
        },
        setPlayingProgression(value) {
            this.playingProgression = value;

            let time = this.duration * (value / 100);
            this.setCurrentTime(time);
        },
        setCurrentTime(value) {
            if (value !== this.currentTime) {
                this.currentTime = value;

                if (this.audio !== null) {
                    if (this.status === 'playing') {
                        this.wasPlaying = true;
                    }

                    this.status = 'changingTime';
                    this.audio.pause();
                    this.audio.currentTime = value;

                    clearTimeout(this.setCurrentTimeTimeout);
                    this.setCurrentTimeTimeout = setTimeout(() => {
                        if (this.wasPlaying) {
                            this.play();
                        } else {
                            this.status = 'paused';
                            this.update();
                        }
                        this.wasPlaying = false;
                    }, 250);
                }
            }
        },
        setError(error) {
            this.audioError = error;
            if (error) {
                this.$emit('error');
            }
        },
        end() {
            this.status = 'paused';
            this.update();
            this.$emit('end');
        },
    },
    mounted() {
        this.init();
    },
    beforeDestroy: function () {
        if (this.audio !== null) {
            this.audio.pause();
        }
    },
    data() {
        return {
            audio: null,
            volume: 1,
            status: '',
            wasPlaying: false,
            playingProgression: 0,
            currentTime: 0,
            duration: 0,
            loadingProgression: 0,
            setCurrentTimeTimeout: null,
            audioError: false,
        }
    },
}
</script>
