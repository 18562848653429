<template>
    <development>
        <div>
            <development-heading>Tooltip</development-heading>
            <base-tooltip type="info" :icon="['fal', 'circle-info']" text-tooltip="Text in Tooltip box" text="Example" position="bottom"></base-tooltip>

            <development-heading :level="2">Props :</development-heading>
            <ul>
                <li><strong>type</strong>: String - <em>'info', 'success', 'warning', 'danger'</em></li>
                <li><strong>position</strong>: String - <em>'top', 'bottom', 'right', 'left'</em></li>
                <li><strong>text</strong>: String</li>
                <li><strong>text-tooltip</strong>: String</li>
                <li><strong>icon</strong>: Array</li>
            </ul>

            <development-heading>Type</development-heading>
            <development-paragraph><strong>type</strong> : info / success / warning / danger</development-paragraph>
            <div class="space-y-2">
                <div>
                    <base-tooltip type="info" text-tooltip="Text in Tooltip box" text="I'm an Alert Usage Example" position="bottom"></base-tooltip>
                </div>
                <div>
                    <base-tooltip type="success" text-tooltip="Text in Tooltip box" text="I'm an Alert Usage Example" position="bottom"></base-tooltip>
                </div>
                <div>
                    <base-tooltip type="warning" text-tooltip="Text in Tooltip box" text="I'm an Alert Usage Example" position="bottom"></base-tooltip>
                </div>
                <div>
                    <base-tooltip type="danger" text-tooltip="Text in Tooltip box" text="I'm an Alert Usage Example" position="bottom"></base-tooltip>
                </div>

            </div>

            <development-heading>Icons</development-heading>
            <div class="space-y-2">
                <div>
                    <base-tooltip type="info" :icon="['fal', 'circle-info']" text-tooltip="Text in Tooltip box" text="I'm an Alert Usage Example" position="bottom"></base-tooltip>
                </div>
                <div>
                    <base-tooltip type="info" :icon="['fal', 'envelope']" text-tooltip="Text in Tooltip box" text="I'm an Alert Usage Example" position="bottom"></base-tooltip>
                </div>
                <div>
                    <base-tooltip type="info" :icon="['far', 'cake-candles']" text-tooltip="Text in Tooltip box" text="I'm an Alert Usage Example" position="bottom"></base-tooltip>
                </div>
                <div>
                    <base-tooltip type="info" :icon="['fal', 'message-lines']" text-tooltip="Text in Tooltip box" text="I'm an Alert Usage Example" position="bottom"></base-tooltip>
                </div>
            </div>

            <development-heading>Position</development-heading>
            <development-paragraph><strong>type</strong> : top / bottom / right / left</development-paragraph>
            <div class="space-y-2">
                <div>
                    <base-tooltip type="info" :icon="['fal', 'circle-info']" text="Top" text-tooltip="I'm an Alert Usage Example" position="top"></base-tooltip>
                </div>
                <div>
                    <base-tooltip type="success" :icon="['fal', 'envelope']" text="Bottom" text-tooltip="I'm an Alert Usage Example" position="bottom"></base-tooltip>
                </div>
                <div>
                    <base-tooltip type="warning" :icon="['far', 'cake-candles']" text="Right" text-tooltip="I'm an Alert Usage Example" position="right"></base-tooltip>
                </div>
                <div>
                    <base-tooltip type="danger" :icon="['fal', 'message-lines']" text="Left" text-tooltip="I'm an Alert Usage Example" position="left"></base-tooltip>
                </div>
            </div>
        </div>
    </development>
</template>
<script>
import Development from "./Development";
import DevelopmentHeading from "./elements/DevelopmentHeading";
import DevelopmentParagraph from "./elements/DevelopmentParagraph";
import BaseTooltip from "../elements/BaseTooltip";

export default {
    components: {BaseTooltip, DevelopmentParagraph, DevelopmentHeading, Development}
}
</script>
