<template>
    <div class="game-wheel">
        <div class="game-wheel-content">

            <transition v-bind:css="false">
                <img ref="wheel" class="game-wheel-image" :src="wheelSrc" :style="wheelStyle"/>
            </transition>

            <div class="game-wheel-buttons">
                <transition name="fade" mode="out-in">
                    <img v-if="!played"
                         :src="buttonPlaySrc"
                         class="cursor-pointer select-none"
                         key="buttonPlay"
                         @click="play"/>

                    <img v-else
                         :src="buttonPlayedSrc"
                         key="buttonPlayed"/>
                </transition>
            </div>

            <transition name="fade" mode="out-in">
                <img v-if="showPrize"
                     :src="prizeSrc"
                     class="game-wheel-prize"
                     :style="gameWheelPrizeStyle"
                     key="prize"
                     @click="prizeCallback"/>
            </transition>

            <div v-if="hasBackgrounds" class="game-wheel-background" style="z-index: -1">
                <slot name="backgrounds"/>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.game-wheel {
    @apply w-full relative;

    &:after {
        content: "";
        display: block;
        padding-bottom: 100%;
    }

    &-content {
        @apply absolute w-full h-full;
    }

    .game-wheel-image {
        @apply w-full h-full pointer-events-none select-none;
    }

    .game-wheel-buttons {
        @apply w-full h-full grid grid-cols-1 place-items-center absolute top-0 left-0 z-30;

        img {
            @apply w-full h-full rounded-full transform scale-25;
        }
    }

    .game-wheel-prize {
        @apply absolute w-full h-full top-0 left-0 select-none z-50;
    }

    .game-wheel-background {
        @apply absolute w-full h-full top-0 left-0;
    }
}

.fade-enter-active, .fade-leave-active {
    transition: opacity 1s ease;
}

.fade-enter, .fade-leave-to, .fade-leave-active {
    opacity: 0;
}
</style>

<script>
export default {
    name: "GameWheel",
    props: {
        maxValue: {
            type: Number,
            default: 8
        },
        buttonPlayImage: String,
        buttonPlayedImage: String,
        prizeAction: Function,
        afterShowPrizeAction: Function,
        prizeImage: String,
        wheelImage: String,
    },
    computed: {
        wheelSrc() {
            return this.urlManager.isUrl(this.wheelImage) ? this.wheelImage : this.assetManager.getImageAsset(this.wheelImage);
        },
        prizeSrc() {
            return this.urlManager.isUrl(this.prizeImage) ? this.prizeImage : this.assetManager.getImageAsset(this.prizeImage);
        },
        buttonPlaySrc() {
            return this.urlManager.isUrl(this.buttonPlayImage) ? this.buttonPlayImage : this.assetManager.getImageAsset(this.buttonPlayImage);
        },
        buttonPlayedSrc() {
            return this.urlManager.isUrl(this.buttonPlayedImage) ? this.buttonPlayedImage : this.assetManager.getImageAsset(this.buttonPlayedImage);
        },
        wheelStyle() {
            return {
                transform: this.rotationAngle !== 0 ? "rotate(" + this.rotationAngle + "deg)" : null,
                transition: this.rotationAngle !== 0 ? "transform 3s" : null,
            }
        },
        hasBackgrounds() {
            return !!this.$slots.backgrounds;
        },
        hasPrizeAction() {
            return !!this.prizeAction;
        },
        hasAfterShowPrizeAction() {
            return !!this.afterShowPrizeAction;
        },
        gameWheelPrizeStyle() {
            return {
                cursor: this.hasPrizeAction ? "pointer" : null,
            }
        }
    },
    methods: {
        play() {
            if (!this.played) {
                this.played = true;
                this.$emit('play');
            }
        },
        rotateWheel(value) {
            this.$refs.wheel.addEventListener("transitionend", () => {
                setTimeout(() => {
                    this.showPrize = true;
                    if (this.hasAfterShowPrizeAction) {
                        this.afterShowPrizeAction();
                    }
                }, 500);
            });
            let angle = (5 * 360) + ((360 / this.maxValue) * (value - 1));
            this.rotationAngle = angle * -1;
        },
        resetWheel() {
            this.rotationAngle = 0;
        },
        prizeCallback() {
            if (this.hasPrizeAction) {
                this.prizeAction();
            }
        },
    },
    data() {
        return {
            rotationAngle: 0,
            played: false,
            showPrize: false,
        }
    },
}
</script>
