export default {
    eventsListenedByChannel: {},

    listen(channel, eventName, callback) {
        if (channel !== null && !this.isAlreadyListening(channel.name, eventName)) {
            this.addEvent(channel.name, eventName);
            channel.listen(eventName, callback);
        }
    },

    listenForWhisper(channel, eventName, callback) {
        if (channel !== null && !this.isAlreadyListening(channel.name, eventName)) {
            this.addEvent(channel.name, eventName);
            channel.listenForWhisper(eventName, callback);
        }
    },

    addEvent(channelName, eventName) {
        if (!(channelName in this.eventsListenedByChannel)) {
            this.eventsListenedByChannel[channelName] = [];
        }
        this.eventsListenedByChannel[channelName].push(eventName);
    },

    isAlreadyListening(channelName, eventName) {
        return this.eventsListenedByChannel[channelName] !== undefined
            && this.eventsListenedByChannel[channelName].indexOf(eventName) !== -1;
    },

    getAllChannelsListened() {
        return Object.keys(this.eventsListenedByChannel);
    },

    removeChannelFromList(channelName) {
        if (this.eventsListenedByChannel[channelName] !== undefined) {
            delete this.eventsListenedByChannel[channelName];
        }
    }
}
