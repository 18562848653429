<template>
    <base-modal small :error="loadingError">
        <div v-if="refillSuccess">
            <modal-heading>
                {{ trans.get('chat.chat_live_refill_success') }}
            </modal-heading>

            <div class="grid grid-cols-1 mt-5">
                <base-button @click="closeModal">
                    {{ trans.get('chat.modals.back_to_chat') }}
                </base-button>
            </div>
        </div>

        <div v-else-if="refillFailed">
            <modal-heading>
                {{ trans.get('chat.chat_live_refill_error') }}
            </modal-heading>

            <div class="grid grid-cols-1 mt-5">
                <base-button @click="closeModal">
                    {{ trans.get('chat.modals.back_to_chat') }}
                </base-button>
            </div>
        </div>

        <div v-else>
            <modal-heading>
                {{ trans.get('generic.credit_soon_exhausted') }}
            </modal-heading>

            <base-list-item v-if="hasTime">
                <template #icon><font-awesome-icon :icon="['fas', 'circle-chevron-right']"/></template>
                <template #title>
                    {{ trans.get('generic.time_left_is') }}
                    <base-timer ref="billing_timer" countdown auto-start
                                v-slot="{hours, minutes, seconds}">
                        <template v-if="hours > 0">{{ hours + 'h' }}</template>
                        <template v-if="minutes > 0">{{ minutes + 'min' }}</template>
                        <template v-if="seconds > 0">{{ seconds + 's' }}</template>
                    </base-timer>
                </template>
            </base-list-item>

            <div class="grid grid-cols-1 mt-5">
                <base-button :loading="loadingRefill" @click="refill">
                    {{ trans.get('chat.chat_live_almost_over_notif_customer_button', {amount: refillAmount}) }}
                </base-button>
            </div>
        </div>
    </base-modal>
</template>

<script>
import BaseButton from "../elements/BaseButton";
import ModalHeading from "./elements/ModalHeading";
import {mapActions, mapGetters} from "vuex";
import BaseTimer from "../elements/BaseTimer";
import BaseListItem from "../elements/BaseListItem";

export default {
    name: "ModalChatRechargeLive",
    components: {BaseListItem, BaseTimer, BaseButton, ModalHeading},
    computed: {
        ...mapGetters('modals', ['getParam']),
        ...mapGetters('payment', ['getPackByType']),
        ...mapGetters('chat', [
            'getLiveSessionBillingTimeLeft',
            'hasLiveSessionBillingStarted',
            'refillFailed',
            'refillSuccess',
        ]),
        refillAmount() {
            return this.$options.filters.formatMoney(this.getPackByType('recharge_chat').cost / 100);
        },
        hasTime() {
            return this.time !== undefined && this.time !== null;
        }
    },
    methods: {
        ...mapActions('modals', ['closeModal']),
        ...mapGetters('chat', ['getLiveSessionMaxBillingTime', 'getOfferIdFromParticipants', 'getSelectedParticipants']),
        ...mapActions('chat', ['buyRefill']),
        refill() {
            this.loadingRefill = true;
            this.buyRefill(this.getOfferIdFromParticipants(this.getSelectedParticipants));
        }
    },
    mounted() {
        document.activeElement.blur();

        this.time = this.getParam['chat-recharge-live']['time'];

        if (this.hasTime) {
            this.$nextTick(() => {
                this.$refs.billing_timer.setTime(this.time);
            });
        }
    },
    data() {
        return {
            loadingError: '',
            loadingRefill: false,
            time: null,
        };
    },
}
</script>
