<template>
    <div>
        <div class="payment-method-card">
            <div class="flex-grow">
                <base-list>
                    <base-list-item>
                        <span>{{ trans.get('payment.card.name') }} :</span>
                        <span><strong>{{ card.name }}</strong></span>
                    </base-list-item>
                    <base-list-item>
                        <span>{{ trans.get('payment.card.type') }} :</span>
                        <span><strong>{{ card.type }}</strong></span>
                    </base-list-item>
                    <base-list-item>
                        <span>{{ trans.get('payment.card.number') }} :</span>
                        <span><strong>{{ card.number }}</strong></span>
                    </base-list-item>
                    <base-list-item>
                        <span>{{ trans.get('payment.card.expiry_date') }} :</span>
                        <span><strong>{{ card.month }}/{{ card.year }}</strong></span>
                    </base-list-item>
                    <base-list-item>
                        <span>{{ trans.get('payment.card.name_on_card') }} :</span>
                        <span><strong>{{ card.owner }}</strong></span>
                    </base-list-item>
                </base-list>
            </div>
            <div class="flex">
                <base-button class="self-center" color="danger" outlined @click="toggleDeleteModal(true)">
                    <font-awesome-icon :icon="['fal', 'trash-can']"/>
                </base-button>
            </div>
        </div>

        <base-modal v-if="showDeleteModal" @close="toggleDeleteModal(false)" small>
            <base-title>{{ trans.get('payment.remove_credit_card') }}</base-title>

            <div class="payment-method-card">
                <div class="flex-grow">
                    <base-list>
                        <base-list-item>
                            <span>{{ trans.get('payment.card.name') }} :</span>
                            <span><strong>{{ card.name }}</strong></span>
                        </base-list-item>
                        <base-list-item>
                            <span>{{ trans.get('payment.card.type') }} :</span>
                            <span><strong>{{ card.type }}</strong></span>
                        </base-list-item>
                        <base-list-item>
                            <span>{{ trans.get('payment.card.number') }} :</span>
                            <span><strong>{{ card.number }}</strong></span>
                        </base-list-item>
                        <base-list-item>
                            <span>{{ trans.get('payment.card.expiry_date') }} :</span>
                            <span><strong>{{ card.month }}/{{ card.year }}</strong></span>
                        </base-list-item>
                        <base-list-item>
                            <span>{{ trans.get('payment.card.name_on_card') }} :</span>
                            <span><strong>{{ card.owner }}</strong></span>
                        </base-list-item>
                    </base-list>
                </div>
            </div>

            <div class="grid grid-cols-1 gap-4 mt-5">
                <base-button :loading="loading" color="danger" @click="deleteCard">
                    <font-awesome-icon :icon="['fal', 'trash-can']"/>&nbsp;{{ trans.get('payment.remove') }}
                </base-button>
            </div>
        </base-modal>
    </div>
</template>

<style lang="scss">
.payment-method-card {
    @apply flex bg-white border border-neutral-500 rounded-lg p-4 pr-2;

    span {
        @apply text-main whitespace-nowrap;
    }
}
</style>

<script>
import BaseButton from "../elements/BaseButton.vue";
import BaseList from "../elements/BaseList.vue";
import BaseListItem from "../elements/BaseListItem.vue";
import BaseTitle from "../elements/BaseTitle.vue";
import axios from "axios";

export default {
    name: "PaymentMethodCard",
    components: {
        BaseTitle,
        BaseButton,
        BaseList,
        BaseListItem
    },
    props: {
        card: {
            type: Object,
        }
    },
    methods: {
        toggleDeleteModal(showModal) {
            if (showModal !== undefined) {
                this.showDeleteModal = showModal;
            } else {
                this.showDeleteModal = !this.showDeleteModal;
            }
        },
        deleteCard() {
            this.loading = true;

            let formData = new FormData();
            formData.append('card_id', this.card.id);
            axios.post('/ajax/del_cb.js', formData).then(response => {
                location.reload();
            });
        }
    },
    data: function () {
        return {
            showDeleteModal: false,
            loading: false,
        }
    },
}
</script>
