<template>
  <base-modal small>
    <modal-heading>{{ trans.get('payment.something_went_wrong_with_your_payment') }}</modal-heading>

    <modal-paragraph>
      <p class="font-smaller">{{ trans.get('payment.not_a_big_deal') }}</p>
      <p class="font-smaller">{{ trans.get('payment.contact_us') }}</p>
    </modal-paragraph>

    <div class="grid grid-cols-2 gap-4 mt-5">
      <base-button @click="closeModal">
        {{ trans.get('generic.ok') }}
      </base-button>
    </div>
  </base-modal>
</template>

<script>
import BaseButton from "../elements/BaseButton";
import ModalHeading from "./elements/ModalHeading";
import ModalParagraph from "./elements/ModalParagraph.vue";
import {mapActions} from "vuex";

export default {
  name: "ModalFailedPayPerUsePayment",
  components: {ModalParagraph, BaseButton, ModalHeading},
  methods: {
    ...mapActions('modals', ['closeModal']),
  },
}
</script>
