<template>
    <div class="payment-methods">
        <div>
            <base-title>{{ trans.get('payment.payment_methods') }}</base-title>
            <base-paragraph>{{ trans.get('payment.current_payment_methods') }}</base-paragraph>
        </div>

        <template v-if="!hasCard && !hasPaypal && !isDiagonalEnabled">
            <base-paragraph>{{ trans.get('payment.no_payment_method') }}</base-paragraph>
        </template>

        <template v-else>
            <div v-if="hasCard">
                <div class="flex">
                    <base-title class="flex-grow">{{ trans.get('payment.credit_card') }}</base-title>
                    <div>
                        <img :src="assetManager.getImageAsset('payment/brands/mastercard.png')"
                             class="h-6 inline-block" alt="Mastercard"/>
                        <img :src="assetManager.getImageAsset('payment/brands/visa.png')"
                             class="h-6 inline-block" alt="Visa"/>
                        <img :src="assetManager.getImageAsset('payment/brands/americanexpress.png')"
                             class="h-6 inline-block" alt="Americanexpress"/>
                    </div>
                </div>

                <div class="space-y-2">
                    <payment-method-card v-for="card in paymentMethods.cards"
                                         :card="card"
                                         :key="card.id"/>
                </div>
            </div>

            <div v-if="hasPaypal">
                <div class="flex">
                    <base-title class="flex-grow">{{ trans.get('payment.paypal') }}</base-title>
                    <div>
                        <img :src="assetManager.getImageAsset('payment/brands/paypal_large.png')"
                             class="h-6 inline-block" alt="Paypal"/>
                    </div>
                </div>

                <div class="space-y-2">
                    <payment-method-paypal v-for="paypal in paymentMethods.paypals"
                                           :paypal="paypal"
                                           :key="paypal.id"/>
                </div>
            </div>

            <template v-if="isDiagonalEnabled">
                <div v-if="hasDiagonal">
                    <div class="flex">
                        <base-title class="flex-grow">{{ trans.get('payment.bank_transfer') }}</base-title>
                        <div>
                            <img :src="assetManager.getImageAsset('payment/brands/sepa.png')"
                                 class="h-6 inline-block" alt="Sepa"/>
                        </div>
                    </div>

                    <div class="space-y-2">
                        <payment-method-diagonal v-for="diagonal in paymentMethods.diagonals"
                                                 :diagonal="diagonal"
                                                 :key="diagonal.id"/>
                    </div>
                </div>
                <div v-else>
                    <div class="flex">
                        <base-title class="flex-grow">{{ trans.get('payment.bank_transfer') }}</base-title>
                        <div>
                            <img :src="assetManager.getImageAsset('payment/brands/sepa.png')"
                                 class="h-6 inline-block" alt="Sepa"/>
                        </div>
                    </div>

                    <base-link @click="togglePaymentMethodDiagonalForm(true)"
                               v-if="!showPaymentMethodDiagonalForm" color="dark">
                        {{ trans.get('payment.diagonal.add_iban') }}
                    </base-link>

                    <template v-if="showPaymentMethodDiagonalForm">
                        <base-paragraph>{{ trans.get('payment.enter_bank_info') }}</base-paragraph>
                        <payment-method-diagonal-form @success="paymentMethodDiagonalCreated">
                            <template v-slot:actions>
                                <div class="grid grid-cols-2 gap-4 mt-5">
                                    <base-button @click="togglePaymentMethodDiagonalForm(false)" outlined>
                                        {{ trans.get('generic.cancel') }}
                                    </base-button>
                                    <base-button type="submit">
                                        {{ trans.get('payment.diagonal.validate_iban') }}
                                    </base-button>
                                </div>
                            </template>
                        </payment-method-diagonal-form>
                    </template>
                </div>
            </template>
        </template>
    </div>
</template>

<style lang="scss">
.payment-methods {
    @apply space-y-8;
}
</style>

<script>
import BaseButton from "../elements/BaseButton.vue";
import BaseParagraph from "../elements/BaseParagraph.vue";
import BaseTextInput from "../elements/BaseTextInput.vue";
import BaseTitle from "../elements/BaseTitle.vue";
import PaymentMethodCard from "./PaymentMethodCard.vue";
import PaymentMethodPaypal from "./PaymentMethodPaypal.vue";
import PaymentMethodDiagonal from "./PaymentMethodDiagonal.vue";
import BaseLink from "../elements/BaseLink.vue";
import PaymentMethodDiagonalForm from "./PaymentMethodDiagonalForm.vue";

export default {
    name: "PaymentMethods",
    components: {
        PaymentMethodDiagonalForm,
        BaseLink,
        PaymentMethodDiagonal,
        BaseButton,
        BaseParagraph,
        BaseTextInput,
        BaseTitle,
        PaymentMethodCard,
        PaymentMethodPaypal,
    },
    props: {
        paymentMethods: {
            type: Object | Array,
        },
    },
    computed: {
        hasCard() {
            return this.paymentMethods.hasOwnProperty('cards') && this.paymentMethods.cards.length > 0;
        },
        hasPaypal() {
            return this.paymentMethods.hasOwnProperty('paypals') && this.paymentMethods.paypals.length > 0;
        },
        hasDiagonal() {
            return this.paymentMethods.hasOwnProperty('diagonals') && this.paymentMethods.diagonals.length > 0;
        },
        isDiagonalEnabled() {
            return this.paymentMethods.hasOwnProperty('can_diagonal') && this.paymentMethods.can_diagonal;
        }
    },
    methods: {
        togglePaymentMethodDiagonalForm(showForm) {
            if (showForm !== undefined) {
                this.showPaymentMethodDiagonalForm = showForm;
            } else {
                this.showPaymentMethodDiagonalForm = !this.showPaymentMethodDiagonalForm;
            }
        },
        paymentMethodDiagonalCreated() {
            window.location.reload();
        },
    },
    data: function () {
        return {
            showPaymentMethodDiagonalForm: false,
        }
    },
}
</script>
