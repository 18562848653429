<template>
    <development>
        <div>
            <development-heading>Badge</development-heading>

            <div class="flex gap-8 mt-4">
                <base-badge>
                    <base-avatar large photo="https://via.placeholder.com/150"/>
                </base-badge>
                <base-badge>
                    <base-button>Button</base-button>
                </base-badge>
                <base-badge>
                    <font-awesome-icon :icon="['fal', 'browser']" size="3x"/>
                </base-badge>
            </div>

            <development-heading :level="2">Props :</development-heading>
            <ul>
                <li><strong>color</strong>: String - <em>'primary', 'secondary', 'available', 'busy', 'offline',
                    'neutral', 'light', 'white', 'danger', 'prime', 'facebook', 'whatsapp', 'horoscope'</em></li>
                <li><strong>distance</strong>: String | Number</li>
                <li><strong>bottom</strong>: Boolean,</li>
                <li><strong>center</strong>: Boolean,</li>
                <li><strong>content</strong>: String | Number,</li>
                <li><strong>dot</strong>: Boolean,</li>
                <li><strong>floating</strong>: Boolean,</li>
                <li><strong>inline</strong>: Boolean,</li>
                <li><strong>left</strong>: Boolean,</li>
                <li><strong>outlined</strong>: Boolean,</li>
                <li><strong>square</strong>: Boolean,</li>
            </ul>


            <development-heading>Color</development-heading>
            <div class="space-y-4 mt-4">
                <base-badge outlined color="primary" content="1" class="mr-3">
                    <base-button>primary</base-button>
                </base-badge>
                <base-badge outlined color="secondary" content="2" class="mr-3">
                    <base-button>secondary</base-button>
                </base-badge>
                <base-badge outlined color="available" content="3" class="mr-3">
                    <base-button>available</base-button>
                </base-badge>
                <base-badge outlined color="busy" content="4" class="mr-3">
                    <base-button>busy</base-button>
                </base-badge>
                <base-badge outlined color="offline" content="5" class="mr-3">
                    <base-button>offline</base-button>
                </base-badge>
                <base-badge outlined color="neutral" content="6" class="mr-3">
                    <base-button>neutral</base-button>
                </base-badge>
                <base-badge outlined color="light" content="7" class="mr-3">
                    <base-button>light</base-button>
                </base-badge>
                <base-badge outlined color="white" content="8" class="mr-3">
                    <base-button>white</base-button>
                </base-badge>
                <base-badge outlined color="danger" content="9" class="mr-3">
                    <base-button>danger</base-button>
                </base-badge>
                <base-badge outlined color="prime" content="10" class="mr-3">
                    <base-button>prime</base-button>
                </base-badge>
                <base-badge outlined color="facebook" content="11" class="mr-3">
                    <base-button>facebook</base-button>
                </base-badge>
                <base-badge outlined color="whatsapp" content="12" class="mr-3">
                    <base-button>whatsapp</base-button>
                </base-badge>
                <base-badge outlined color="horoscope" content="13" class="mr-3">
                    <base-button>horoscope</base-button>
                </base-badge>
            </div>

            <development-heading>Dot</development-heading>
            <div class="flex gap-8 mt-4">
                <base-badge dot>
                    <base-avatar large photo="https://via.placeholder.com/150"/>
                </base-badge>
                <base-badge dot>
                    <base-button>Button</base-button>
                </base-badge>
                <base-badge dot>
                    <font-awesome-icon :icon="['fal', 'browser']" size="3x"/>
                </base-badge>
            </div>

            <development-heading>Outlined</development-heading>
            <div class="flex gap-8 mt-4">
                <base-badge outlined>
                    <base-avatar large photo="https://via.placeholder.com/150"/>
                </base-badge>
                <base-badge outlined dot>
                    <base-button>Button</base-button>
                </base-badge>
                <base-badge outlined content="123">
                    <font-awesome-icon :icon="['fal', 'browser']" size="3x"/>
                </base-badge>
            </div>

            <development-heading>Square</development-heading>
            <div class="flex gap-8 mt-4">
                <base-badge square>
                    <base-avatar large photo="https://via.placeholder.com/150"/>
                </base-badge>
                <base-badge square dot>
                    <base-button>Button</base-button>
                </base-badge>
                <base-badge square outlined dot>
                    <base-button>Button</base-button>
                </base-badge>
                <base-badge square outlined content="123">
                    <font-awesome-icon :icon="['fal', 'browser']" size="3x"/>
                </base-badge>
            </div>

            <development-heading>Left</development-heading>
            <div class="flex gap-8 mt-4">
                <base-badge left color="secondary">
                    <base-avatar large photo="https://via.placeholder.com/150"/>
                </base-badge>
                <base-badge left dot color="secondary">
                    <base-button>Button</base-button>
                </base-badge>
                <base-badge left content="12" color="secondary">
                    <font-awesome-icon :icon="['fal', 'browser']" size="3x"/>
                </base-badge>
            </div>

            <development-heading>Center</development-heading>
            <div class="flex gap-8 mt-4">
                <base-badge center color="secondary">
                    <base-avatar large photo="https://via.placeholder.com/150"/>
                </base-badge>
                <base-badge center color="secondary" dot>
                    <base-button>Button</base-button>
                </base-badge>
                <base-badge center color="secondary" content="10000">
                    <font-awesome-icon :icon="['fal', 'browser']" size="3x"/>
                </base-badge>
            </div>

            <development-heading>Bottom</development-heading>
            <div class="flex gap-8 mt-4">
                <base-badge bottom color="secondary">
                    <base-avatar large photo="https://via.placeholder.com/150"/>
                </base-badge>
                <base-badge bottom color="secondary" dot>
                    <base-button>Button</base-button>
                </base-badge>
                <base-badge bottom color="secondary" content="10000">
                    <font-awesome-icon :icon="['fal', 'browser']" size="3x"/>
                </base-badge>
            </div>

            <development-heading>Inline</development-heading>
            <div class="flex gap-8 mt-4">
                <base-badge inline outlined>
                    <base-avatar large photo="https://via.placeholder.com/150"/>
                </base-badge>
                <base-badge inline outlined dot>
                    <base-button>Button</base-button>
                </base-badge>
                <base-badge inline outlined content="123" color="danger">
                    <font-awesome-icon :icon="['fal', 'trash-can']" size="3x"/>
                </base-badge>
                <p> Inbox
                    <base-badge inline content="123"/>
                </p>
            </div>

            <development-heading>Distance</development-heading>
            <div>
                <input type="range" min="-4" max="4" value="0" step="0.05" v-model="distance"/> {{ distance }}rem
            </div>
            <div class="flex gap-8 mt-4">
                <base-badge left :distance="distance">
                    <base-avatar large photo="https://via.placeholder.com/150"/>
                </base-badge>
                <base-badge center :distance="distance">
                    <base-avatar large photo="https://via.placeholder.com/150"/>
                </base-badge>
                <base-badge :distance="distance">
                    <base-avatar large photo="https://via.placeholder.com/150"/>
                </base-badge>
            </div>
            <div class="flex gap-8 mt-4">
                <base-badge bottom left :distance="distance">
                    <base-avatar large photo="https://via.placeholder.com/150"/>
                </base-badge>
                <base-badge bottom center :distance="distance">
                    <base-avatar large photo="https://via.placeholder.com/150"/>
                </base-badge>
                <base-badge bottom :distance="distance">
                    <base-avatar large photo="https://via.placeholder.com/150"/>
                </base-badge>
            </div>
            <div class="flex gap-16 mt-4">
                <base-badge inline :distance="distance">
                    <base-avatar large photo="https://via.placeholder.com/150"/>
                </base-badge>
            </div>
        </div>
    </development>
</template>

<script>
import BaseBadge from "../elements/BaseBadge";
import BaseButton from "../elements/BaseButton";
import Development from "./Development";
import DevelopmentHeading from "./elements/DevelopmentHeading";
import DevelopmentParagraph from "./elements/DevelopmentParagraph";
import BaseAvatar from "../elements/BaseAvatar";

export default {
    components: {BaseAvatar, BaseBadge, BaseButton, DevelopmentParagraph, DevelopmentHeading, Development},
    data() {
        return {
            distance: 0
        };
    },
}
</script>
