<template>
    <base-modal :error="loadingError">
        <modal-heading>{{ trans.get('generic.password_reset_title') }}</modal-heading>

        <div v-if="postFormSucceed">
            <modal-paragraph>{{ trans.get('generic.password_reseted') }}</modal-paragraph>

            <base-button block @click="cancel">OK</base-button>
        </div>

        <div v-else>
            <form @submit="postForm">
                <modal-paragraph>{{ trans.get('generic.enter_new_password') }}</modal-paragraph>

                <div class="mb-4">
                    <base-text-input type="password"
                                     autofocus
                                     outlined
                                     v-model="password"
                                     :label="trans.get('generic.password')"
                                     :placeholder="trans.get('generic.password')"
                                     :error="errorPassword"
                                     @keydown="errorPassword = ''">
                        <template #icon><font-awesome-icon :icon="['fal', 'envelope']"/></template>
                    </base-text-input>
                </div>
                <div class="mb-4">
                    <base-text-input type="password"
                                     outlined
                                     v-model="password_confirmation"
                                     :label="trans.get('generic.password_confirmation')"
                                     :placeholder="trans.get('generic.password_confirmation')"
                                     :error="errorPasswordConfirmation"
                                     @keydown="errorPasswordConfirmation = ''">
                        <template #icon><font-awesome-icon :icon="['fal', 'envelope']"/></template>
                    </base-text-input>
                </div>

                <base-button type="submit" block :loading="formLoading">
                    {{ trans.get('generic.validate') }}
                </base-button>
            </form>
        </div>
    </base-modal>
</template>

<script>
import axios from 'axios';


import {mapState} from 'vuex';
import {mapMutations} from 'vuex';
import BaseButton from "../elements/BaseButton";
import BaseTextInput from "../elements/BaseTextInput";
import ValidationLang from "../ValidationLang.js";
import ModalHeading from "./elements/ModalHeading";
import ModalParagraph from "./elements/ModalParagraph";

export default {
    components: {ModalParagraph, ModalHeading, BaseTextInput, BaseButton},
    computed: {
        ...mapState('modals', ['loading']),
    },
    methods: {
        ...mapMutations('modals', [
            'changeLoadingState',
        ]),
        cancel() {
            this.$emit('cancel');
        },
        postForm(e) {
            e.preventDefault();

            this.formLoading = true;
            axios.post('/password/reset', {
                password: this.password,
                password_confirmation: this.password_confirmation
            }).then((res) => {
                this.postFormSucceed = true;
            }).catch((errorResponse) => {
                let errorsInResponse = errorResponse.response.data.errors;
                let validationLang = new ValidationLang();
                let validationErrorsMap = validationLang.getMessageTranslated(errorsInResponse)
                this.errorPassword = validationErrorsMap.get('password').join(' ');
                this.errorPasswordConfirmation = validationErrorsMap.get('password_confirmation').join(' ');
            }).finally(() => {
                this.formLoading = false;
            });
        },
    },

    async created() {
        this.changeLoadingState(true);
        await axios
            .get(this.url)
            .then(response => {
                if (response.data) {
                    this.changeLoadingState(false);
                }
                this.response = response.data;

            }).catch(error => {
                this.changeLoadingState(false);
                this.loadingError = error
            });
    },

    data() {
        return {
            url: '/ajax/get-online-offers-call-button-text.js',
            response: null,
            loadingError: '',
            password: '',
            password_confirmation: '',
            csrf: '',
            postFormSucceed: false,
            errorPassword: '',
            errorPasswordConfirmation: '',
            formLoading: false
        }
    },
    mounted() {
        this.csrf = window.csrf_token;
    }
}

</script>
