<template>
    <base-radio-input
        v-model="billingType"
        :name="name"
        :value="value"
        :disabled="disabled"
        input-hidden
    >
        <template>
            <div class="base-radio-input">
                <div class="grid grid-cols-2 w-full items-center min-h-10">
                    <span class="base-radio-input-title">{{ title }}</span>
                    <span class="text-right font-bold text-2xl">{{ description }}</span>
                </div>
                <div class="base-radio-input-icon text-neutral-400">
                    <font-awesome-icon :icon="['far', 'circle']" size="lg"/>
                </div>
            </div>
        </template>
        <template #checked>
            <div class="base-radio-input base-radio-input--checked">
                <div class="grid grid-cols-2 w-full items-center min-h-10">
                    <span class="base-radio-input-title">{{ title }}</span>
                    <span class="text-right font-bold text-2xl">{{ description }}</span>
                </div>
                <div class="base-radio-input-icon">
                    <font-awesome-icon :icon="['far', 'circle-check']" size="lg"/>
                </div>
            </div>
        </template>
        <template #disabled>
            <div class="base-radio-input base-radio-input--disabled">
                <div class="grid grid-cols-2 w-full items-center min-h-10">
                    <span class="base-radio-input-title">{{ title }}</span>
                    <span class="text-right font-bold text-2xl">{{ description }}</span>
                </div>
                <div class="base-radio-input-icon base-radio-input-icon--disabled">
                    <font-awesome-icon :icon="['far', 'circle']" size="lg"/>
                </div>
            </div>
        </template>
    </base-radio-input>
</template>

<style lang="scss">

.base-radio-input {
    @apply flex relative w-full p-4 mb-4 border border-neutral-400 text-neutral-500 rounded-lg cursor-pointer;

    &--checked {
        @apply border-primary text-primary;
    }

    &--disabled {
        @apply bg-disabled text-neutral-400 pointer-events-none;
    }
}

.base-radio-input-icon {
    @apply absolute top-full left-2/4 bg-white rounded-full transform -translate-y-1/2 -translate-x-1/2;

    &--disabled {
        @apply bg-disabled;
    }
}

.base-radio-input-title {
    @apply text-base leading-5;
}

</style>

<script>
import BaseRadioInput from "../BaseRadioInput";

export default {
    name: "BaseRadioInputBillingType",
    components: {BaseRadioInput},
    model: {
        prop: 'selectedValue',
        event: 'change'
    },
    props: {
        description: String,
        disabled: Boolean,
        name: String,
        selectedValue: String | Number,
        title: String,
        value: String | Number
    },
    computed: {
        billingType: {
            get() {
                return this.selectedValue;
            },

            set(value) {
                this.$emit('change', value);
            }
        },
    }
}
</script>

<style scoped>

</style>
