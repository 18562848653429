<template>
    <div class="offer-card-actions">
        <template v-if="isOffline">
            <offer-card-action :label="trans.get('generic.button.alert_me_to_his_return')"
                               :icon="['fas', 'bell']"
                               :small="small"
                               color="offline"
                               outlined
                               @click="notifyWhenMasterIsOnline"/>
        </template>

        <template v-else-if="isBusy">
            <offer-card-action v-if="fastPassActivated"
                               :label="trans.get('generic.button.fast_pass_active')"
                               :icon="['fas', 'phone']"
                               :small="small"
                               color="prime"
                               outlined/>
            <offer-card-action v-else-if="waitingQueueFull"
                               :label="trans.get('generic.button.already_reserved_prime')"
                               :icon="['fas', 'stars']"
                               :small="small"
                               color="busy"
                               outlined/>
            <offer-card-action v-else-if="fastPassUnavailable"
                               :label="trans.get('generic.button.fast_pass_unavailable')"
                               :icon="['fas', 'phone-slash']"
                               :small="small"
                               color="prime"
                               outlined/>
            <offer-card-action v-else-if="canPurchaseFastPass"
                               :label="trans.get('generic.button.purchase_fast_pass')"
                               :icon="['fas', 'phone']"
                               :small="small"
                               color="prime"
                               @click="purchaseFastPass"/>
            <offer-card-action v-else-if="canCallWithFastPass"
                               :label="trans.get('generic.button.use_fast_pass')"
                               :icon="['fas', 'phone']"
                               :small="small"
                               color="prime"
                               @click="startCallWithFastPass"/>
            <offer-card-action v-else-if="canShowFastPassPresentation"
                               :label="trans.get('generic.button.use_fast_pass')"
                               :icon="['fas', 'phone']"
                               :small="small"
                               color="prime"
                               @click="showFastPassPresentation"/>
            <offer-card-action v-else-if="callInProgress"
                               :label="trans.get('generic.button.in_consultation')"
                               :icon="['fas', 'phone-slash']"
                               :small="small"
                               color="busy"
                               outlined/>
            <offer-card-action v-else-if="chatInProgress"
                               :label="trans.get('generic.button.in_consultation')"
                               :icon="['fas', 'message-slash']"
                               :small="small"
                               color="busy"
                               outlined/>
            <offer-card-action v-else color="busy"
                               :label="trans.get('generic.button.busy')"
                               :icon="['fas', 'user-clock']"
                               :small="small"
                               outlined/>
        </template>

        <template v-else-if="!canChat && !canCall">
            <offer-card-action :label="trans.get('generic.button.busy')"
                               :icon="['fas', 'user-clock']"
                               :small="small"
                               color="busy"
                               outlined/>
        </template>

        <template v-else>
            <template v-if="canChat">
                <offer-card-action v-if="canChatFreePrime"
                                   :label="trans.get('generic.button.free_chat')"
                                   :icon="['fas', 'message-lines']"
                                   :small="small"
                                   color="available"
                                   @click="startChatFreePrime"/>
                <offer-card-action v-else-if="canChatFree"
                                   :label="trans.get('generic.button.free_chat')"
                                   :icon="['fas', 'message-lines']"
                                   :small="small"
                                   color="available"
                                   @click="startChatFree"/>
                <offer-card-action v-else-if="canChatWithVoucher"
                                   :label="trans.get('generic.button.minutes_gift', {'minutes': 10})"
                                   :icon="['fas', 'message-lines']"
                                   :small="small"
                                   color="available"
                                   @click="startChatWithVoucher"/>
                <offer-card-action v-else
                                   :label="trans.get('generic.button.chat')"
                                   :icon="['fas', 'message-lines']"
                                   :small="small"
                                   color="available"
                                   @click="startChat"/>
            </template>

            <template v-if="canCall">
                <offer-card-action v-if="canCallFreePrime"
                                   :label="trans.get('generic.button.free_call')"
                                   :icon="['fas', 'phone']"
                                   :small="small"
                                   @click="startCallFreePrime"/>
                <offer-card-action v-else-if="canCallFreePrimeWithMastersNeverConsulted"
                                   :label="trans.get('generic.button.free_call')"
                                   :icon="['fas', 'phone']"
                                   :small="small"
                                   @click="startCallFreePrimeWithMastersNeverConsulted"/>
                <offer-card-action v-else-if="canCallFree"
                                   :label="trans.get('generic.button.free_call')"
                                   :icon="['fas', 'phone']"
                                   :small="small"
                                   @click="startCallFree"/>
                <offer-card-action v-else-if="canCallWithVoucher"
                                   :label="trans.get('generic.button.minutes_gift', {'minutes': 10})"
                                   :icon="['fas', 'phone']"
                                   :small="small"
                                   @click="startCallWithVoucher"/>
                <offer-card-action v-else
                                   :label="trans.get('generic.button.call')"
                                   :icon="['fas', 'phone']"
                                   :small="small"
                                   @click="startCall"/>
            </template>
        </template>
    </div>
</template>

<script>
import OfferMixin from "./OfferMixin";
import OfferCardAction from "./OfferCardAction.vue";

export default {
    name: 'OfferCardActions',
    components: {OfferCardAction},
    mixins: [OfferMixin],
    props: {
        offer: {
            type: Object,
            default() {
                return {}
            }
        },
        user: Object | Array,
        offer_list_type: String,
        is_prime_enabled: Boolean,
        is_free_chat_enabled_for_prospect: Boolean,
        user_credits: Number,
        small: Boolean,
    },
}
</script>
