<template>
    <form id="search_form" action="ajax/search.js" method="POST" target="ajax_upload">
        <input type="hidden" name="cat" :value="this.categoryId"/>

        <base-text-input v-model="searchValue"
                         ref="searchInput"
                         type="text"
                         name="keyword"
                         id="textfieldsearch"
                         autocomplete="off"
                         :outlined="config.get('components.header.search.outlined')"
                         :placeholder="trans.get('header.search')"
                         x-small>
            <template #append>
                <base-button :class="searchButtonClass" type="submit" icon small>
                    <font-awesome-icon :icon="['fal', 'magnifying-glass']" class="text-neutral-500"/>
                </base-button>
                <base-button :class="clearButtonClass" icon small @click="clearSearch">
                    <font-awesome-icon :icon="['fas', 'circle-xmark']" class="text-neutral-400"/>
                </base-button>
            </template>
        </base-text-input>
    </form>
</template>

<script>
import BaseTextInput from "../elements/BaseTextInput";

export default {
    components: {BaseTextInput},
    name: 'Search',
    props: {
        categoryId: {
            type: Number,
            default: 0,
        },
    },
    methods: {
        focusSearch() {
            this.$refs.searchInput.focusInput();
        },
        clearSearch() {
            this.searchValue = '';
            this.focusSearch();
        },
    },
    computed: {
        hasSearchValue() {
            return this.searchValue.trim().length > 0;
        },
        clearButtonClass() {
            return {
                'invisible': !this.hasSearchValue,
                'float-right': true,
            };
        },
        searchButtonClass() {
            return {
                'float-right': true,
            };
        },
    },
    data() {
        return {
            searchValue: '',
        }
    },
}
</script>
