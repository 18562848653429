<template>
    <div>
        <conversation-message v-if="isChatBotShown(getSelectedParticipants)" :message="chatBotMessage"/>
        <div v-else-if="chatBot.reason === 'asked_too_many_masters_recently'" class="chat-bot-alert">
            <base-alert elevated text-center>
                <font-awesome-icon :icon="['fas', 'circle-exclamation']" class="text-primary"/>
                {{
                    trans.get('chat.chat_bot.asked_too_many_masters_recently_alert',
                        {hours: this.getHoursBeforeAskingEnabledAgain(this.getSelectedParticipants)})
                }}
            </base-alert>
        </div>
        <div v-else-if="chatBot.reason === 'asked_master_too_recently' || chatBot.reason === 'asked_master_too_recently_with_answer'" class="chat-bot-alert">
            <base-alert elevated text-center>
                <span class="font-bold">
                    <font-awesome-icon :icon="['fas', 'circle-exclamation']" class="text-primary"/>
                    {{ trans.get('chat.chat_bot.asked_master_too_recently_alert_title') }}
                </span>
                <div>{{
                        this.trans.get('chat.chat_bot.asked_master_too_recently_alert_text') + ' ' +
                        this.trans.get('chat.chat_bot.asked_master_too_recently_alert_or_write_again_later_text', {
                            hours: this.getHoursBeforeAskingEnabledAgain(this.getSelectedParticipants),
                        })
                    }}
                </div>
            </base-alert>
        </div>
        <div v-else-if="chatBot.reason === 'asked_same_master_without_answer_too_many_times'" class="chat-bot-alert">
            <base-alert elevated text-center>
                <span class="font-bold">
                    <font-awesome-icon :icon="['fas', 'circle-exclamation']" class="text-primary"/>
                    {{ trans.get('chat.chat_bot.asked_master_too_recently_alert_title') }}
                </span>
                <div>{{ this.trans.get('chat.chat_bot.asked_master_too_recently_alert_text') }}
                </div>
            </base-alert>
        </div>
    </div>
</template>

<style lang="scss">
.chat-bot-alert {
    @apply mt-5 flex items-center justify-center m-3;
}
</style>

<script>
import ConversationMessage from './ConversationMessage';
import {mapActions, mapGetters} from 'vuex';
import ChatMixin from './ChatMixin';
import BaseAlert from '../elements/BaseAlert';

export default {
    name: 'ChatBot',
    components: {BaseAlert, ConversationMessage},
    mixins: [ChatMixin],
    computed: {
        ...mapGetters('chat', [
            'getSelectedParticipants',
            'isChatBotShown',
            'hasChatBotReasonToShow',
            'getChatBot',
            'getChatBotMastersAsked',
            'getHoursBeforeAskingEnabledAgain',
        ]),
        chatBot: function () {
            return this.getChatBot(this.getSelectedParticipants);
        },
        chatBotMessage: function () {
            return {
                content: {},
                type: 'chat_bot_ask_question',
                error: false,
                participant: this.interlocutor(),
            };
        },
    },
    methods: {
        ...mapActions('chat', ['askChatBotQuestion']),
    },
};
</script>
