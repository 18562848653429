<template>
    <div class="conversation-list-header">
        <div class="conversation-list-header-searchbar-container">
            <base-button v-if="canSendFollowUp" link font-normal normal-case class="absolute ml-3" @click="openModalFollowUpChoice">
                <font-awesome-icon :icon="['fal', 'pen-to-square']" size="lg" class="mr-2"/>{{ trans.get('chat.follow_up_button_title') }}
            </base-button>
            <div v-if="!this.meFromUserStore().is_master" class="conversation-list-header-title">
                {{ trans.get('generic.my_messages') }}
            </div>
            <base-button icon @click="openSearch" class="absolute right-0">
                <font-awesome-icon :icon="['fal', 'magnifying-glass']"/>
            </base-button>
        </div>
    </div>
</template>

<style lang="scss">
.conversation-list-header {
    @apply flex border-b border-neutral-300 h-16;
}

.conversation-list-header-searchbar-container {
    @apply flex w-full items-center;
}

.conversation-list-header-title {
    @apply flex-grow text-center font-bold text-primary;
}
</style>

<script>
import BaseButton from "../elements/BaseButton";
import ConversationListSearchbar from "./ConversationListSearchbar";
import {mapActions, mapGetters} from "vuex";
import ChatMixin from "./ChatMixin";

export default {
    name: "ConversationListHeader",
    components: {BaseButton, ConversationListSearchbar},
    mixins: [ChatMixin],
    methods: {
        ...mapActions('chat', ['toggleConversationSearch']),
        ...mapActions('modals', ['openModal']),
        openModalFollowUpChoice() {
            this.openModal({
                name: globals.FOLLOW_UP_TYPE_CHOICE
            });
        },
        openSearch() {
            if (this.meFromUserStore().is_master) {
                this.openModal({
                    name: globals.FOLLOW_UP_TO_ONE
                });
            } else {
                this.toggleConversationSearch(true);
            }
        }
    },
    computed: {
        ...mapGetters('chat', ['iAmMaster']),
        canSendFollowUp() {
            return this.meFromUserStore().is_master;
        },
        placeholderSearchBar() {
            return (this.meFromUserStore().is_master) ? this.trans.get('generic.search_for_a_client') : this.trans.get('generic.search_for_a_master');
        }
    },
    data() {
        return {
            displaySearchBar: false
        }
    }

}
</script>
