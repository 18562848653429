<template>
    <development>
        <development-heading>Slider</development-heading>
<!--        <development-paragraph>-->
<!--            Use SwiperJs lib : <base-link href="https://swiperjs.com/demos#freemode">SwiperJs</base-link>-->
<!--        </development-paragraph>-->

<!--        <div ref="swiper" class="swiper">-->
<!--            <div class="swiper-wrapper">-->
<!--                <div v-for="n in 10" class="swiper-slide">-->
<!--                    Slide {{ n }}-->
<!--                </div>-->
<!--            </div>-->

<!--            <div class="swiper-button-prev"></div>-->
<!--            <div class="swiper-button-next"></div>-->

<!--            <div class="swiper-pagination"></div>-->
<!--        </div>-->
    </development>
</template>

<!--<style scoped>-->
<!--.swiper {-->
<!--    max-width: 600px;-->
<!--    height: 300px;-->
<!--}-->

<!--.swiper-slide {-->
<!--    display: flex;-->
<!--    justify-content: center;-->
<!--    align-items: center;-->
<!--}-->
<!--</style>-->

<script>
// import Swiper, {Navigation, Pagination, Autoplay} from 'swiper'
// import 'swiper/swiper-bundle.min.css'

import Development from "./Development";
import DevelopmentHeading from "./elements/DevelopmentHeading";
import DevelopmentParagraph from "./elements/DevelopmentParagraph";
import BaseLink from "../elements/BaseLink";

export default {
    components: {BaseLink, DevelopmentParagraph, Development, DevelopmentHeading},

    data() {
        return {
            activeIndex: 0,
        }
    },
    mounted() {
        // new Swiper(this.$refs.swiper, {
        //     modules: [Navigation, Pagination, Autoplay],
        //     loop: true,
        //     speed: 100,
        //     pagination: {
        //         el: '.swiper-pagination',
        //         clickable: true,
        //     },
        //     navigation: {
        //         nextEl: '.swiper-button-next',
        //         prevEl: '.swiper-button-prev',
        //     },
        //     on: {
        //         slideChange: (swiper) => {
        //             this.activeIndex = swiper.realIndex
        //         },
        //     },
        // })
    },
}
</script>
