export default {
    namespaced: true,

    state: {
        userMicrophonePermissionState: 'prompt',
        mediaRecorder: null,
        recordingChunks: [],
        recordFinalBlob: null,
        isRecordInProgress: false,
        recordStartDate: null,
        timerRecord: null,
        recordTime: null,
        isUserListeningToAudioDraft: false
    },
    mutations: {
        setUserMicrophonePermissionState(state, userMicrophonePermissionState) {
            state.userMicrophonePermissionState = userMicrophonePermissionState;
        },
        setMediaRecorder(state, mediaRecorder) {
            state.mediaRecorder = mediaRecorder;
        },
        resetMediaRecorder(state) {
            state.mediaRecorder = null;
        },
        setRecordInProgress(state, isRecordInProgress) {
            state.isRecordInProgress = isRecordInProgress;
        },
        addRecordingChunks(state, chunks) {
            state.recordingChunks.push(chunks);
        },
        resetRecordingChunks(state) {
            state.recordingChunks = [];
        },
        setIsUserListeningToAudioDraft(state, isUserListeningToAudioDraft) {
            state.isUserListeningToAudioDraft = isUserListeningToAudioDraft;
        },
        setRecordFinalBlob(state, blob) {
            state.recordFinalBlob = blob;
        },
        resetRecordFinalBlob(state) {
            state.recordFinalBlob = null;
        },
        launchRecordTime(state) {
            state.timerRecord = setInterval(() => {
                state.recordTime += 1
            }, 1000);
        },
        stopRecordTimer(state) {
            clearInterval(state.timerRecord);
        },
        resetRecordTime(state) {
            state.recordTime = 0;
        }
    },
    actions: {
        setUserMicrophonePermissionState({commit}, userMicrophonePermissionState) {
            commit('setUserMicrophonePermissionState', userMicrophonePermissionState);
        },
        startAudioRecord({commit, dispatch, getters}, stream) {
            let options = {mimeType:'audio/webm;codecs="opus"'};
            if (!MediaRecorder.isTypeSupported('audio/webm;codecs="opus"')) {
                options.mimeType = 'audio/mp4';
            }
            const mediaRecorder = new MediaRecorder(stream, options);
            mediaRecorder.ondataavailable = (event) => {
                commit('addRecordingChunks', event.data);
            }
            mediaRecorder.onstop = (e) => {
                commit('setRecordInProgress', false);
                commit('setRecordFinalBlob', new Blob(getters.getRecordingChunks, { type: mediaRecorder.mimeType}));
                dispatch('resetAudioRecord');
                commit('stopRecordTimer');
            }
            mediaRecorder.onerror = (event) => {
                dispatch('modals/openModal', {
                    name: globals.GENERIC_ERROR,
                }, {root: true});
            }
            mediaRecorder.start();
            commit('setMediaRecorder', mediaRecorder);
            commit('launchRecordTime');
            commit('setRecordInProgress', true);
        },
        async stopAudioRecord({getters}) {
            getters.getMediaRecorder.stop();
        },
        resetAudioRecord({commit}) {
            commit('resetMediaRecorder');
            commit('resetRecordingChunks');
        },
        deleteAudioRecord({commit}) {
            commit('resetRecordFinalBlob');
            commit('resetRecordTime');
        },
        setIsUserListeningToAudioDraft({commit}, isUserListeningToAudioDraft) {
            commit('setIsUserListeningToAudioDraft', isUserListeningToAudioDraft);
        },
        resetRecordTime({commit}) {
            commit('resetRecordTime');
        }
    },
    getters: {
        getUserMicrophonePermissionState: state => state.userMicrophonePermissionState,
        getMediaRecorder: state => state.mediaRecorder,
        isRecordInProgress: state => state.isRecordInProgress,
        getRecordingChunks: state => state.recordingChunks,
        isUserListeningToAudioDraft: state => state.isUserListeningToAudioDraft,
        getRecordFinalBlob: state => state.recordFinalBlob,
        getRecordTime: state => state.recordTime,
    },

}
