<template>
    <div v-if="this.getSelectedParticipants.length === 0" class="conversation-messages-suggestion-box">
        <div class="conversation-messages-suggestion-box-container">
            <img :src="assetManager.getImageAsset('chat/chat_en_ligne.gif')"
                 class="conversation-messages-suggestion-box-image"
                 alt="Online chat">
            <span class="conversation-messages-suggestion-box-message" v-if="!this.meFromUserStore().is_master">
                {{ this.trans.get('chat.suggestion_box.choose_a_master_to_start_chat') }}
            </span>
            <span class="conversation-messages-suggestion-box-message" v-else>
                {{ this.trans.get('chat.suggestion_box.relaunching_your_customers') }}
            </span>
        </div>
    </div>

    <div v-else-if="this.speakingWithMaster" class="conversation-messages-suggestion-box">
        <div v-if="this.isMasterOffline(this.interlocutor())" class="conversation-messages-suggestion-box-container">
            <img :src="assetManager.getImageAsset('chat/chat_hors_ligne.gif')"
                 class="conversation-messages-suggestion-box-image"
                 alt="Offline chat">
            <span
                class="conversation-messages-suggestion-box-message conversation-messages-suggestion-box-message--offline">
                {{
                    this.trans.get('chat.suggestion_box.choose_master_is_offline',
                        {master_name: this.interlocutor().name})
                }}
            </span>
            <base-button class="min-w-30" color="neutral" outlined small @click="displayModalNotifMasterOnline">
                <font-awesome-icon :icon="['far', 'bell']" size="1x"/>
                {{ trans.get('generic.alert_notif_master_online') }}
            </base-button>
        </div>

        <div v-else-if="this.isMasterBusy(this.interlocutor())" class="conversation-messages-suggestion-box-container">
            <img :src="assetManager.getImageAsset('chat/chat_occupe.gif')"
                 class="conversation-messages-suggestion-box-image"
                 alt="Busy chat">
            <span
                class="conversation-messages-suggestion-box-message conversation-messages-suggestion-box-message--busy">
                {{
                    this.trans.get('chat.suggestion_box.choose_master_is_busy', {
                        master_name: this.interlocutor().name,
                    })
                }}
            </span>
        </div>

        <div v-else-if="this.isMasterAvailableForChat(this.interlocutor())"
             class="conversation-messages-suggestion-box-container">
            <img :src="assetManager.getImageAsset('chat/chat_en_ligne.gif')"
                 class="conversation-messages-suggestion-box-image"
                 alt="Online chat">
            <span class="conversation-messages-suggestion-box-message">
                {{
                    this.trans.get('chat.suggestion_box.choose_start_a_conversation_with',
                        {master_name: this.interlocutor().name})
                }}
            </span>
            <div class="bg-neutral-50 flex my-5 p-5 sm:rounded-2xl sm:w-9/12 w-full">
                <img :src="this.interlocutor().photo" alt="Photo master" class="h-24 mr-5 rounded-full w-24">
                <div class="flex flex-col justify-between w-full">
                    <div>
                        <div class="flex items-center justify-between">
                            <span class="font-bold text-primary">{{ this.interlocutor().name }}</span>
                            <span class="font-bold">
                            {{ this.interlocutor().calls_count }}
                            <font-awesome-icon :icon="['fas', 'phone-square-alt']"/>
                        </span>
                        </div>
                        <span class="block mt-2">{{ this.interlocutor().short_description }}</span>
                    </div>
                    <div class="flex items-center justify-between mt-2">
                        <div class="font-bold text-star">
                            <span>{{ this.interlocutor().average_mark / 10 }}</span>
                            <font-awesome-icon :icon="['fas', 'star']"/>
                        </div>
                        <div>
                            <span
                                v-if="this.interlocutor().cost_by_minute_without_advantage !== this.interlocutor().cost_by_minute"
                                class="line-through">
                                {{ this.interlocutor().cost_by_minute_without_advantage / 100 | formatMoneyPerMinute }}
                            </span>
                            <span class="font-bold text-primary">{{
                                    this.interlocutor().cost_by_minute / 100 | formatMoneyPerMinute
                                }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <base-button class="min-w-30" outlined small @click="startLiveChat">
                <font-awesome-icon :icon="['far', 'message-lines']"/>
                {{ trans.get('generic.chat_live') }}
            </base-button>
        </div>

        <div v-else class="conversation-messages-suggestion-box-container">
            <img :src="assetManager.getImageAsset('chat/chat_desactive.gif')"
                 class="conversation-messages-suggestion-box-image"
                 alt="Busy chat">
            <span class="conversation-messages-suggestion-box-message">
                {{
                    this.trans.get('chat.suggestion_box.choose_master_is_not_available', {
                        master_name: this.interlocutor().name,
                    })
                }}
            </span>

            <base-link :href="this.interlocutor().url">
                <base-button class="min-w-30" color="primary" small>
                    <font-awesome-icon :icon="['fas', 'user']" size="1x"/>
                    {{ trans.get('generic.see_the_master_profile') }}
                </base-button>
            </base-link>
        </div>
    </div>
</template>

<style lang="scss">
.conversation-messages-suggestion-box {
    @apply absolute flex h-full items-center justify-center w-full z-1 pointer-events-none;
}

.conversation-messages-suggestion-box-container {
    @apply flex flex-col items-center justify-center pointer-events-auto;
}

.conversation-messages-suggestion-box-image {
    @apply w-20 lg:w-24;
}

.conversation-messages-suggestion-box-message {
    @apply font-bold text-primary text-center w-8/12 mb-2 sm:text-xl lg:text-2xl;

    &--busy {
        @apply text-status-busy;
    }

    &--offline {
        @apply text-status-offline;
    }
}
</style>

<script>
import BaseButton from '../elements/BaseButton';
import {mapActions, mapGetters} from 'vuex';
import ChatMixin from './ChatMixin';
import BaseLink from '../elements/BaseLink';

export default {
    name: 'ConversationMessagesSuggestionBox',
    components: {BaseLink, BaseButton},
    mixins: [ChatMixin],
    computed: {
        ...mapGetters('chat', ['getSelectedParticipants', 'getOfferIdFromParticipants', 'speakingWithMaster']),
    },
    methods: {
        ...mapActions('chat', ['startLiveChatProcess']),
        ...mapActions('modals', ['openModal']),
        startLiveChat: function (event) {
            this.startLiveChatProcess({offerId:this.getOfferIdFromParticipants(this.getSelectedParticipants)});
        },
        displayModalNotifMasterOnline() {
            this.openModal({
                name: globals.CHAT_CUSTOMER_NOTIF_MASTER_ONLINE,
                param: {
                    master: this.interlocutor(this.getSelectedParticipants),
                    userFullMobilePhoneNumber: this.meFromUserStore().mobile_phone,
                },
            });
        },
    },
};
</script>
