import axios from 'axios';
import {ziggy_route} from '../ZiggyMixin';

export default {
    namespaced: true,

    state: {
        game: {},
    },

    mutations: {
        setGameInfos: (state, gameInfos) => {
            state.game = gameInfos;
            state.game.isFirstDay = false;
            state.game.isLastDay = false;
            let dtEnd = new Date(gameInfos.ends_at);
            let dtStart = new Date(gameInfos.starts_at);
            let now = new Date();
            const datesAreOnSameDay = (first, second) =>
                first.getFullYear() === second.getFullYear() &&
                first.getMonth() === second.getMonth() &&
                first.getDate() === second.getDate();
            if (datesAreOnSameDay(now, dtEnd)) {
                state.game.isLastDay = true;
            }
            if (datesAreOnSameDay(now, dtStart)) {
                state.game.isFirstDay = true;
            }
        },
    },
    getters: {
        getActiveGameInfos: (state) => {
            return state.game;
        },
        getActiveGameSlug: (state) => {
            return state.game.slug;
        },
        getIsFirstDay: (state) => {
            return state.game.isFirstDay;
        },
        getIsLastDay: (state) => {
            return state.game.isLastDay;
        },
    },
    actions: {
        displayUnauthorizeModal({dispatch, getters, commit, state}, code) {
            switch (code) {
                case 'must_be_registered_before_date':
                    dispatch('modals/openModalImage', {
                        small: true,
                        title: '',
                        url: 'game/' + getters.getActiveGameSlug + '/website/popup/must_be_registered_before_game.jpg',
                        alt: window.globalVue.trans.get('game.must_be_registered_before_date'),
                        buttonText: window.globalVue.trans.get('generic.back_to_site')
                    }, {root: true});

                    break;
                case 'must_be_logged_in':
                    ajax_popup('register', {step: 2, cat: 0, is_app: 0});
                    break;
                case 'nb_participations_per_day':
                    if (getters.getIsLastDay) {
                        dispatch('modals/openModalImage', {
                            small: true,
                            title: '',
                            url: 'game/' + getters.getActiveGameSlug + '/website/popup/nb_participations_per_day_last.jpg',
                            alt: window.globalVue.trans.get('game.nb_participations_per_day'),
                            buttonText: window.globalVue.trans.get('generic.back_to_site')
                        }, {root: true});

                    } else {
                        dispatch('modals/openModalImage', {
                            small: true,
                            title: '',
                            url: 'game/' + getters.getActiveGameSlug + '/website/popup/nb_participations_per_day.jpg',
                            alt: window.globalVue.trans.get('game.nb_participations_per_day'),
                            buttonText: window.globalVue.trans.get('generic.back_to_site')
                        }, {root: true});
                    }
                    break;
                case 'must_not_have_user_auth':
                    dispatch('modals/openModalMessage', {
                        title: window.globalVue.trans.get('game.regulation_violation'),
                        description: window.globalVue.trans.get('game.regulation_violation_text'),
                        icon: ['fal', 'face-sad-tear'],
                        buttonText: window.globalVue.trans.get('generic.back_to_site')
                    }, {root: true});
                    break;
                default:
                    dispatch('modals/openModal', {
                        name: globals.GENERIC_ERROR,
                    }, {root: true});
            }

        },

        flashUnauthorizeSession({dispatch, getters, commit,}) {
            if (window.unauthorized_exception) {
                setTimeout(function () {
                    dispatch('displayUnauthorizeModal', window.unauthorized_exception);
                }, 2000);

            }
        },
        fetchGameInfos({dispatch, getters, commit,}) {
            if (window.game_infos !== 'null') {
                commit('setGameInfos', JSON.parse(window.game_infos));
                if (getters.getIsFirstDay && !sessionStorage.getItem('shownPopupGameFirstDay')) {
                    dispatch('showPopupFirstDay');
                }
            }
        },

        showPopupFirstDay({dispatch, getters, commit, state}) {
            sessionStorage.setItem('shownPopupGameFirstDay', true);
            return axios.get(ziggy_route('game.canPlay')).then(response => {
                dispatch('modals/openModal', {
                    name: globals.GAME_FIRST_DAY,
                    param: {
                        url: window.globalVue.assetManager.getImageAsset('game/' + getters.getActiveGameSlug + '/website/popup/play_first_day.jpg'),
                    }
                }, {root: true});
            }).catch(error => {
                },
            );
        },

        showGame({dispatch, getters, commit, state}) {
            sessionStorage.setItem('shownPopupGameFirstDay', true);
            return axios.get(ziggy_route('game.canPlay')).then(response => {
                window.location.href = ziggy_route('game.show', {game: response.data.game});
            }).catch(error => {
                    try {
                        let code = null;

                        try {
                            code = error.response.data.code;
                        } catch (e) {
                        }
                        dispatch('displayUnauthorizeModal', code);
                    } catch (error) {
                        dispatch('modals/openModal', {
                            name: globals.GENERIC_ERROR,
                        }, {root: true});
                    }
                },
            );
        },

        play({}, callback) {
            return axios.post(ziggy_route('game.play'))
                .then(callback)
                .catch(function () {
                    window.location.href = "/";
                });
        },
    },
};
