import Echo from 'laravel-echo';

const getBroadcaster = () => {
    const pusherEnabled = document.getElementById('pusher_enabled');

    if (pusherEnabled && pusherEnabled.value === '0') {
        return 'null';
    }

    return 'pusher';
};

const getOptions = (namespace = '') => {
    const broadcastDriver = process.env.MIX_BROADCAST_DRIVER;
    let options;

    options = {
        broadcaster: getBroadcaster(),
        key: process.env.MIX_PUSHER_APP_KEY,
        cluster: process.env.MIX_PUSHER_APP_CLUSTER,
        forceTLS: true,
    };

    if (broadcastDriver === 'ably') {
        options = {
            broadcaster: getBroadcaster(),
            key: process.env.MIX_ABLY_PUBLIC_KEY,
            wsHost: 'realtime-pusher.ably.io',
            wsPort: 443,
            disableStats: true,
            encrypted: true,
        };
    }

    if (namespace !== '') {
        options.namespace = namespace;
    }

    return options;
};

const makeEchoInstance = (namespace = '') => {
    return new Echo(getOptions(namespace));
};

export {makeEchoInstance};
