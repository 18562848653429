<template>
    <development>
        <div>
            <development-heading>Text input</development-heading>
            <base-text-input></base-text-input>

            <development-heading :level="2">Props :</development-heading>
            <ul>
                <li><strong>id</strong>: String</li>
                <li><strong>type</strong>: String - <em>'text', 'email', 'password', 'phone_number'</em></li>
                <li><strong>autofocus</strong>: Boolean</li>
                <li><strong>autocomplete</strong>: String</li>
                <li><strong>disabled</strong>: Boolean</li>
                <li><strong>error</strong>: String</li>
                <li><strong>label</strong>: String</li>
                <li><strong>large</strong>: Boolean</li>
                <li><strong>name</strong>: String</li>
                <li><strong>outlined</strong>: Boolean</li>
                <li><strong>placeholder</strong>: String</li>
                <li><strong>small</strong>: Boolean</li>
                <li><strong>x-small</strong>: Boolean</li>
                <li><strong>value</strong>: String|Number</li>
            </ul>

            <development-heading>Placeholder</development-heading>
            <base-text-input outlined placeholder="Input with placeholder"></base-text-input>

            <development-heading>Label</development-heading>
            <base-text-input outlined label="Label"></base-text-input>

            <development-heading>Label + Placeholder</development-heading>
            <base-text-input outlined label="Focus me" placeholder="Placeholder"></base-text-input>

            <development-heading>Outlined</development-heading>
            <development-paragraph><strong>outlined</strong> input</development-paragraph>
            <base-text-input outlined placeholder="Outlined input"></base-text-input>

            <development-heading>Disabled</development-heading>
            <development-paragraph><strong>disabled</strong> input</development-paragraph>
            <div class="space-y-2">
                <base-text-input disabled placeholder="Disabled input"></base-text-input>
                <base-text-input disabled placeholder="Disabled input">
                    <template #icon>
                        <font-awesome-icon :icon="['fal', 'envelope']"/>
                    </template>
                </base-text-input>
            </div>

            <development-heading>With icon</development-heading>
            <base-text-input label="Input with icon" placeholder="Input with icon" outlined>
                <template #icon>
                    <font-awesome-icon :icon="['fal', 'envelope']"/>
                </template>
            </base-text-input>

            <development-heading>Large</development-heading>
            <base-text-input large label="Large input with icon" placeholder="Large input" outlined>
                <template #icon>
                    <font-awesome-icon :icon="['fal', 'envelope']"/>
                </template>
            </base-text-input>

            <development-heading>Small</development-heading>
            <base-text-input small label="Small input with icon" placeholder="Small input" outlined>
                <template #icon>
                    <font-awesome-icon :icon="['fal', 'envelope']"/>
                </template>
            </base-text-input>

            <development-heading>X-small</development-heading>
            <base-text-input x-small label="X-small input with icon" placeholder="X-small input" outlined>
                <template #icon>
                    <font-awesome-icon :icon="['fal', 'envelope']"/>
                </template>
            </base-text-input>

            <development-heading>Error</development-heading>
            <base-text-input error="This is an error" outlined label="Focus me" placeholder="Placeholder">
                <template #icon>
                    <font-awesome-icon :icon="['fal', 'envelope']"/>
                </template>
            </base-text-input>

            <development-heading>Prepend</development-heading>
            <div class="space-y-2">
                <base-text-input label="Focus me" placeholder="Placeholder" outlined>
                    <template #prepend>
                        <base-select value="Item 1" :items="['Item 1', 'Item 2', 'Item 3']" transparent/>
                    </template>
                </base-text-input>

                <base-text-input label="Focus me" placeholder="Placeholder" outlined>
                    <template #prepend><span class="px-4">$</span></template>
                </base-text-input>
            </div>

            <development-heading>Append</development-heading>
            <div class="space-y-2">
                <base-text-input label="Focus me" placeholder="Placeholder" outlined>
                    <template #append>
                        <base-select value="Item 1" :items="['Item 1', 'Item 2', 'Item 3']" transparent/>
                    </template>
                </base-text-input>

                <base-text-input label="Focus me" placeholder="Placeholder" outlined>
                    <template #append><span class="px-4">€</span></template>
                </base-text-input>
            </div>

            <development-heading>Example</development-heading>
            <div class="space-y-2">
                <base-text-input ref="searchInput"
                                 class="conversation-search-input"
                                 type="text"
                                 placeholder="Rechercher"
                                 v-model="searchValue"
                                 outlined
                                 small>
                    <template #icon>
                        <font-awesome-icon v-if="!hasSearchValue" :icon="['fal', 'magnifying-glass']"/>
                        <font-awesome-icon v-else :icon="['fas', 'spinner']" spin/>
                    </template>
                    <template #append>
                        <base-button v-if="hasSearchValue" icon small @click="clearSearch">
                            <font-awesome-icon :icon="['fas', 'circle-xmark']" class="text-neutral-300"/>
                        </base-button>
                    </template>
                </base-text-input>
                <base-alert type="info" v-if="hasSearchValue">Recherche en cours : {{ searchValue }}</base-alert>
            </div>
        </div>
    </development>
</template>
<script>
import Development from "./Development";
import BaseTextInput from "../elements/BaseTextInput";
import DevelopmentHeading from "./elements/DevelopmentHeading";
import DevelopmentParagraph from "./elements/DevelopmentParagraph";
import BaseSelect from "../elements/BaseSelect";
import BasePhoneInput from "../elements/BasePhoneInput";
import BaseButton from "../elements/BaseButton";
import BaseAlert from "../elements/BaseAlert";

export default {
    components: {
        BaseAlert,
        BaseButton,
        BasePhoneInput, BaseSelect, DevelopmentParagraph, DevelopmentHeading, BaseTextInput, Development
    },
    methods: {
        clearSearch() {
            this.searchValue = '';
        },
    },
    computed: {
        hasSearchValue() {
            return this.searchValue.trim().length > 0;
        },
    },
    data() {
        return {
            searchValue: '',
        }
    },
}
</script>
