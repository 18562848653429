<template>
    <div class="conversation-alert">
        <base-alert type="info" block elevated
                    v-if="isTypeAlert('client_isLiveConversationWaiting')">
            <template #icon>
                <font-awesome-icon :icon="['fas', 'spinner']" spin />
            </template>
            {{ trans.get('chat.chat_live_waiting_for_master_notif_customer', {master_name: this.interlocutor().name}) }}
        </base-alert>

        <base-alert type="info" block elevated
                    v-if="isTypeAlert('client_isFreeLiveConversationWaiting')">
            <template #icon>
                <font-awesome-icon :icon="['fas', 'spinner']" spin />
            </template>
            {{
                trans.get('chat.free_chat_live_waiting_for_master_notif_customer',
                    {master_name: this.interlocutor().name})
            }}
        </base-alert>

        <base-alert type="success" block elevated
                    v-if="isTypeAlert('client_isLiveConversationInProgress_and_hasNotLiveSessionBillingStarted')">
            <template #icon>
                <font-awesome-icon :icon="['far', 'check']" />
            </template>
            {{ trans.get('chat.chat_live_in_progress_notif_customer', {master_name: this.interlocutor().name}) }}
        </base-alert>


        <base-alert type="info" block elevated
                    v-if="isTypeAlert('invite_master_to_connect')">
            <template #icon>
                <font-awesome-icon :icon="['fas', 'user-check']" />
            </template>
            {{ trans.get('chat.alert_invite_master_to_connect') }}
            <template #action>
                <base-button color="white" outlined small @click="connectMasterFromChatBot(me())">
                    {{ trans.get('chat.alert_action_connect_master') }}
                </base-button>
            </template>
        </base-alert>

        <base-alert block elevated type="success" :delay="5000" v-if="isTypeAlert('master_connected_via_chatbot')">
            <template #icon>
                <font-awesome-icon :icon="['fas', 'user-check']" />
            </template>
            {{ trans.get('chat.alert_success_master_connected_via_chatbot') }}
        </base-alert>

        <base-alert block elevated type="info" v-if="isTypeAlert('offline_chat_so_follow_up_the_user')">
            <template #icon>
                <font-awesome-icon :icon="['fas', 'envelope']" />
            </template>
            {{ trans.get('chat.follow_up.offline_chat_so_follow_up_the_user') }}
            <template #action>
                <base-button color="white" outlined small @click="setFollowUpMode">
                    {{ trans.get('chat.follow_up_button_title') }}
                </base-button>
            </template>
        </base-alert>

        <base-alert ref="offlineChatButTheUserIsAlreadyBeingFollowedUp" block elevated type="warning"
                    v-if="isTypeAlert('offline_chat_but_reason_forbid_follow_up')">
            <template v-if="parseInt(this.getFollowUpReason(this.getSelectedParticipants)) > 0">
                {{
                    trans.choice(
                        'chat.follow_up.offline_chat_but_the_user_is_already_being_followed_up',
                        parseInt(this.getFollowUpReason(this.getSelectedParticipants)), {
                            'days': this.getFollowUpReason(this.getSelectedParticipants),
                        })
                }}
            </template>
            <template
                v-else-if="this.getFollowUpReason(this.getSelectedParticipants) === 'CANNOT_FOLLOW_UP_CUSTOMER_NEW'">
                {{ trans.get('chat.follow_up.offline_chat_but_the_user_is_too_recently_register') }}
            </template>
            <template
                v-else-if="this.getFollowUpReason(this.getSelectedParticipants) === 'CANNOT_FOLLOW_UP_CUSTOMER_NOT_REGISTER'">
                {{ trans.get('chat.follow_up.offline_chat_but_the_user_is_out_of_service') }}
            </template>
            <template
                v-else-if="this.getFollowUpReason(this.getSelectedParticipants) === 'CANNOT_FOLLOW_UP_MASTER_BLOCKED'">
                {{ trans.get('chat.follow_up.offline_chat_but_the_user_blocked_master') }}
            </template>
            <template #action>
                <base-button color="white" outlined small
                             @click="$refs.offlineChatButTheUserIsAlreadyBeingFollowedUp.close()">
                    {{ trans.get('generic.i_understood') }}
                </base-button>
            </template>
        </base-alert>

        <base-alert type="info" block elevated
                    v-if="isTypeAlert('master_isMasterWithChatLiveDisabled')">
            <template #icon>
                <font-awesome-icon :icon="['fas', 'circle-info']" />
            </template>
            {{
                trans.get('chat.your_master_is_not_available_by_chat_you_can_call_it',
                    {master_name: this.interlocutor().name})
            }}
            <template #action>
                <base-link :href="interlocutor().url">
                    <base-button color="white" outlined small>
                        <font-awesome-icon :icon="['fas', 'user']" size="1x" />
                        {{ trans.get('generic.see_the_master_profile') }}
                    </base-button>
                </base-link>
            </template>
        </base-alert>

        <base-alert block elevated type="warning" v-if="isTypeAlert('chat_audio_message_record_almost_over')">
            <template #icon>
                <font-awesome-icon :icon="['fal', 'microphone-lines']" />
            </template>
            <template>
                {{
                    trans.get('chat.alert_chat_audio_message_record_almost_over',
                        {max_duration: this.getAudioMessageMaxDurationText})
                }}
            </template>
        </base-alert>

        <base-alert block elevated type="danger" v-if="isTypeAlert('chat_audio_message_record_over')">
            <template #icon>
                <font-awesome-icon :icon="['fal', 'microphone-lines']" />
            </template>
            <template>
                {{ trans.get('chat.alert_chat_audio_message_record_over') }}
            </template>
        </base-alert>

        <base-alert block elevated type="warning" v-if="isTypeAlert('chat_session_almost_over')">
            <template #icon>
                <font-awesome-icon :icon="['fas', 'triangle-exclamation']" />
            </template>
            <template>
                <base-timer v-slot="{minutes, seconds}"
                            ref="live_chat_refill_alert_timer"
                            :time="getTimeLeftForRefill"
                            auto-start countdown
                            @end="() => seconds = 0">
                    {{
                        trans.get('chat.alert_chat_session_almost_over',
                            {time: (minutes > 0 ? minutes + 'min' : '') + seconds})
                    }}
                </base-timer>
            </template>
            <template #action>
                <base-button color="white" outlined small :loading="loadingRefill" @click="buyChatLiveRefillPack">
                    {{ trans.get('chat.alert_recharge_action', {amount: refillAmount}) }}
                </base-button>
            </template>
        </base-alert>

        <base-alert block elevated type="warning" v-if="isTypeAlert('chat_continue_with_credit')">
            <template #icon>
                <font-awesome-icon :icon="['fas', 'triangle-exclamation']" />
            </template>
            <template>
                <base-timer v-slot="{minutes, seconds}"
                            ref="live_chat_continue_with_credit_alert_timer"
                            :time="getTimeLeftForRefill"
                            auto-start countdown
                            @end="() => seconds = 0">
                    {{ trans.get('chat.alert_continue_with_credit') }}
                </base-timer>
            </template>
            <template #action>
                <base-button color="white" outlined small :loading="loadingRefill" @click="continueWithCredit">
                    {{ trans.get('chat.alert_continue_with_credit_action') }}
                </base-button>
            </template>
        </base-alert>

        <base-alert block elevated type="info" v-if="isTypeAlert('chat_add_fund')">
            <template #icon>
                <font-awesome-icon :icon="['fas', 'circle-plus']" />
            </template>
            <template>
                {{ trans.get('chat.alert_chat_add_fund') }}
            </template>
            <template #action>
                <base-button color="white" outlined small :loading="loadingRefill" @click="buyChatLiveRefillPack">
                    {{ trans.get('chat.alert_recharge_action', {amount: refillAmount}) }}
                </base-button>
            </template>
        </base-alert>

        <base-alert block elevated type="success" v-if="isTypeAlert('chat_live_refill_success')">
            <template #icon>
                <font-awesome-icon :icon="['fas', 'circle-check']" />
            </template>
            <template>
                {{ trans.get('chat.chat_live_refill_success') }}
            </template>
        </base-alert>

        <base-alert block elevated type="success" v-if="isTypeAlert('chat_live_extend_with_credit_success')">
            <template #icon>
                <font-awesome-icon :icon="['fas', 'circle-check']" />
            </template>
            <template>
                {{ trans.get('chat.chat_live_extend_with_credit_success') }}
            </template>
        </base-alert>

        <base-alert block elevated type="danger" v-if="isTypeAlert('chat_live_refill_error')">
            <template #icon>
                <font-awesome-icon :icon="['fas', 'circle-info']" />
            </template>
            <template>
                {{ trans.get('chat.chat_live_refill_error') }}
            </template>
        </base-alert>

        <base-alert ref="fastPassWaiting" block elevated type="info" v-if="isTypeAlert('master_has_fast_pass_waiting')">
            <template #icon>
                <font-awesome-icon :icon="['fas', 'phone']" />
            </template>
            <template>
                {{ trans.get('chat.alert_fast_pass_waiting', {'nb_fast_pass': masterWaitingQueue.length}) }}
                <span v-if="config.get('chat.display_client_in_waiting_queue_nickname_in_chat')">
                    {{ '(' + getNextCustomerInWaitingQueueNickName + ')'}}
                </span>
            </template>
            <template #action>
                <base-button color="white" outlined small @click="$refs.fastPassWaiting.close()">
                    {{ trans.get('generic.i_understood') }}
                </base-button>
            </template>
        </base-alert>
    </div>
</template>

<style lang="scss">
.conversation-alert {
    @apply z-1 p-3 absolute top-0 right-0 left-0 justify-center items-center px-3 md:px-6 lg:px-9 xl:px-12 2xl:px-15;
}
</style>

<script>
import {mapActions, mapGetters} from 'vuex';
import ChatMixin from './ChatMixin';
import BaseButton from '../elements/BaseButton';
import BaseAlert from '../elements/BaseAlert';
import BaseLink from '../elements/BaseLink';
import BaseTimer from '../elements/BaseTimer';

export default {
    name: 'ConversationAlert',
    components: {BaseTimer, BaseLink, BaseAlert, BaseButton},
    mixins: [ChatMixin],
    computed: {
        ...mapGetters('chat', [
            'canFollowUp',
            'getChatLiveSession',
            'getConversation',
            'getConversationMessages',
            'getFollowUpReason',
            'getLiveSessionBillingTimeLeft',
            'getOfferIdFromParticipants',
            'getSelectedParticipants',
            'hasLiveSessionBillingStarted',
            'hasMasterConnectedViaChatBot',
            'iAmMaster',
            'isAddFundButtonDisplayed',
            'isContinueWithCreditButtonDisplayed',
            'isChatBotAnswerMode',
            'isChatSessionAlmostOver',
            'isFollowUpMode',
            'isFreeChatSession',
            'isLastMessageReceivedLessThanFiveMinutesAgo',
            'isLiveConversation',
            'isLiveConversationInProgress',
            'isLiveConversationWaiting',
            'masterHasFastPassWaiting',
            'masterWaitingQueue',
            'refillEmpty',
            'refillFailed',
            'refillSuccess',
            'extendWithCreditSuccess',
        ]),
        ...mapGetters('media_devices', ['getRecordTime', 'isRecordInProgress', 'isUserListeningToAudioDraft']),
        ...mapGetters('payment', ['getPackByType']),
        getTypeAlert()
        {
            return {
                'client_isLiveConversationInProgress_and_hasNotLiveSessionBillingStarted':
                    !this.iAmMaster
                    && this.isLiveConversationInProgress
                    && !this.hasLiveSessionBillingStarted
                    && !this.hasMasterGiftToClientAdvantage(),
                'client_isLiveConversationWaiting':
                    !this.iAmMaster
                    && this.isLiveConversationWaiting
                    && !this.isFreeChatSession,
                'client_isFreeLiveConversationWaiting':
                    !this.iAmMaster
                    && this.isLiveConversationWaiting
                    && this.isFreeChatSession,
                'invite_master_to_connect':
                    this.isMasterOfflineMode
                    && this.isMasterOffline(this.me())
                    && this.isLastMessageReceivedLessThanFiveMinutesAgo(this.getSelectedParticipants),
                'master_connected_via_chatbot':
                    this.isMasterOfflineMode
                    && this.isMasterOnline(this.me())
                    && this.hasMasterConnectedViaChatBot,
                'offline_chat_but_reason_forbid_follow_up':
                    this.isMasterOfflineMode
                    && !this.canFollowUp(this.getSelectedParticipants)
                    && !this.isLastMessageReceivedLessThanFiveMinutesAgo(this.getSelectedParticipants)
                    && !this.isChatBotAnswerMode,
                'offline_chat_so_follow_up_the_user':
                    this.isMasterOfflineMode
                    && this.canFollowUp(this.getSelectedParticipants)
                    && !this.isLastMessageReceivedLessThanFiveMinutesAgo(this.getSelectedParticipants)
                    && !this.isChatBotAnswerMode,
                'master_isMasterWithChatLiveDisabled':
                    !this.isConversationWithCare()
                    && !this.iAmMaster
                    && this.isMasterOnline(this.interlocutor())
                    && !this.isMasterWithChatLiveEnabled(this.interlocutor())
                    && this.getConversationMessages(this.getSelectedParticipants).length > 0,
                'chat_audio_message_record_almost_over':
                    this.isRecordInProgress
                    && this.getRecordTime >= this.config.get('chat.chat_audio_message_record_duration_before_warning'),
                'chat_audio_message_record_over':
                    (this.isRecordInProgress || this.isUserListeningToAudioDraft)
                    && this.getRecordTime >= this.config.get('chat.chat_audio_message_record_max_duration'),
                'chat_session_almost_over': this.isAddFundAlertDisplayed() && this.isAddFundButtonDisplayed && this.isChatSessionAlmostOver,
                'chat_add_fund': this.isAddFundAlertDisplayed() && this.isAddFundButtonDisplayed && !this.isChatSessionAlmostOver,
                'chat_continue_with_credit': this.isContinueWithCreditAlertDisplayed() && this.isChatSessionAlmostOver,
                'chat_live_refill_success': this.refillSuccess,
                'chat_live_refill_error': this.refillFailed,
                'chat_live_extend_with_credit_success': this.extendWithCreditSuccess,
                'master_has_fast_pass_waiting': this.iAmMaster
                    && this.isLiveConversationInProgress
                    && this.masterHasFastPassWaiting,
            };
        },
        isMasterOfflineMode()
        {
            return this.iAmMaster && !this.isConversationWithCare() && !this.isLiveConversation && !this.isFollowUpMode;
        },
        refillAmount()
        {
            return this.$options.filters.formatMoney(this.getPackByType('recharge_chat').cost / 100);
        },
        getAudioMessageMaxDurationText()
        {
            const maxDuration = this.config.get('chat.chat_audio_message_record_max_duration');
            let minutes = Math.floor(maxDuration / 60);
            let seconds = maxDuration - (minutes * 60);
            return minutes + 'm' + seconds + 's';
        },
        getTimeLeftForRefill()
        {
            return Math.abs(new Date(this.getChatLiveSession.max_end_at) - new Date());
        },
        getNextCustomerInWaitingQueueNickName()
        {
            return this.masterWaitingQueue[0].user_nick_name;
        }
    },
    methods: {
        ...mapActions('chat', [
            'buyRefill',
            'extendChatWithCredit',
            'connectMasterFromChatBot',
            'resetRefillStatus',
            'resetExtendWithCreditStatus',
            'setFollowUpMode',
            'toggleAddFundAlert',
            'toggleContinueWithCreditAlert',
        ]),
        ...mapGetters('chat', ['isAddFundAlertDisplayed', 'isContinueWithCreditAlertDisplayed']),
        isTypeAlert: function(alertName) {
            return this.getTypeAlert[alertName] !== undefined && this.getTypeAlert[alertName] === true;
        },
        buyChatLiveRefillPack: function() {
            this.loadingRefill = true;
            this.buyRefill(this.getOfferIdFromParticipants(this.getSelectedParticipants)).then(() => {
                this.loadingRefill = false;
                this.toggleAddFundAlert();
                setTimeout(this.resetRefillStatus, 5000);
            });
        },
        continueWithCredit: function() {
            this.loadingRefill = true;
            this.extendChatWithCredit().then(() => {
                this.loadingRefill = false;
                this.toggleContinueWithCreditAlert();
                setTimeout(this.resetExtendWithCreditStatus, 5000);
            });
        },
    },
    data()
    {
        return {
            loadingRefill: false,
        };
    },
    created()
    {
        this.unwatchLiveChatRefillAlertDisplayed = this.$store.watch(this.isAddFundAlertDisplayed, () => {
            if (this.isAddFundAlertDisplayed() && this.isChatSessionAlmostOver) {
                this.$nextTick(() => {
                    let timeLeftForRefill = Math.abs(new Date(this.getChatLiveSession.max_end_at) - new Date());
                    this.$refs.live_chat_refill_alert_timer.setTime(timeLeftForRefill);
                });
            }
        }, {immediate: true});

        this.unwatchLiveChatContinueWithCreditAlertDisplayed = this.$store.watch(
            this.isContinueWithCreditAlertDisplayed, () => {
                if (this.isContinueWithCreditAlertDisplayed() && this.isChatSessionAlmostOver) {
                    this.$nextTick(() => {
                        let timeLeftToContinueWithCredit = Math.abs(
                            new Date(this.getChatLiveSession.max_end_at) - new Date());
                        this.$refs.live_chat_continue_with_credit_alert_timer.setTime(timeLeftToContinueWithCredit);
                    });
                }
            }, {immediate: true});
    },
    beforeDestroy()
    {
        this.unwatchLiveChatRefillAlertDisplayed();
        this.unwatchLiveChatContinueWithCreditAlertDisplayed();
    },

};
</script>
