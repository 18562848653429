<template>
    <div>
        <form @submit="createDiagonal">
            <div class="space-y-2">
                <base-text-input v-model="iban"
                                 :error="errors.iban"
                                 :placeholder="trans.get('payment.diagonal.iban')"
                                 :label="trans.get('payment.diagonal.iban')"
                                 autocomplete="off"
                                 outlined>
                    <template #icon>
                        <font-awesome-icon :icon="['fal', 'building-columns']"/>
                    </template>
                </base-text-input>
                <base-text-input v-model="firstName"
                                 :error="errors.first_name"
                                 :placeholder="trans.get('payment.diagonal.first_name')"
                                 :label="trans.get('payment.diagonal.first_name')"
                                 autocomplete="off"
                                 outlined>
                    <template #icon>
                        <font-awesome-icon :icon="['fal', 'circle-user']"/>
                    </template>
                </base-text-input>
                <base-text-input v-model="lastName"
                                 :error="errors.last_name"
                                 :placeholder="trans.get('payment.diagonal.last_name')"
                                 :label="trans.get('payment.diagonal.last_name')"
                                 autocomplete="off"
                                 outlined>
                    <template #icon>
                        <font-awesome-icon :icon="['fal', 'circle-user']"/>
                    </template>
                </base-text-input>
                <base-text-input :placeholder="trans.get('payment.diagonal.address')"
                                 :label="trans.get('payment.diagonal.address')"
                                 :id="_uid + '_address'"
                                 autocomplete="off"
                                 outlined>
                    <template #icon>
                        <font-awesome-icon :icon="['fal', 'location-dot']"/>
                    </template>
                </base-text-input>

                <base-text-input v-model="streetName"
                                 :error="errors.street_name"
                                 :placeholder="trans.get('payment.diagonal.street')"
                                 :label="trans.get('payment.diagonal.street')"
                                 outlined
                                 disabled>
                    <template #icon>
                        <font-awesome-icon :icon="['fal', 'road']"/>
                    </template>
                </base-text-input>

                <div class="grid grid-cols-2 gap-2">
                    <base-text-input v-model="streetNumber"
                                     :error="errors.street_number"
                                     :placeholder="trans.get('payment.diagonal.number')"
                                     :label="trans.get('payment.diagonal.number')"
                                     outlined
                                     disabled>
                        <template #icon>
                            <font-awesome-icon :icon="['fal', 'hashtag']"/>
                        </template>
                    </base-text-input>
                    <base-text-input v-model="zipCode"
                                     :error="errors.zip_code"
                                     :placeholder="trans.get('payment.diagonal.postal_code')"
                                     :label="trans.get('payment.diagonal.postal_code')"
                                     outlined
                                     disabled>
                        <template #icon>
                            <font-awesome-icon :icon="['fal', 'mailbox']"/>
                        </template>
                    </base-text-input>
                </div>

                <base-text-input v-model="city"
                                 :error="errors.city"
                                 :placeholder="trans.get('payment.diagonal.city')"
                                 :label="trans.get('payment.diagonal.city')"
                                 outlined
                                 disabled>
                    <template #icon>
                        <font-awesome-icon :icon="['fal', 'city']"/>
                    </template>
                </base-text-input>
                <base-text-input v-model="country"
                                 :error="errors.country"
                                 :placeholder="trans.get('payment.diagonal.country')"
                                 :label="trans.get('payment.diagonal.country')"
                                 outlined
                                 disabled>
                    <template #icon>
                        <span :class="countryCode.toLowerCase()" class="vti__flag inline-block"></span>
                    </template>
                </base-text-input>
            </div>

            <p class="text-danger mt-3 mb-4">{{ errors.form }}</p>

            <base-paragraph class="mt-3">
                {{ trans.get('payment.diagonal.conditions') }}
                <base-link @click="toggleMoreInformationModal(true)">
                    {{ trans.get('payment.diagonal.more_information') }}
                </base-link>
            </base-paragraph>

            <slot name="actions"/>
        </form>

        <base-modal v-if="showMoreInformationModal" @close="toggleMoreInformationModal(false)" small>
            <base-title>{{ trans.get('payment.diagonal.more_information_modal.title') }}</base-title>

            <base-paragraph>{{ trans.get('payment.diagonal.more_information_modal.text') }}</base-paragraph>

            <div class="grid grid-cols-1 gap-4 mt-5">
                <base-button @click="toggleMoreInformationModal(false)">
                    {{ trans.get('generic.back') }}
                </base-button>
            </div>
        </base-modal>
    </div>
</template>

<script>
import BaseButton from "../elements/BaseButton.vue";
import BaseLink from "../elements/BaseLink.vue";
import BaseParagraph from "../elements/BaseParagraph.vue";
import BaseTextInput from "../elements/BaseTextInput.vue";
import BaseTitle from "../elements/BaseTitle.vue";
import {ziggy_route} from "../ZiggyMixin";
import ValidationLang from "../ValidationLang";

export default {
    name: "PaymentMethodDiagonalForm",
    components: {
        BaseButton,
        BaseLink,
        BaseParagraph,
        BaseTextInput,
        BaseTitle,
    },
    methods: {
        toggleMoreInformationModal(showModal) {
            if (showModal !== undefined) {
                this.showMoreInformationModal = showModal;
            } else {
                this.showMoreInformationModal = !this.showMoreInformationModal;
            }
        },
        createDiagonal(e) {
            if (e !== undefined) {
                e.preventDefault();
            }

            Object.keys(this.errors).forEach(key => {
                this.errors[key] = '';
            });

            this.loading = true;

            axios.post(ziggy_route('payment.diagonal.create-iban'), {
                iban: this.iban,
                first_name: this.firstName,
                last_name: this.lastName,
                street_number: this.streetNumber,
                street_name: this.streetName,
                zip_code: this.zipCode,
                city: this.city,
                country: this.countryCode,
            }).then((response) => {
                if (response.data.status === 'success') {
                    this.$emit('success');
                } else {
                    this.$emit('error');
                }
            }).catch((errorResponse) => {
                this.$emit('error');

                if (errorResponse.response.data.status === 422) {
                    let errorsInResponse = errorResponse.response.data.errors;
                    let validationLang = new ValidationLang();
                    let validationErrorsMap = validationLang.getMessageTranslated(errorsInResponse);

                    Object.keys(this.errors).forEach(key => {
                        this.errors[key] = validationErrorsMap.get(key) ? validationErrorsMap.get(key).join('<br/>') : '';
                    });
                } else {
                    this.errors.form = this.trans.get('generic.an_error_has_occurred');
                }
            }).finally(() => {
                this.loading = false;
            });
        },
        cancel() {
            this.$emit('cancel');
        }
    },
    mounted() {
        if (document.getElementById("cc_c2a") !== null) {
            document.getElementById("cc_c2a").remove();
        }

        new clickToAddress({
            accessToken: this.config.get('app.fetchify.access_token'),
            dom: {
                search: `${this._uid}_address`,
            },
            domMode: 'id',
            defaultCountry: this.config.get('app.fetchify.default_country'),
            countryLanguage: this.config.get('app.fetchify.country_language'),
            enabledCountries: this.config.get('app.fetchify.enabled_countries'),
            getIpLocation: false,
            showLogo: false,
            onResultSelected: (c2a, elements, address) => {
                this.streetName = address.street_name;
                this.city = address.locality;
                this.zipCode = address.postal_code;
                this.streetNumber = address.building_number;
                this.country = address.country.country_name;
                this.countryCode = address.country.short_code;
            },
            texts: {
                default_placeholder: this.trans.get('payment.fetchify.default_placeholder'),
                country_placeholder: this.trans.get('payment.fetchify.country_placeholder'),
                country_button: this.trans.get('payment.fetchify.country_button'),
                generic_error: this.trans.get('payment.fetchify.generic_error'),
                no_results: this.trans.get('payment.fetchify.no_results'),
                more: this.trans.get('payment.fetchify.more'),
            },
        });
    },
    data: function () {
        return {
            iban: "",
            firstName: "",
            lastName: "",
            city: "",
            country: "",
            countryCode: "",
            streetNumber: "",
            zipCode: "",
            streetName: "",
            showMoreInformationModal: false,
            loading: false,
            errors: {
                form: '',
                iban: '',
                first_name: '',
                last_name: '',
                street_number: '',
                street_name: '',
                zip_code: '',
                city: '',
                country: '',
            },
        }
    },
}
</script>
