<template>
    <div>
        <div class="mb-2">
            <master-user-note-editor ref="general"
                                     :title="trans.get('master.customer_notes')"
                                     :placeholder="trans.get('master.write_customer_notes')"
                                     :user-id="userId"
                                     note-type="general"
                                     @status="changeStatus"
            />
        </div>

        <div class="mb-2">
            <master-user-note-editor ref="consultation"
                                     :title="trans.get('master.reading_notes')"
                                     :placeholder="trans.get('master.write_reading_notes')"
                                     :user-id="userId"
                                     note-type="consultation"
                                     show-status
                                     @status="changeStatus"
            />
        </div>

        <div class="text-center">
            <base-button :loading="loading" @click="save">
                {{ this.trans.get('generic.save') }}
            </base-button>
        </div>
    </div>
</template>

<script>
import BaseButton from "../elements/BaseButton";
import ModalHeading from "../modals/elements/ModalHeading";
import BaseTextarea from "../elements/BaseTextarea";
import MasterUserNoteEditor from "./MasterUserNoteEditor";

export default {
    name: 'MasterUserNotesEditor',
    components: {MasterUserNoteEditor, BaseTextarea, BaseButton, ModalHeading},
    props: {
        userId: {
            type: Number | String,
        },
    },
    methods: {
        save() {
            this.$refs.general.save();
            this.$refs.consultation.save();
        },
        changeStatus() {
            this.loading = this.$refs.general.status === 'loading' || this.$refs.general.status === 'saving' || this.$refs.consultation.status === 'loading' || this.$refs.consultation.status === 'saving';
        },
    },
    data() {
        return {
            loading: false,
        }
    },
}
</script>

