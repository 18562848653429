<template>
    <div class="reset-this h-full">
        <div :class="chatClass">
            <conversation-list v-if="displayConversationList" class="chat-conversation-list"/>
            <conversation-search v-else-if="displayConversationSearch" class="chat-conversation-search"/>

            <conversation class="chat-conversation"/>

            <conversation-informations v-if="isConversationInformationsOpen" class="chat-conversation-informations"/>
        </div>

        <modal-chat-master-confirmation-to-start-live-chat
            v-if="openMasterConfirmationToStartLiveChatModal"/>
        <modal-chat-customer-confirmation-end-chat-session
            v-if="isShown(globals.CHAT_CUSTOMER_CONFIRMATION_END_CHAT_SESSION)"/>
        <modal-chat-report-problem v-if="isShown(globals.CHAT_REPORT_PROBLEM)"></modal-chat-report-problem>
        <modal-follow-up-type-choice v-if="isShown(globals.FOLLOW_UP_TYPE_CHOICE)"/>
        <modal-follow-up-to-one v-if="isShown(globals.FOLLOW_UP_TO_ONE)"/>
        <modal-chat-master-connected v-if="isShown(globals.CHAT_MASTER_CONNECTED)"/>
        <modal-chat-recharge-live v-if="isShown(globals.CHAT_RECHARGE_LIVE)"/>
    </div>
</template>

<style lang="scss">
.chat {
    @apply bg-white flex flex-row flex-grow min-h-0 w-full h-full z-10 overflow-hidden md:border md:border-neutral-100 md:rounded-lg;

    &:not(.chat--conversation-opened) {
        .chat-conversation-list, .chat-conversation-search {
            @apply w-full flex flex-col;
            @apply md:w-5/12;
            @apply lg:w-4/12;
        }

        .chat-conversation {
            @apply hidden;
            @apply md:flex md:flex-col md:w-7/12;
            @apply lg:w-8/12;
        }

        .chat-conversation-informations {
            @apply hidden;
        }
    }

    &.chat--conversation-opened:not(.chat--conversation-information-opened) {
        .chat-conversation-list, .chat-conversation-search {
            @apply hidden;

            @at-root .chat--conversation-list-opened#{&} {
                @apply md:flex md:flex-col md:w-5/12;
                @apply lg:w-4/12;
            }
        }

        .chat-conversation {
            @apply w-full;

            @at-root .chat--conversation-list-opened#{&} {
                @apply md:flex md:flex-col md:w-7/12;
                @apply lg:w-8/12;
            }
        }

        .chat-conversation-informations {
            @apply hidden;
        }
    }

    &.chat--conversation-opened.chat--conversation-information-opened {
        .chat-conversation-list, .chat-conversation-search {
            @apply hidden;
            @apply md:flex md:flex-col md:w-5/12;
            @apply lg:w-3/12;
        }

        .chat-conversation {
            @apply hidden;
            @apply lg:flex lg:w-5/12;
        }

        .chat-conversation-informations {
            @apply w-full;
            @apply md:flex md:flex-col md:w-7/12;
            @apply lg:w-4/12;
        }
    }

    &.chat--conversation-opened.chat--conversation-information-opened:not(.chat--conversation-list-opened) {
        .chat-conversation {
            @apply hidden;
            @apply lg:flex lg:w-8/12;
        }

        .chat-conversation-informations {
            @apply w-full;
            @apply md:flex md:flex-col md:w-7/12;
            @apply lg:w-4/12;
        }
    }
}
</style>

<script>
import ConversationList from './ConversationList';
import Conversation from './Conversation';
import ConversationListSearchbar from './ConversationListSearchbar';
import ConversationInformations from './ConversationInformations';
import {mapActions, mapGetters} from 'vuex';
import ChatMixin from './ChatMixin';
import ConversationSearch from './ConversationSearch';

export default {
    name: 'Chat',
    mixins: [ChatMixin],
    components: {
        Conversation,
        ConversationInformations,
        ConversationList,
        ConversationListSearchbar,
        ConversationSearch,
    },
    props: {
        displayLeftPanel: Boolean,
    },
    methods: {
        ...mapActions('chat', [
            'closeConversation',
            'connectionStateChange',
            'listenForChatBotAnsweredQuestion',
            'listenForChatBotAskedQuestion',
            'listenForFollowUpSent',
            'listenForLiveChatAcceptedEvent',
            'listenForLiveChatBillingStartedEvent',
            'listenForLiveChatRefusedEvent',
            'listenForLiveChatUpdated',
            'liveChatAccepted',
            'liveChatRefused',
            'liveChatStarted',
            'loadLiveSession',
            'onChatMainComponentDestroyed',
            'onChatMainComponentMount',
            'selectParticipants',
        ]),
        ...mapActions('modals', ['openModal']),
        updateHeight() {
            const chat = document.getElementById("chat-v5");
            if (typeof (chat) != 'undefined' && chat != null) {
                const screenHeight = document.documentElement.clientHeight;
                const headerHeight = document.getElementById('header_container').offsetHeight;
                const chatHeight = screenHeight - headerHeight;
                chat.style.height = chatHeight + "px";
            }
        },
    },
    computed: {
        ...mapGetters('chat',
            [
                'getConversation',
                'iAmMaster',
                'isChatSessionAlmostOver',
                'isConversationInformationsOpen',
                'isConversationOpen',
                'isConversationSearchOpen',
                'isLiveConversationInProgress',
                'isLiveConversationWaiting',
                'openMasterConfirmationToStartLiveChatModal',
                'sessionIsCredit',
            ]),
        ...mapGetters('modals', ['isShown']),
        chatClass() {
            return {
                'chat': true,
                'chat--conversation-opened': this.isConversationOpen,
                'chat--conversation-information-opened': this.isConversationInformationsOpen,
                'chat--conversation-list-opened': this.displayConversationList || this.displayConversationSearch,
            };
        },
        displayConversationList() {
            return this.displayLeftPanel && !this.isConversationSearchOpen;
        },
        displayConversationSearch() {
            return this.displayLeftPanel && this.isConversationSearchOpen;
        },
    },
    created() {
        this.listenForLiveChatAcceptedEvent();
        this.listenForLiveChatBillingStartedEvent();
        this.listenForLiveChatRefusedEvent();
        this.listenForLiveChatUpdated();

        this.listenForChatBotAskedQuestion();
        this.listenForChatBotAnsweredQuestion();

        this.listenForFollowUpSent();

        window.onpopstate = (event) => {
            if (event.state !== undefined && event.state !== null && event.state.participants !== undefined && event.state.participants !== null) {
                if (this.getConversation(event.state.participants) !== undefined) {
                    this.selectParticipants(event.state.participants);
                } else {
                    document.location.reload();
                }
            } else if (!this.isLiveConversationInProgress && !this.isLiveConversationWaiting) {
                this.closeConversation();
            }
        };

        this.onChatMainComponentMount();
    },
    mounted() {
        const chat = document.getElementById("chat-v5");
        if (typeof (chat) != 'undefined' && chat != null) {
            this.updateHeight();
            window.onresize = this.updateHeight;
        }
    },
    destroyed() {
        window.onpopstate = () => {
        }

        this.onChatMainComponentDestroyed();
    },
};
</script>
