<template>
    <div class="w-screen h-screen overflow-x-hidden">
        <div class="max-w-screen-sm lg:max-w-screen-md mx-auto text-center px-4">
            <img class="mx-auto w-72 md:w-96 select-none"
                 :src="titleImageSrc"/>

            <game-wheel ref="wheel"
                        @play="run"
                        :wheel-image="wheelImage"
                        :button-play-image="buttonPlayImage"
                        :button-played-image="buttonPlayedImage"
                        :prize-image="prizeImage"
                        :prize-action="prizeAction">
                <template #backgrounds>

                    <game-wheel-background
                        :color="backgroundColor"
                        fullscreen/>

                    <game-wheel-background v-for="(background, index) in backgrounds"
                                           :key="index"
                                           v-if="background.enabled"
                                           :snowfall="background.snowfall"
                                           :fullscreen="background.fullscreen"
                                           :image="background.image"
                                           :scale="background.scale"
                                           :repeat="background.repeat"
                                           :spin="background.spin"/>
                </template>
            </game-wheel>

            <div class="mb-2">
                <base-link href="/">
                    <img :src="buttonBackImageSrc"
                         class="h-12 md:h-20 mx-auto select-none"/>
                </base-link>

                <base-link :href="this.assetManager.getDocumentAsset('game/reglement_grand-jeu-de-noel_2021.pdf')"
                           color="white"
                           target="_blank">
                    {{ trans.get('game.consult_regulation') }}
                </base-link>
            </div>
        </div>

        <base-button class="fixed top-4 left-4 z-100" outlined fab small @click="settingsOpen = !settingsOpen">
            <font-awesome-icon v-if="!settingsOpen" :icon="['far', 'ellipsis-vertical']"/>
            <font-awesome-icon v-else :icon="['fal', 'xmark']"/>
        </base-button>
        <div v-if="settingsOpen" class="fixed left-0 top-0 h-full w-96 bg-white z-90">
            <div class="h-full w-full mt-16 pt-4 px-4 pb-20 overflow-y-scroll border-t-2 border-neutral-300">
                <base-text-input v-model="titleImage" class="mb-4" outlined
                                 label="Title image"
                                 placeholder="http://"/>
                <base-text-input v-model="wheelImage" class="mb-4" outlined
                                 label="Wheel image"
                                 placeholder="http://"/>
                <base-text-input v-model="buttonPlayImage" class="mb-4" outlined
                                 label="Button play image"
                                 placeholder="http://"/>
                <base-text-input v-model="buttonPlayedImage" class="mb-4" outlined
                                 label="Button played image"
                                 placeholder="http://"/>
                <base-text-input v-model="buttonBackImage" class="mb-4" outlined
                                 label="Button back image"
                                 placeholder="http://"/>
                <base-text-input v-model="prizeImage" class="mb-4" outlined
                                 label="Prize image"
                                 placeholder="http://"/>
                <base-text-input v-model="backgroundColor" class="mb-4" outlined
                                 label="Background color"
                                 placeholder="#000000"/>

                <div v-for="(background, index) in backgrounds" :key="'form_bg_' + index"
                     class="mt-10 border border-neutral-300 pt-2 px-4">
                    <base-checkbox v-model="background.enabled">Background {{ index + 1 }}</base-checkbox>
                    <div v-if="background.enabled" class="flex flex-col">
                        <base-text-input v-model="background.image" class="mb-4" outlined
                                         label="Background image"
                                         placeholder="http://"/>
                        <base-select v-model="background.scale" class="mb-4" outlined
                                     label="Scale (en %)"
                                     placeholder="Scale (en %)"
                                     :items="[100, 125, 150, 200]"/>
                        <base-checkbox v-model="background.repeat">Repeat</base-checkbox>
                        <base-checkbox v-model="background.fullscreen">Fullscreen</base-checkbox>
                        <base-checkbox v-model="background.snowfall">Snowfall</base-checkbox>
                        <base-checkbox v-model="background.spin">Spin</base-checkbox>
                    </div>
                </div>

                <base-button color="secondary" class="mt-4" @click="reset">Réinitialiser</base-button>
            </div>
        </div>
    </div>
</template>

<script>
import GameWheel from "../elements/GameWheel";
import GameWheelBackground from "../elements/GameWheelBackground";
import BaseButton from "../../elements/BaseButton";
import BaseLink from "../../elements/BaseLink";
import BaseTextInput from "../../elements/BaseTextInput";
import BaseCheckbox from "../../elements/BaseCheckbox";
import BaseSelect from "../../elements/BaseSelect";

export default {
    components: {BaseSelect, BaseTextInput, BaseButton, BaseCheckbox, BaseLink, GameWheelBackground, GameWheel},
    methods: {
        run() {
            let wheelCase = 1;

            this.prizeAction = () => {
                if (window.confirm("Case n°" + wheelCase + ". Réessayer ?")) {
                    location.reload();
                }
            };

            this.$refs.wheel.rotateWheel(wheelCase);
        },
        reset() {
            if (window.confirm("Êtes-vous sûr de vouloir réinitialiser le jeu ? Tous vos changements seront perdus.")) {
                localStorage.removeItem('titleImage');
                localStorage.removeItem('prizeImage');
                localStorage.removeItem('prizeAction');
                localStorage.removeItem('settingsOpen');
                localStorage.removeItem('wheelImage');
                localStorage.removeItem('buttonPlayImage');
                localStorage.removeItem('buttonPlayedImage');
                localStorage.removeItem('buttonBackImage');
                localStorage.removeItem('backgroundColor');
                localStorage.removeItem('backgrounds');
                location.reload();
            }
        },
    },
    computed: {
        titleImageSrc() {
            return this.urlManager.isUrl(this.titleImage) ? this.titleImage : this.assetManager.getImageAsset(this.titleImage);
        },
        buttonBackImageSrc() {
            return this.urlManager.isUrl(this.buttonBackImage) ? this.buttonBackImage : this.assetManager.getImageAsset(this.buttonBackImage);
        }
    },
    mounted() {
        if (localStorage.titleImage) this.titleImage = localStorage.titleImage;
        if (localStorage.prizeImage) this.prizeImage = localStorage.prizeImage;
        if (localStorage.prizeAction) this.prizeAction = localStorage.prizeAction;
        if (localStorage.settingsOpen) this.settingsOpen = localStorage.settingsOpen;
        if (localStorage.wheelImage) this.wheelImage = localStorage.wheelImage;
        if (localStorage.buttonPlayImage) this.buttonPlayImage = localStorage.buttonPlayImage;
        if (localStorage.buttonPlayedImage) this.buttonPlayedImage = localStorage.buttonPlayedImage;
        if (localStorage.buttonBackImage) this.buttonBackImage = localStorage.buttonBackImage;
        if (localStorage.backgroundColor) this.backgroundColor = localStorage.backgroundColor;
        if (localStorage.backgrounds) this.backgrounds = JSON.parse(localStorage.backgrounds);
    },
    watch: {
        titleImage(string) {
            localStorage.titleImage = string;
        },
        prizeImage(string) {
            localStorage.prizeImage = string;
        },
        prizeAction(string) {
            localStorage.prizeAction = string;
        },
        settingsOpen(string) {
            localStorage.settingsOpen = string;
        },
        wheelImage(string) {
            localStorage.wheelImage = string;
        },
        buttonPlayImage(string) {
            localStorage.buttonPlayImage = string;
        },
        buttonPlayedImage(string) {
            localStorage.buttonPlayedImage = string;
        },
        buttonBackImage(string) {
            localStorage.buttonBackImage = string;
        },
        backgroundColor(string) {
            localStorage.backgroundColor = string;
        },
        backgrounds: {
            handler: function (object) {
                localStorage.backgrounds = JSON.stringify(object);
            },
            deep: true
        }
    },
    data() {
        return {
            titleImage: 'game/grand-jeu-de-noel/game/wheel/title.png',
            prizeImage: 'game/grand-jeu-de-noel/game/prize/bag.png',
            prizeAction: null,
            settingsOpen: false,
            wheelImage: 'game/grand-jeu-de-noel/game/wheel/wheel.png',
            buttonPlayImage: 'game/grand-jeu-de-noel/game/wheel/button_play.gif',
            buttonPlayedImage: 'game/grand-jeu-de-noel/game/wheel/button_played.png',
            buttonBackImage: 'game/grand-jeu-de-noel/game/wheel/button_back.png',
            backgroundColor: '#12256d',
            backgrounds: [
                {
                    enabled: true,
                    fullscreen: true,
                    image: null,
                    scale: 100,
                    snowfall: true,
                    spin: false,
                    repeat: false,
                },
                {
                    enabled: true,
                    fullscreen: false,
                    image: 'game/grand-jeu-de-noel/game/wheel/background_4.png',
                    scale: 200,
                    snowfall: false,
                    spin: false,
                    repeat: false,
                },
                {
                    enabled: true,
                    fullscreen: false,
                    image: 'game/grand-jeu-de-noel/game/wheel/background_3.png',
                    scale: 100,
                    snowfall: false,
                    spin: false,
                    repeat: false,
                },
                {
                    enabled: true,
                    fullscreen: false,
                    image: 'game/grand-jeu-de-noel/game/wheel/background_1.png',
                    scale: 100,
                    snowfall: false,
                    spin: true
                },
                {
                    enabled: true,
                    fullscreen: false,
                    image: 'game/grand-jeu-de-noel/game/wheel/background_2.png',
                    scale: 200,
                    snowfall: false,
                    spin: true
                },
                {
                    enabled: false,
                    fullscreen: false,
                    image: null,
                    scale: 100,
                    snowfall: false,
                    spin: false,
                    repeat: false,
                },
                {
                    enabled: false,
                    fullscreen: false,
                    image: null,
                    scale: 100,
                    snowfall: false,
                    spin: false,
                    repeat: false,
                },
                {
                    enabled: false,
                    fullscreen: false,
                    image: null,
                    scale: 100,
                    snowfall: false,
                    spin: false,
                    repeat: false,
                },
            ],
        }
    },
}
</script>
