<template>
    <div class="conversation-search">
        <div class="conversation-search-header">
            <base-button class="conversation-search-close-button" icon @click="toggleConversationSearch(false)">
                <font-awesome-icon :icon="['fal', 'chevron-left']"/>
            </base-button>
            <base-text-input ref="searchInput"
                             class="conversation-search-input"
                             type="text"
                             :placeholder="this.trans.get('generic.search_for_a_master')"
                             v-model="searchValue"
                             @input="search"
                             outlined
                             small>
                <template #icon><font-awesome-icon :icon="['fal', 'magnifying-glass']"/></template>
                <template #append>
                    <base-button v-if="hasSearchValue" icon small @click="clearSearch">
                        <font-awesome-icon :icon="['fas', 'circle-xmark']" class="text-neutral-300"/>
                    </base-button>
                </template>
            </base-text-input>
        </div>

        <div class="conversation-search-items" @scroll="blurInput">
            <div v-if="isLoading" class="text-center p-4">
                <font-awesome-icon :icon="['fas', 'spinner']" class="text-primary" spin size="3x"/>
            </div>
            <template v-else-if="hasSearchValue">
                <template v-if="hasSearchOffers">
                    <h2 class="conversation-search-heading">{{ trans.get('generic.results') }}</h2>

                    <template v-for="conversation in searchOffers">
                        <conversation-search-item :conversation="conversation" class="mb-2"/>
                    </template>
                </template>
                <template v-else>
                    <h2 class="conversation-search-heading">{{ trans.get('generic.no_results') }}</h2>

                    <p>{{ trans.get('chat.search.no_results_for_the_search_for_masters') }}</p>
                </template>
            </template>
            <template v-else>
                <template v-if="hasFavoriteOffers">
                    <h2 class="conversation-search-heading"> {{ trans.get('generic.favorite_masters') }} </h2>

                    <template v-for="conversation in favoriteOffers">
                        <conversation-search-item :conversation="conversation" class="mb-2"/>
                    </template>
                </template>

                <template v-if="hasAvailableOffers">
                    <h2 class="conversation-search-heading"> {{ trans.get('generic.masters_online') }} </h2>

                    <template v-for="conversation in availableOffers">
                        <conversation-search-item :conversation="conversation" class="mb-2"/>
                    </template>
                </template>
            </template>
        </div>
    </div>
</template>

<style lang="scss">
.conversation-search {
    @apply relative bg-neutral-100;
}

.conversation-search-heading {
    @apply text-lg leading-8 text-primary font-bold;
}

.conversation-search-header {
    @apply flex pr-4 py-2 items-center;
}

.conversation-search-input {
    @apply flex-grow;
}

.conversation-search-close-button {
    @apply flex-none text-primary;
}

.conversation-search-items {
    @apply flex-1 overflow-y-scroll px-4 py-2;
}
</style>

<script>
import {mapActions} from "vuex";
import ConversationListSearchbar from "./ConversationListSearchbar";
import BaseTextInput from "../elements/BaseTextInput";
import BaseButton from "../elements/BaseButton";
import ConversationSearchItem from "./ConversationSearchItem";
import requests from "./requests";

export default {
    name: 'ConversationSearch',
    components: {ConversationSearchItem, BaseButton, BaseTextInput, ConversationListSearchbar},
    methods: {
        ...mapActions('chat', ['toggleConversationSearch']),
        focusInput() {
            this.$refs.searchInput.focusInput();
        },
        blurInput() {
            this.$refs.searchInput.blurInput();
        },
        clearSearch() {
            this.searchValue = '';
            this.focusInput();
        },
        search() {
            if (this.hasSearchValue) {
                this.loadingSearchOffers = true;

                clearInterval(this.searchWorker);
                this.searchWorker = setTimeout(() => {
                    if (this.axiosCancelSource !== null) {
                        this.axiosCancelSource.cancel('Cancel search.');
                    }

                    this.axiosCancelSource = axios.CancelToken.source();
                    requests.searchConversations(
                        {
                            keyword: this.searchValue
                        }, {
                            cancelToken: this.axiosCancelSource.token,
                        }, (response) => {
                            this.searchOffers = response.data.data;
                        }, (errorResponse) => {
                            console.error(errorResponse);
                        }, () => {
                            this.loadingSearchOffers = false;
                        }
                    );
                }, 300);
            } else {
                this.searchOffers = {};
            }
        }
    },
    computed: {
        isLoading() {
            return this.loadingAvailableOffers || this.loadingFavoriteOffers || this.loadingSearchOffers;
        },
        hasSearchValue() {
            return this.searchValue.trim().length > 0;
        },
        hasAvailableOffers() {
            return this.availableOffers.length > 0;
        },
        hasFavoriteOffers() {
            return this.favoriteOffers.length > 0;
        },
        hasSearchOffers() {
            return this.searchOffers.length > 0;
        }
    },
    mounted() {
        this.focusInput();

        this.loadingAvailableOffers = true;
        this.loadingFavoriteOffers = true;

        requests.getAvailableOffers(
            (response) => {
                this.availableOffers = response.data.data;
            }, (errorResponse) => {
                console.error(errorResponse);
            }, () => {
                this.loadingAvailableOffers = false;
            }
        );

        requests.getFavoriteOffers(
            (response) => {
                this.favoriteOffers = response.data.data;
            }, (errorResponse) => {
                console.error(errorResponse);
            }, () => {
                this.loadingFavoriteOffers = false;
            }
        );
    },
    data() {
        return {
            loadingAvailableOffers: false,
            loadingFavoriteOffers: false,
            loadingSearchOffers: false,
            searchValue: '',
            availableOffers: {},
            favoriteOffers: {},
            searchOffers: {},
            searchWorker: null,
            axiosCancelSource: null,
        }
    },
}
</script>
