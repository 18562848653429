<template>
    <base-modal :small="small">
        <modal-heading>{{ title }}</modal-heading>
        <modal-paragraph v-html="description"></modal-paragraph>
        <modal-paragraph v-if="hasIcon" class="text-center">
            <font-awesome-icon class="text-primary" :icon="icon" size="6x" />
        </modal-paragraph>
        <div class="grid grid-cols-1 gap-4 mt-5">
            <base-button @click="closeModal">{{ buttonText }}</base-button>
        </div>
    </base-modal>
</template>

<script>
import ModalHeading from "./elements/ModalHeading";
import ModalParagraph from "./elements/ModalParagraph";
import BaseButton from "../elements/BaseButton";
import {mapActions, mapGetters} from "vuex";
export default {
    name: "ModalMessage",
    components: {BaseButton, ModalParagraph, ModalHeading},
    computed: {
        ...mapGetters('modals', ['getParam']),
        hasIcon() {
            return this.icon !== null && this.icon !== undefined;
        }
    },
    methods: {
        ...mapActions('modals', ['closeModal'])
    },
    data() {
        return {
            small: false,
            title: '',
            description: '',
            icon: null,
            buttonText: ''
        }
    },
    mounted() {
        this.small = this.getParam['message']['small'];
        this.title = this.getParam['message']['title'];
        this.description = this.getParam['message']['description'];
        this.icon = this.getParam['message']['icon'];
        this.buttonText = this.getParam['message']['buttonText'];
    }
}
</script>
