<template>
    <div class="conversation-list-searchbar">
        <base-text-input type="text"
                         small
                         :placeholder="placeholder"
                         v-model="search">
            <template #icon><font-awesome-icon :icon="['fal', 'magnifying-glass']"/></template>
        </base-text-input>
    </div>
</template>

<style>
.conversation-list-searchbar {
    @apply w-full flex p-3 gap-3 grid grid-cols-1;
}
</style>
<script>
import BaseTextInput from "../elements/BaseTextInput";

export default {
    components: {BaseTextInput},
    props: {
        placeholder: String
    },
    data() {
        return {
            search: ''
        }
    }
}
</script>
