<template>
    <div>
        <h1 class="text-primary text-2xl font-bold my-4">{{ trans.get('contact.page_title') }}</h1>

        <div v-if="postFormSucceed">
            <span class="text-success">{{ trans.get('contact.form_sent_successfully') }}</span>
        </div>

        <div v-else>
            <form @submit="postForm" id="contact_form">
                <base-paragraph>{{ trans.get('contact.before_form_paragraph') }}</base-paragraph>

                <div class="mb-4">
                    <base-text-input type="text"
                                     autofocus
                                     outlined
                                     v-model="name"
                                     :label="trans.get('generic.your_name')"
                                     :placeholder="trans.get('generic.your_name')"
                                     :error="errors.name"
                                     @keydown="clearError('name')">
                        <template #icon>
                            <font-awesome-icon :icon="['fas', 'user']"/>
                        </template>
                    </base-text-input>
                </div>
                <div class="mb-4">
                    <base-text-input type="email"
                                     outlined
                                     v-model="email"
                                     :label="trans.get('generic.your_email')"
                                     :placeholder="trans.get('generic.your_email')"
                                     :error="errors.email"
                                     @keydown="clearError('email')">
                        <template #icon>
                            <font-awesome-icon :icon="['fal', 'envelope']"/>
                        </template>
                    </base-text-input>
                </div>
                <div class="mb-4">
                    <base-text-input outlined
                                     v-model="phone"
                                     :label="trans.get('generic.your_phone_number')"
                                     :placeholder="trans.get('generic.your_phone_number')"
                                     :error="errors.phone"
                                     @keydown="clearError('phone')">
                        <template #icon>
                            <font-awesome-icon :icon="['far', 'mobile-screen-button']"/>
                        </template>
                    </base-text-input>
                </div>
                <div class="mb-4">
                    <base-select v-model="reason"
                                 :label="trans.get('generic.your_reason')"
                                 :placeholder="trans.get('generic.your_reason')"
                                 :items="reasonItems"
                                 :error="errors.reason"
                                 @blur="clearError('reason')"
                                 block outlined>
                        <template #icon>
                            <font-awesome-icon :icon="['far', 'circle-exclamation']"/>
                        </template>
                    </base-select>
                </div>
                <div class="mb-4">
                    <base-textarea v-model="message"
                                   :label="this.trans.get('generic.your_message')"
                                   :placeholder="this.trans.get('generic.your_message')"
                                   :rows="7"
                                   :error="errors.message"
                                   @keydown="clearError('message')"
                                   icon-top outlined>
                        <template #icon>
                            <font-awesome-icon :icon="['fal', 'message-lines']"/>
                        </template>
                    </base-textarea>
                </div>
                <div class="mb-4">
                    <base-recaptcha v-model="captcha"
                                    :siteKey="this.config.get('spam_protection.recaptcha.sitekey')"
                                    :error="errors.captcha"
                                    @recaptchaValidate="clearError('captcha')">
                    </base-recaptcha>
                </div>

                <base-button type="submit" :loading="formLoading">
                    {{ trans.get('generic.send') }}
                </base-button>
            </form>
        </div>
    </div>
</template>

<script>
import BaseTextInput from '../elements/BaseTextInput.vue';
import BaseSelect from '../elements/BaseSelect.vue';
import BaseTextarea from '../elements/BaseTextarea.vue';
import BaseButton from '../elements/BaseButton.vue';
import BaseParagraph from '../elements/BaseParagraph.vue';
import BaseReCaptcha from '../elements/BaseReCaptcha.vue';
import axios from 'axios';
import ValidationLang from '../ValidationLang';
import {ziggy_route} from '../ZiggyMixin';

export default {
    name: 'contact-form',
    components: {BaseParagraph, BaseTextarea, BaseSelect, BaseTextInput, BaseButton, BaseReCaptcha},
    data() {
        return {
            reasonItems: [
                this.trans.get('contact.reasons_list.technical_issue'),
                this.trans.get('contact.reasons_list.consultation_issue'),
                this.trans.get('contact.reasons_list.welcome_offer'),
                this.trans.get('contact.reasons_list.find_master'),
                this.trans.get('contact.reasons_list.order_issue'),
                this.trans.get('contact.reasons_list.other'),
            ],
            errors: {
                name: '',
                email: '',
                phone: '',
                reason: '',
                message: '',
                captcha: '',
            },
            postFormSucceed: false,
            formLoading: false,
            csrf: '',
            name: '',
            email: '',
            phone: '',
            reason: null,
            message: '',
            captcha: ''
        };
    },
    methods: {
        clearError(field) {
            this.errors[field] = '';
        },
        postForm(e) {
            e.preventDefault();
            this.formLoading = true;

            axios.post(ziggy_route('contact.send-form'), {
                name: this.name,
                email: this.email,
                phone: this.phone,
                reason: this.reason,
                message: this.message,
                captcha: this.captcha,
                csrf: window.csrf_token
            }).then((res) => {
                this.postFormSucceed = true;
            }).catch((errorResponse) => {
                let errorsInResponse = errorResponse.response.data.errors;
                let validationLang = new ValidationLang();
                let validationErrorsMap = validationLang.getMessageTranslated(errorsInResponse);

                Object.keys(this.errors).forEach(key => {
                    this.errors[key] = validationErrorsMap.get(key) ? validationErrorsMap.get(key).join('<br/>') : '';
                });
            }).finally(() => {
                this.formLoading = false;
            });
        }
    }
}
</script>
