<template>
    <div class="base-infinite-pagination">
        <transition-group v-if="paginationEnabled" name="pagination" tag="p">
            <span v-for="item in items" v-bind:key="item" class="pagination-item" @click="click(item)"></span>
        </transition-group>
    </div>
</template>

<style lang="scss">
.base-infinite-pagination {
    @apply flex justify-center h-4;

    .pagination-item {
        @apply bg-neutral-300 h-2 w-2 mx-2 inline-block rounded-full transition duration-300 cursor-pointer;
    }

    .pagination-item:first-child, .pagination-item:last-child {
        @apply opacity-0 w-0;
    }

    .pagination-item:nth-child(3):nth-last-child(3) {
        @apply bg-neutral-800;
    }

    .pagination-item:not(.pagination-item:nth-child(3):nth-last-child(3)) {
        @apply bg-neutral-300;
    }
}
</style>

<script>
export default {
    name: "BaseInfinitePagination",
    props: {
        slider: Object,
    },
    mounted() {
        this.$watch('slider', () => {
            if (this.hasSlider) {
                this.slider.on('activeIndexChange', (swiper) => {
                    if (swiper.swipeDirection === 'prev' || swiper.touchesDirection === 'prev') {
                        this.prev();
                    } else if (swiper.swipeDirection === 'next' || swiper.touchesDirection === 'next') {
                        this.next();
                    }
                });
            }
        });
    },
    computed: {
        hasSlider() {
            return this.slider !== null;
        },
        paginationEnabled() {
            return this.slider !== null && this.slider.slides.length > this.slider.params.slidesPerView;
        },
    },
    methods: {
        click(item) {
            let index = this.items.indexOf(item);
            if (index < 2) {
                this.prev();
                this.slider.slidePrev();
            } else if (index > 2) {
                this.next();
                this.slider.slideNext();
            }
        },
        prev() {
            this.runAnimation(() => {
                this.items.unshift(this.items.pop())
            });
        },
        next() {
            this.runAnimation(() => {
                this.items.push(this.items.shift());
            });
        },
        runAnimation(animation) {
            if (!this.animationEnd) {
                return false;
            }

            animation();

            this.animationEnd = false;
            setTimeout(() => {
                this.animationEnd = true
            }, 300);
        }
    },
    data() {
        return {
            items: [1, 2, 3, 4, 5],
            animationEnd: true,
        }
    },
}
</script>
