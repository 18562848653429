<template>
    <development>
        <div>
            <development-heading>Code input</development-heading>
            <base-code-input/>

            <development-heading :level="2">Props :</development-heading>
            <ul>
                <li><strong>fields</strong>: Number</li>
                <li><strong>autofocus</strong>: Boolean</li>
                <li><strong>disabled</strong>: Boolean</li>
                <li><strong>error</strong>: String</li>
                <li><strong>placeholder</strong>: String</li>
            </ul>
        </div>

        <development-heading>Fields</development-heading>
        <base-code-input :fields="10"/>

        <development-heading>Placeholder</development-heading>
        <base-code-input placeholder="X"/>

        <development-heading>Disabled</development-heading>
        <base-code-input disabled/>

        <development-heading>Error</development-heading>
        <base-code-input error="This is an error"/>

        <development-heading>Events</development-heading>
        <div class="space-y-2">
            <base-code-input v-model="codeValue"
                             @change="addEvent('onChange', 'info')"
                             @complete="addEvent('onComplete', 'success')"/>
            <development-paragraph>Value : {{ codeValue }}</development-paragraph>
        </div>

        <div class="notifications">
            <template v-for="(timerEvent, index) in componentEvents">
                <base-alert :type="timerEvent.type" dismissible elevated class="mb-2" :delay="2000">
                    {{ timerEvent.message }}
                </base-alert>
            </template>
        </div>
    </development>
</template>

<style>
.notifications {
    @apply fixed top-0 right-0 flex flex-col-reverse p-2;
}
</style>

<script>
import Development from "./Development";
import DevelopmentHeading from "./elements/DevelopmentHeading";
import DevelopmentParagraph from "./elements/DevelopmentParagraph";
import BaseCodeInput from "../elements/BaseCodeInput";
import BaseAlert from "../elements/BaseAlert";

export default {
    components: {BaseAlert, BaseCodeInput, DevelopmentParagraph, DevelopmentHeading, Development},
    data() {
        return {
            counting: false,
            componentEvents: [],
            codeValue: "",
        };
    },
    methods: {
        addEvent: function (message, type) {
            this.componentEvents.push({message: message, type: type});
        },
    },
}
</script>
