import { render, staticRenderFns } from "./BaseVideoPlayer.vue?vue&type=template&id=09031fbe"
import script from "./BaseVideoPlayer.vue?vue&type=script&lang=js"
export * from "./BaseVideoPlayer.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports