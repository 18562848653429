<template>
    <base-modal small :error="loadingError">
        <modal-heading>
            {{ trans.get('chat.modals.client_confirmation_end_chat') }}
        </modal-heading>

        <div class="grid grid-cols-2 gap-4 mt-5">
            <base-button outlined @click="closeModal">
                {{ trans.get('generic.no') }}
            </base-button>
            <base-button :loading="this.loading" @click="stop">
                {{ trans.get('generic.yes') }}
            </base-button>
        </div>
    </base-modal>
</template>

<script>
import BaseButton from "../elements/BaseButton";
import ModalHeading from "./elements/ModalHeading";
import {mapActions} from "vuex";

export default {
    name: "ModalChatConfirmationEndChatClient",
    components: {BaseButton, ModalHeading},
    methods: {
        ...mapActions('chat', ['stopLiveChat']),
        ...mapActions('modals', ['closeModal']),
        async stop() {
            this.loading = true;
            await this.stopLiveChat();
            this.loading = false;
        }
    },
    data() {
        return {
            loadingError: '',
            loading: false,
        };
    },
}
</script>

<style scoped>

</style>
