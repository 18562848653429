<template>
    <base-modal disable-closing dense zIndex="z-40" :error="loadingError">
        <div class="px-6 pt-6 pb-2">
            <modal-heading>
                {{ trans.get('generic.chat_user_wants_to_start_live_chat_with_you', {'username': name}) }}
            </modal-heading>
        </div>

        <div class="flex items-center bg-neutral-100 p-4">
            <div class="flex-none justify-center mr-4">
                <base-avatar :name="name" :photo="getSegmentPhoto(getSegment)" large outlined />
            </div>
            <div class="flex-grow">
                <base-list>
                    <base-list-item>
                        <strong class="text-primary text-base leading-4">{{ name }}</strong>
                    </base-list-item>
                    <base-list-item>
                        <template #icon>
                            <font-awesome-icon :icon="['fas', 'circle-chevron-right']"/>
                        </template>
                        <template #title>
                            {{ trans.get('generic.user_segment.type') }}
                        </template>
                        <template #description>
                        <span v-if="getSegment === 'gold'">
                            {{ trans.get('generic.user_segment.gold') }}
                            <font-awesome-icon :icon="['fas', 'trophy-star']" class="text-star"/>
                        </span>
                            <span v-else-if="getSegment === 'silver'">
                            {{ trans.get('generic.user_segment.silver') }}
                            <font-awesome-icon :icon="['fas', 'trophy']" class="text-neutral-400"/>
                        </span>
                            <span v-else-if="getSegment === 'diamond'">
                            {{ trans.get('generic.user_segment.diamond') }}
                            <font-awesome-icon :icon="['fas', 'gem']" class="text-dark"/>
                        </span>

                        <span v-else-if="getSegment === 'customer_to_retain'">
                            {{ trans.get('generic.user_segment.customer_to_retain') }}
                            <font-awesome-icon :icon="['far', 'face-smile-plus']" class="text-success"/>
                        </span>
                        <span v-else-if="getSegment === 'loyal_customer'">
                            {{ trans.get('generic.user_segment.loyal_customer') }}
                            <font-awesome-icon :icon="['far', 'face-smile']" class="text-primary"/>
                        </span>
                        <span v-else-if="getSegment === 'customer_to_reloyalty'">
                            {{ trans.get('generic.user_segment.customer_to_reloyalty') }}
                            <font-awesome-icon :icon="['far', 'face-sleeping']" class="text-warning"/>
                        </span>
                        <span v-else-if="getSegment === 'free_customer'">
                            {{ trans.get('generic.user_segment.free_customer') }}
                            <font-awesome-icon :icon="['far', 'face-meh']" class="text-main"/>
                        </span>
                            <span v-else>
                            {{ trans.get('generic.user_segment.customer') }}
                            <font-awesome-icon :icon="['fas', 'user']" class="text-primary"/>
                        </span>
                        </template>
                    </base-list-item>
                    <base-list-item>
                        <template #icon>
                            <font-awesome-icon :icon="['fas', 'circle-chevron-right']"/>
                        </template>
                        <template #title>{{ trans.get('generic.birth_date') }} :</template>
                        <template #description>{{ dt_birth | formatDate }}</template>
                    </base-list-item>
                    <base-list-item>
                        <template #icon>
                            <font-awesome-icon :icon="['fas', 'circle-chevron-right']"/>
                        </template>
                        <template #title>{{ trans.get('generic.rate') }} :</template>
                        <template #description v-if="free_chat">{{ trans.get('chat.modals.free_chat_no_payoff') }}</template>
                        <template #description v-else>{{ cost_by_minute | formatMoneyPerMinute }}</template>
                    </base-list-item>
                </base-list>
            </div>
        </div>

        <div class="p-6 mt-2">
            <div class="grid grid-cols-2 gap-4">
                <base-button :loading="this.loadingRefuse" outlined @click="refuse">
                    {{ trans.get('generic.refuse') }}
                </base-button>
                <base-button autofocus :loading="this.loadingAccept" @click="accept">
                    {{ trans.get('generic.accept') }}
                </base-button>
            </div>
        </div>
    </base-modal>
</template>

<script>

import {mapActions, mapGetters} from 'vuex';
import BaseButton from '../elements/BaseButton';
import ModalHeading from './elements/ModalHeading';
import BaseListItem from '../elements/BaseListItem';
import BaseList from '../elements/BaseList';
import ChatMixin from '../chat/ChatMixin';
import BaseAvatar from '../elements/BaseAvatar';
import UserMixin from '../user/UserMixin';

export default {
    components: {BaseAvatar, BaseList, BaseListItem, BaseButton, ModalHeading},
    mixins: [ChatMixin, UserMixin],
    computed: {
        ...mapGetters('chat', ['getClientInformationData', 'getChatLiveSession']),
        getSegment()
        {
            return this.getClientInformationData['segment'];
        },
        shouldPlayChatAskedSound()
        {
            return this.config.get('chat.play_sound_when_master_receives_a_chat');
        }
    },
    methods: {
        ...mapActions('chat', ['loadClientInformation']),
        ...mapActions('chat', ['acceptLiveChat', 'refuseLiveChat']),
        cancel()
        {
            this.$emit('cancel');
        },
        async accept() {
            this.loadingAccept = true;

            await this.acceptLiveChat();

            this.loadingAccept = false;
        },
        async refuse() {
            this.loadingRefuse = true;

            await this.refuseLiveChat();

            this.loadingRefuse = false;
        }
    },
    data() {
        return {
            loadingError: '',
            dt_birth: '',
            name: '',
            cost_by_minute: 0,
            free_chat: false,
            loadingAccept: false,
            loadingRefuse: false
        };
    },
    created() {
        this.loadClientInformation();
    },
    mounted() {
        this.name = this.interlocutor().name;
        this.dt_birth = this.interlocutor().dt_birth;
        if(this.getChatLiveSession.billing_advantages.length === 0) {
            this.cost_by_minute = this.getChatLiveSession.cost_by_minute / 100;
        } else {
            this.free_chat = true;
        }
        if (this.shouldPlayChatAskedSound) {
            window.globalVue.chatAskedSound.play();
        }
    },
};
</script>
