<template>
    <base-modal>
        <form @submit="postForm">
            <base-title color="whatsapp" center>
                {{ trans.get('whatsapp.catcher.we_are_coming_to_whatsapp') }}
            </base-title>

            <base-paragraph class="text-center">
                <strong>
                    {{ trans.get('whatsapp.catcher.join_our_whatsappfamily') }}
                </strong>
            </base-paragraph>

            <div class="w-full text-center mb-4">
                <img class="inline-block max-h-48" :src="assetManager.getImageAsset('whatsapp/whatsapp-catcher.gif')"/>
            </div>

            <base-checkbox ref="checkbox_right_of_withdrawal"
                           color="whatsapp"
                           block
                           v-model="whatsapp_notifications_value"
                           :error="whatsapp_notifications_error"
                           @change="whatsapp_notifications_error = ''">
                <font-awesome-icon :icon="['fas', 'circle-chevron-right']" class="text-whatsapp"/>
                <span v-html="this.trans.get('whatsapp.catcher.i_agree_to_receive_whatsapp')"></span>
            </base-checkbox>

            <base-button type="submit" color="whatsapp" :loading="formLoading" block>
                {{ trans.get('whatsapp.catcher.lets_go') }}
            </base-button>
        </form>
    </base-modal>
</template>

<script>
import BaseButton from '../elements/BaseButton';
import BaseCheckbox from '../elements/BaseCheckbox';
import BaseLink from '../elements/BaseLink';
import BaseParagraph from "../elements/BaseParagraph";
import BaseTextInput from '../elements/BaseTextInput';
import BaseTitle from "../elements/BaseTitle";
import ValidationLang from '../ValidationLang.js';
import {mapActions} from "vuex";
import {ziggy_route} from '../ZiggyMixin';

export default {
    name: 'ModalWhatsappCatcher',
    components: {
        BaseParagraph,
        BaseTitle, BaseLink, BaseCheckbox, BaseButton, BaseTextInput
    },
    methods: {
        ...mapActions('modals', ['openModalLog', 'closeModal']),
        postForm(e) {
            e.preventDefault();

            this.formLoading = true;

            axios.post(ziggy_route('popup.whatsapp-catcher.accept'), {
                whatsapp_notifications: this.whatsapp_notifications_value,
            }).then(() => {
                this.closeModal();
            }).catch((errorResponse) => {
                let errorsInResponse = errorResponse.response.data.errors;
                let validationLang = new ValidationLang();
                let validationErrorsMap = validationLang.getMessageTranslated(errorsInResponse);

                if (validationErrorsMap.get('whatsapp_notifications') !== undefined) {
                    this.whatsapp_notifications_error = validationErrorsMap.get('whatsapp_notifications').join('<br/>');
                } else {
                    this.whatsapp_notifications_error = '';
                }
            }).finally(() => {
                this.formLoading = false;
            });
        },
    },
    data() {
        return {
            formLoading: false,
            whatsapp_notifications_error: '',
            whatsapp_notifications_value: false,
        }
    },
}
</script>
