<template>
    <div class="w-full text-neutral-800">
        <base-audio-player ref="audio-player"
                           :src="url"
                           :initialDuration="duration"
                           @update="update"/>
        <div class="flex items-stretch">
            <div class="flex-none pr-2">
                <base-button v-if="hasError" icon large @click="reload">
                    <font-awesome-icon class="text-neutral-800" :icon="['fas', 'rotate-right']"/>
                </base-button>
                <base-button v-else-if="isLoading" icon large>
                    <font-awesome-icon class="text-neutral-800" :icon="['fas', 'spinner']" spin/>
                </base-button>
                <base-button v-else-if="isPlaying" icon large @click="pause">
                    <font-awesome-icon class="text-neutral-800" :icon="['fas', 'pause']"/>
                </base-button>
                <base-button v-else icon large @click="play">
                    <font-awesome-icon class="text-neutral-800" :icon="['fas', 'play']"/>
                </base-button>
            </div>
            <div class="flex-grow flex">
                <input type="range" class="progressBar"
                       min="0" max="100" step="0.1" :value="playingProgression"
                       @input="setCurrentTime"/>
            </div>
            <div class="flex-none ml-2 w-10 text-center flex items-center">
                <span v-if="displayCurrentTime">{{ currentTime | formatSecondsToTimer }}</span>
                <span v-else>{{ duration | formatSecondsToTimer }}</span>
            </div>
        </div>
    </div>
</template>

<style lang="scss">

.progressBar {
    @apply w-full h-full self-center cursor-pointer inline-block;
    -webkit-appearance: none;
    margin: 0 -5px;
    background: none;
    z-index: 0;

    &:focus {
        @apply outline-none;
    }

    &::-webkit-slider-runnable-track {
        @apply h-0.5 cursor-pointer bg-neutral-800;
    }

    &::-moz-range-track {
        @apply h-0.5 cursor-pointer bg-neutral-800;
    }

    &::-ms-track {
        @apply h-0.5 cursor-pointer bg-neutral-800;
    }

    &::-webkit-slider-thumb {
        @apply cursor-pointer rounded-full h-4 w-4 z-3 relative bg-neutral-800;
        -webkit-appearance: none;
        margin-top: 1px;
        transform: translateY(-50%);
    }

    &::-moz-range-thumb {
        @apply cursor-pointer rounded-full h-4 w-4 z-3 relative bg-neutral-800;
        -webkit-appearance: none;
    }

    &::-ms-thumb {
        @apply cursor-pointer rounded-full h-4 w-4 z-3 relative bg-neutral-800;
        transform: translateY(0%);
    }
}
</style>

<script>
import BaseAudioPlayer from "../elements/BaseAudioPlayer";
import BaseButton from "../elements/BaseButton";

export default {
    name: "ConversationAudioRecordPlayer",
    components: {BaseButton, BaseAudioPlayer},
    props: {
        url: {
            type: String
        },
        duration: Number,
    },
    computed: {
        hasError() {
            return this.status === 'error';
        },
        isLoading() {
            return this.status === 'loading';
        },
        isPlaying() {
            return this.status === 'playing';
        },
        displayCurrentTime() {
            return this.status === 'playing' || this.status === 'changingTime' || this.status === 'paused';
        }
    },
    methods: {
        play() {
            this.$refs["audio-player"].play();
        },
        pause() {
            this.$refs["audio-player"].pause();
        },
        reload() {
            this.$refs["audio-player"].init();
            this.$refs["audio-player"].play();
        },
        setCurrentTime(element) {
            this.$refs["audio-player"].setPlayingProgression(element.target.value);
        },
        update() {
            this.currentTime = this.$refs["audio-player"].currentTime;
            this.duration = this.$refs["audio-player"].duration;
            this.status = this.$refs["audio-player"].status;
            this.playingProgression = this.$refs["audio-player"].playingProgression;
        },
    },
    data() {
        return {
            playingProgression: 0,
            currentTime: 0,
            status: null,
        }
    }
}
</script>
