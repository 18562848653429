<template>
    <base-modal>
        <div v-if="error === 'MASTER_IS_BUSY'">
            <modal-heading>{{
                    trans.get('recall-customer.modals.impossible_recall',
                        {customer_nick_name: customer.nick_name})
                }}
            </modal-heading>

            <modal-paragraph>{{
                    trans.get('recall-customer.modals.you_are_already_in_a_conversation_with_a_customer')
                }}
            </modal-paragraph>

            <div class="text-center my-8">
                <font-awesome-icon :icon="['fas', 'triangle-exclamation']" class="text-warning" size="4x" />
            </div>

            <div class="grid grid-cols-1 mt-8">
                <base-button block @click="hide">
                    {{ trans.get('generic.ok') }}
                </base-button>
            </div>
        </div>
        <div v-else-if="error === 'CUSTOMER_IS_BUSY'">
            <modal-heading>{{
                    trans.get('recall-customer.modals.customer_is_busy',
                        {customer_nick_name: customer.nick_name})
                }}
            </modal-heading>

            <modal-paragraph>{{
                    trans.get('recall-customer.modals.you_cannot_recall')
                }}
            </modal-paragraph>

            <div class="text-center my-8">
                <font-awesome-icon :icon="['fal', 'face-sad-tear']" class="text-primary" size="4x" />
            </div>

            <div class="grid grid-cols-1 mt-8">
                <base-button block @click="hide">
                    {{ trans.get('generic.ok') }}
                </base-button>
            </div>
        </div>
        <div v-else-if="error === 'MAX_TIME_TO_RECALL_CUSTOMER_EXCEEDED' || seconds <= 0">
            <modal-heading>{{
                    trans.get('recall-customer.modals.call_with_customer_expired',
                        {customer_nick_name: customer.nick_name})
                }}
            </modal-heading>

            <modal-paragraph>{{
                    trans.get('recall-customer.modals.you_cannot_recall_this_customer_anymore')
                }}
            </modal-paragraph>

            <div class="text-center my-8">
                <font-awesome-icon :icon="['fal', 'face-sad-tear']" class="text-primary" size="4x" />
            </div>

            <modal-paragraph>{{
                    trans.get('recall-customer.modals.do_not_forget_you_have_one_minute_to_recall_a_customer')
                }}
            </modal-paragraph>

            <div class="grid grid-cols-1 mt-8">
                <base-button block @click="hide">
                    {{ trans.get('generic.ok') }}
                </base-button>
            </div>
        </div>
        <div v-else-if="seconds > 0">
            <modal-heading>{{ trans.get('recall-customer.modals.recall_customer') }}</modal-heading>

            <div class="-mx-6 px-6 py-4 mb-4 bg-neutral-100 flex items-start justify-left">
                <div class="bg-white flex h-20 items-center justify-center mr-5 rounded-full w-20">
                    <img :src="getSegmentPhoto(customer.segment)" alt="" class="w-14">
                </div>
                <div class="flex flex-col justify-center">
                    <span class="text-primary text-xl mb-2 font-bold leading-6">{{ customer.nick_name }}</span>
                    <base-list>
                        <base-list-item>
                            <template #icon>
                                <font-awesome-icon :icon="['fas', 'circle-chevron-right']" />
                            </template>
                            <template #title>{{ trans.get('generic.user_segment.type') }}</template>
                            <template #description>{{
                                    trans.get('generic.user_segment.' + customer.segment)
                                }}
                            </template>
                        </base-list-item>
                    </base-list>
                </div>
            </div>

            <modal-paragraph>
                {{
                    trans.get('recall-customer.modals.do_you_want_to_recall_customer',
                        {customer_nick_name: customer.nick_name})
                }}
            </modal-paragraph>

            <div class="flex-grow">
                <base-list>
                    <base-list-item>
                        <template #icon>
                            <font-awesome-icon :icon="['fas', 'circle-chevron-right']" />
                        </template>
                        <template #title>
                            <base-timer v-slot="{minutes, seconds}" :time="seconds" auto-start countdown @end="() => seconds = 0">
                                {{ trans.get('recall-customer.modals.time_left_to_recall', {time: (minutes > 0 ? minutes + 'min' : '') + seconds}) }}
                            </base-timer>
                        </template>
                    </base-list-item>
                </base-list>
            </div>

            <div class="grid grid-cols-1 mt-8">
                <base-button :loading="loading" block @click="recallCustomer">
                    {{ trans.get('recall-customer.modals.i_restart_the_call') }}
                </base-button>
            </div>
        </div>
    </base-modal>
</template>

<script>
import ModalHeading from './elements/ModalHeading';
import BaseButton from '../elements/BaseButton';
import ModalParagraph from './elements/ModalParagraph';
import BaseLink from '../elements/BaseLink';
import BaseListItem from '../elements/BaseListItem';
import BaseList from '../elements/BaseList';
import BaseTimer from '../elements/BaseTimer';
import {mapGetters, mapMutations} from 'vuex';
import axios from 'axios';
import UserMixin from '../user/UserMixin';

export default {
    mixins: [UserMixin],
    components: {BaseLink, ModalParagraph, BaseButton, ModalHeading, BaseListItem, BaseList, BaseTimer},
    computed: {
        ...mapGetters('modals', ['getParam']),
    },
    data()
    {
        return {
            customer: {nick_name: '', user_id: 0, segment: ''},
            seconds: 0,
            error: '',
            loading: false,
        };
    },
    methods: {
        ...mapMutations('modals', ['hide']),
        recallCustomer()
        {
            this.loading = true;
            axios.post('/ajax/recallCustomer.js', {user_id: this.customer.user_id}).then(response => {
                this.loading = false;

                if (response.data.status === 'success') {
                    this.hide();
                } else {
                    this.error = 'CUSTOMER_IS_BUSY';
                }
            }).catch(() => this.error = 'CUSTOMER_IS_BUSY');
        },
    },
    mounted()
    {
        window.history.pushState('data', '', '/');
    },
    beforeMount()
    {
        this.customer = this.getParam['recall-customer']['customer'];
        this.seconds = this.getParam['recall-customer']['seconds'] * 1000;
        this.error = this.getParam['recall-customer']['error'];
    },
};
</script>
