<template>
    <development>
        <div>
            <development-heading>Checkbox</development-heading>
            <base-checkbox>Checkbox</base-checkbox>

            <development-heading :level="2">Props :</development-heading>
            <ul>
                <li><strong>id</strong>: String</li>
                <li><strong>color</strong>: String - <em>primary (default) / secondary / available / busy / neutral /
                    danger / prime / whatsapp</em></li>
                <li><strong>name</strong>: String</li>
                <li><strong>disabled</strong>: Boolean</li>
            </ul>

            <development-heading>Colors</development-heading>
            <development-paragraph><strong>color</strong> : primary (default) / secondary / available / busy / offline /
                neutral / danger / prime / facebook / whatsapp
            </development-paragraph>
            <base-checkbox color="primary" block :value="true">Checkbox primary</base-checkbox>
            <base-checkbox color="secondary" block :value="true">Checkbox secondary</base-checkbox>
            <base-checkbox color="available" block :value="true">Checkbox available</base-checkbox>
            <base-checkbox color="busy" block :value="true">Checkbox busy</base-checkbox>
            <base-checkbox color="offline" block :value="true">Checkbox offline</base-checkbox>
            <base-checkbox color="neutral" block :value="true">Checkbox neutral</base-checkbox>
            <base-checkbox color="danger" block :value="true">Checkbox danger</base-checkbox>
            <base-checkbox color="prime" block :value="true">Checkbox prime</base-checkbox>
            <base-checkbox color="facebook" block :value="true">Checkbox facebook</base-checkbox>
            <base-checkbox color="whatsapp" block :value="true">Checkbox whatsapp</base-checkbox>

            <development-heading>Disabled</development-heading>
            <base-checkbox disabled>Checkbox</base-checkbox>
            <base-checkbox :value="true" disabled>Checkbox</base-checkbox>

            <development-heading>Block</development-heading>
            <base-checkbox block>Checkbox 1</base-checkbox>
            <base-checkbox block>Checkbox 2</base-checkbox>
            <base-checkbox block>Checkbox 3</base-checkbox>

            <development-heading>Error</development-heading>
            <base-checkbox error="This is an error">Checkbox</base-checkbox>

            <development-heading>v-model</development-heading>
            <base-checkbox v-model="checkbox">Checkbox : {{ String(checkbox) }}</base-checkbox>

            <development-heading>Long content</development-heading>
            <base-checkbox>Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Sed porttitor lectus
                nibh. Nulla quis lorem ut libero malesuada feugiat. Vestibulum ac diam sit amet quam vehicula elementum
                sed sit amet dui. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Curabitur aliquet quam
                id dui posuere blandit. Pellentesque in ipsum id orci porta dapibus. Sed porttitor lectus nibh. Cras
                ultricies ligula sed magna dictum porta. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a.
            </base-checkbox>
            <base-checkbox
                error="Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Sed porttitor lectus nibh. Nulla quis lorem ut libero malesuada feugiat. Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Curabitur aliquet quam id dui posuere blandit. Pellentesque in ipsum id orci porta dapibus. Sed porttitor lectus nibh. Cras ultricies ligula sed magna dictum porta. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a."
            >Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Sed porttitor lectus
                nibh. Nulla quis lorem ut libero malesuada feugiat. Vestibulum ac diam sit amet quam vehicula elementum
                sed sit amet dui. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Curabitur aliquet quam
                id dui posuere blandit. Pellentesque in ipsum id orci porta dapibus. Sed porttitor lectus nibh. Cras
                ultricies ligula sed magna dictum porta. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a.
            </base-checkbox>
        </div>
    </development>
</template>

<script>
import Development from "./Development";
import DevelopmentHeading from "./elements/DevelopmentHeading";
import DevelopmentParagraph from "./elements/DevelopmentParagraph";
import BaseCheckbox from "../elements/BaseCheckbox";

export default {
    components: {BaseCheckbox, DevelopmentParagraph, DevelopmentHeading, Development},
    data() {
        return {
            checkbox: true,
        }
    },
}
</script>
