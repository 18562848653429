<template>
    <div>
        <div class="home-banner-glitch-container flex md:hidden">
            <div v-for="n in 6"
                 class="home-banner-glitch-image"
                 :style="backgroundStyle">
            </div>
        </div>

        <div class="home-banner-glitch-container hidden md:flex lg:hidden">
            <div v-for="n in 6"
                 class="home-banner-glitch-image"
                 :style="backgroundMdStyle">
            </div>
        </div>

        <div class="home-banner-glitch-container hidden lg:flex">
            <div v-for="n in 6"
                 class="home-banner-glitch-image"
                 :style="backgroundLgStyle">
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.home-banner-glitch-container {
    @apply absolute top-0 bottom-0 left-0 right-0 z-0 justify-center items-center overflow-hidden flex-col;
    flex: 1;
}

.home-banner-glitch-image {
    @apply absolute top-0 bottom-0 left-0 right-0 z-0 bg-center bg-cover;
}

.home-banner-glitch-image:nth-child(1) {
    opacity: 1;
}

.home-banner-glitch-image:nth-child(n+2) {
    opacity: 0;
}

.home-banner-glitch-image:nth-child(n+1) {
    opacity: 1;
}

.home-banner-glitch-image:nth-child(2) {
    transform: translate3d(1.1, 0, 0);
    animation: glitch-horizontal 3s infinite linear alternate;
}

.home-banner-glitch-image:nth-child(3) {
    transform: translate3d(-1.1, 0, 0);
    animation: glitch-horizontal-2 2s infinite linear alternate;
}

.home-banner-glitch-image:nth-child(4) {
    transform: translate3d(-1.1, 0, 0);
    animation: glitch-vertical 1s infinite linear alternate;
}

.home-banner-glitch-image:nth-child(5) {
    transform: translate3d(0, 1.1, 0) scale3d(-1, -1, 1);
    animation: glitch-flash 5s steps(1, end) infinite;
}

.home-banner-glitch-image:nth-child(6) {
    transform: translate3d(0, 1.3, 0) scale3d(-1, -1, 1);
    animation: glitch-flash-2 5s steps(1, end) infinite;
}

@keyframes glitch-horizontal {
    0% {
        -webkit-clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%);
        clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%);
    }
    10% {
        -webkit-clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
        clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
    }
    20% {
        -webkit-clip-path: polygon(0 10%, 100% 10%, 100% 20%, 0 20%);
        clip-path: polygon(0 10%, 100% 10%, 100% 20%, 0 20%);
    }
    30% {
        -webkit-clip-path: polygon(0 1%, 100% 1%, 100% 2%, 0 2%);
        clip-path: polygon(0 1%, 100% 1%, 100% 2%, 0 2%);
    }
    40% {
        -webkit-clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
        clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
    }
    50% {
        -webkit-clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
        clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
    }
    60% {
        -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
        clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
    }
    70% {
        -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
        clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
    }
    80% {
        -webkit-clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
        clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
    }
    90% {
        -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
        clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
    }
    100% {
        -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
        clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
    }
}

@keyframes glitch-horizontal-2 {
    0% {
        opacity: 1;
        transform: translate3d(-10px, 0, 0);
        -webkit-clip-path: polygon(0 25%, 100% 25%, 100% 30%, 0 30%);
        clip-path: polygon(0 25%, 100% 25%, 100% 30%, 0 30%);
    }
    3% {
        -webkit-clip-path: polygon(0 3%, 100% 3%, 100% 3%, 0 3%);
        clip-path: polygon(0 3%, 100% 3%, 100% 3%, 0 3%);
    }
    5% {
        -webkit-clip-path: polygon(0 5%, 100% 5%, 100% 20%, 0 20%);
        clip-path: polygon(0 5%, 100% 5%, 100% 20%, 0 20%);
    }
    7% {
        -webkit-clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
        clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
    }
    9% {
        -webkit-clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%);
        clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%);
    }
    11% {
        -webkit-clip-path: polygon(0 52%, 100% 52%, 100% 59%, 0 59%);
        clip-path: polygon(0 52%, 100% 52%, 100% 59%, 0 59%);
    }
    13% {
        -webkit-clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
        clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
    }
    15% {
        -webkit-clip-path: polygon(0 75%, 100% 75%, 100% 75%, 0 75%);
        clip-path: polygon(0 75%, 100% 75%, 100% 75%, 0 75%);
    }
    17% {
        -webkit-clip-path: polygon(0 65%, 100% 65%, 100% 40%, 0 40%);
        clip-path: polygon(0 65%, 100% 65%, 100% 40%, 0 40%);
    }
    19% {
        -webkit-clip-path: polygon(0 45%, 100% 45%, 100% 50%, 0 50%);
        clip-path: polygon(0 45%, 100% 45%, 100% 50%, 0 50%);
    }
    20% {
        -webkit-clip-path: polygon(0 14%, 100% 14%, 100% 33%, 0 33%);
        clip-path: polygon(0 14%, 100% 14%, 100% 33%, 0 33%);
    }
    21.9% {
        opacity: 1;
        transform: translate3d(-10px, 0, 0);
    }
    22%, 100% {
        opacity: 0;
        transform: translate3d(0, 0, 0);
        -webkit-clip-path: polygon(0 0, 0 0, 0 0, 0 0);
        clip-path: polygon(0 0, 0 0, 0 0, 0 0);
    }
}

@keyframes glitch-vertical {
    0% {
        opacity: 1;
        transform: translate3d(0, -5px, 0) scale3d(-1, -1, 1);
        -webkit-clip-path: polygon(0 1%, 100% 1%, 100% 3%, 0 3%);
        clip-path: polygon(0 1%, 100% 1%, 100% 3%, 0 3%);
    }
    1.5% {
        -webkit-clip-path: polygon(0 10%, 100% 10%, 100% 9%, 0 9%);
        clip-path: polygon(0 10%, 100% 10%, 100% 9%, 0 9%);
    }
    2% {
        -webkit-clip-path: polygon(0 5%, 100% 5%, 100% 6%, 0 6%);
        clip-path: polygon(0 5%, 100% 5%, 100% 6%, 0 6%);
    }
    2.5% {
        -webkit-clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
        clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
    }
    3% {
        -webkit-clip-path: polygon(0 10%, 100% 10%, 100% 10%, 0 10%);
        clip-path: polygon(0 10%, 100% 10%, 100% 10%, 0 10%);
    }
    5% {
        -webkit-clip-path: polygon(0 30%, 100% 30%, 100% 25%, 0 25%);
        clip-path: polygon(0 30%, 100% 30%, 100% 25%, 0 25%);
    }
    5.5% {
        -webkit-clip-path: polygon(0 15%, 100% 15%, 100% 16%, 0 16%);
        clip-path: polygon(0 15%, 100% 15%, 100% 16%, 0 16%);
    }
    7% {
        -webkit-clip-path: polygon(0 40%, 100% 40%, 100% 39%, 0 39%);
        clip-path: polygon(0 40%, 100% 40%, 100% 39%, 0 39%);
    }
    8% {
        -webkit-clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
        clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
    }
    9% {
        -webkit-clip-path: polygon(0 60%, 100% 60%, 100% 55%, 0 55%);
        clip-path: polygon(0 60%, 100% 60%, 100% 55%, 0 55%);
    }
    10.5% {
        -webkit-clip-path: polygon(0 30%, 100% 30%, 100% 31%, 0 31%);
        clip-path: polygon(0 30%, 100% 30%, 100% 31%, 0 31%);
    }
    11% {
        -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 69%, 0 69%);
        clip-path: polygon(0 70%, 100% 70%, 100% 69%, 0 69%);
    }
    13% {
        -webkit-clip-path: polygon(0 40%, 100% 40%, 100% 41%, 0 41%);
        clip-path: polygon(0 40%, 100% 40%, 100% 41%, 0 41%);
    }
    14% {
        -webkit-clip-path: polygon(0 80%, 100% 80%, 100% 75%, 0 75%);
        clip-path: polygon(0 80%, 100% 80%, 100% 75%, 0 75%);
    }
    14.5% {
        -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 51%, 0 51%);
        clip-path: polygon(0 50%, 100% 50%, 100% 51%, 0 51%);
    }
    15% {
        -webkit-clip-path: polygon(0 90%, 100% 90%, 100% 90%, 0 90%);
        clip-path: polygon(0 90%, 100% 90%, 100% 90%, 0 90%);
    }
    16% {
        -webkit-clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
        clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
    }
    18% {
        -webkit-clip-path: polygon(0 100%, 100% 100%, 100% 99%, 0 99%);
        clip-path: polygon(0 100%, 100% 100%, 100% 99%, 0 99%);
    }
    20% {
        -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 71%, 0 71%);
        clip-path: polygon(0 70%, 100% 70%, 100% 71%, 0 71%);
    }
    21.9% {
        opacity: 1;
        transform: translate3d(0, -10px, 0) scale3d(-1, -1, 1);
    }
    22%, 100% {
        opacity: 0;
        transform: translate3d(0, 0, 0);
        -webkit-clip-path: polygon(0 0, 0 0, 0 0, 0 0);
        clip-path: polygon(0 0, 0 0, 0 0, 0 0);
    }
}

@keyframes glitch-flash {
    0% {
        opacity: 0.2;
        transform: translate3d(10px, 5px, 0);
    }
    3%, 100% {
        opacity: 0;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes glitch-flash-2 {
    0% {
        opacity: 0.2;
        transform: translate3d(5px, 10px, 0);
    }
    2%, 100% {
        opacity: 0;
        transform: translate3d(0, 0, 0);
    }
}
</style>

<script>
export default {
    name: "HomeBannerGlitch",
    props: {
        lgImage: String,
        mdImage: String,
        image: String,
    },
    computed: {
        backgroundStyle() {
            return {
                'background-image': 'url(' + this.image + ')'
            }
        },
        backgroundMdStyle() {
            return {
                'background-image': 'url(' + this.mdImage + ')'
            }
        },
        backgroundLgStyle() {
            return {
                'background-image': 'url(' + this.lgImage + ')'
            }
        },
    },
}
</script>
