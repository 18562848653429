<template>
    <div class="reset-this home-top-sellers-list-container">
        <div class="home-top-sellers-list" ref="home-top-sellers-list">
            <offer-card-loader v-if="loading"
                               v-for="n in cardsPerPage"
                               class="home-top-sellers-list-card"
                               ref="offer-card-loader"
                               :key="'offer-card-loader-' + n"/>

            <offer-card v-for="(offer, index) in offers"
                        class="home-top-sellers-list-card"
                        :key="offer.id"
                        :number="index+1"
                        :offer="offer"
                        :user="getLoggedUserInfos"
                        :user_credits="getCredits"
                        :is_prime_enabled="config.get('prime.enabled')"
                        :is_free_chat_enabled_for_prospect="config.get('chat.free_chat_enabled_for_prospect')"
                        offer_list_type="top_sellers"
            />
        </div>
    </div>
</template>


<style lang="scss">
.home-top-sellers-list-container {
    @apply px-4 sm:px-2;

    .home-top-sellers-list {
        @apply grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4;

        .home-top-sellers-list-card:nth-child(n+4) {
            @apply lg:hidden;
        }
    }
}
</style>

<script>
import OfferCard from "../offer/OfferCard";
import OfferListMixin from "../offer/OfferListMixin";
import {mapGetters} from "vuex";
import {ziggy_route} from "../ZiggyMixin";
import OfferCardSmallLoader from "../offer/OfferCardSmallLoader.vue";
import OfferCardLoader from "../offer/OfferCardLoader.vue";

export default {
    name: "HomeTopSellersList",
    mixins: [OfferListMixin],
    components: {OfferCardLoader, OfferCardSmallLoader, OfferCard},
    props: {
        categoryId: Number,
    },
    computed: {
        ...mapGetters('user', ['getLoggedUserInfos']),
        ...mapGetters('payment', ['getCredits']),
        cardsPerPage() {
            return 4;
        },
    },
    mounted() {
        this.initList();
        this.initStatusChangedListener();
    },
    methods: {
        initList() {
            this.offers = [];
            this.loading = false;
            this.fetchOffers();
        },
        fetchOffers() {
            if (this.loading) {
                return false;
            }

            this.loading = true;

            let params = {
                category_id: this.categoryId,
                offer_list_type: 'top_sellers',
                count: 4,
                filters: [],
            };

            if (this.axiosCancelSource !== null) {
                this.axiosCancelSource.cancel('Cancel load.');
            }

            this.axiosCancelSource = axios.CancelToken.source();

            axios.get(ziggy_route('offer-list.get', params), {
                cancelToken: this.axiosCancelSource.token,
            }).then(response => {
                response.data.data.forEach((offer) => {
                    this.createOrUpdateOffer(offer);
                });
                this.loading = false;
                this.offerListReady = true;

            }).catch(error => {
                console.error(error);
            });
        },
    },
    data() {
        return {
            axiosCancelSource: null,
            loading: false,
        }
    }
}
</script>
