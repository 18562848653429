<template>
    <base-modal small>
        <modal-heading>
            {{ trans.get('chat.modals.report_done') }}
        </modal-heading>

        <modal-paragraph>
            <p class="font-smaller">{{ trans.get('chat.modals.report_master_care_will_contact_you') }}</p>
        </modal-paragraph>

        <div class="grid grid-cols-1 gap-4 mt-5">
            <base-button autofocus @click="closeModal">
                {{ trans.get('chat.modals.back_to_chat') }}
            </base-button>
        </div>
    </base-modal>
</template>

<script>
import ModalParagraph from "./elements/ModalParagraph";
import BaseButton from "../elements/BaseButton";
import ModalHeading from "./elements/ModalHeading";
import {mapActions} from "vuex";

export default {
    name: "ModalChatReportProblem",
    components: {ModalParagraph, BaseButton, ModalHeading},
    methods: {
        ...mapActions('modals', ['closeModal'])
    },
}
</script>

<style scoped>

</style>
