<template>
    <div class="home-banner-hearts">
        <div class="home-banner-hearts-heart" v-for="i in 50">
            <svg viewBox="0 0 32 29.6">
                <path d="M23.6,0c-3.4,0-6.3,2.7-7.6,5.6C14.7,2.7,11.8,0,8.4,0C3.8,0,0,3.8,0,8.4c0,9.4,9.5,11.9,16,21.2
	c6.1-9.3,16-12.1,16-21.2C32,3.8,28.2,0,23.6,0z"/>
            </svg>
        </div>
    </div>
</template>

<style lang="scss">

.home-banner-hearts-heart {
    --heart-size: 1vw;
    width: var(--heart-size);
    height: var(--heart-size);
    position: absolute;
    top: -5vh;

    svg {
        fill: #ff8ba0;
        animation: heart-pulse 1s ease infinite;
    }
}

.home-banner-hearts-heart:nth-child(6n) svg {
    filter: blur(1px);
}

@for $i from 1 through 50 {
    .home-banner-hearts-heart:nth-child(#{$i}) {
        @media (min-width: 640px) {
            --heart-size: #{random(5) * 0.3}vw;
        }

        @media (min-width: 1024px) {
            --heart-size: #{random(5) * 0.2}vw;
        }

        --heart-size: #{random(5) * 0.7}vw;
        --left-ini: #{random(20) - 10}vw;
        --left-end: #{random(20) - 10}vw;
        left: #{random(100)}vw;
        animation: heart-translation #{5 + random(10)}s linear infinite;
        animation-delay: -#{random(10)}s;
    }
}

@keyframes heart-translation {
    0% {
        transform: translate3d(var(--left-ini), 0, 0);
    }
    100% {
        transform: translate3d(var(--left-end), 110vh, 0);
    }
}

@keyframes heart-pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.3);
    }
    100% {
        transform: scale(1);
    }
}
</style>

<script>
export default {
    name: "HomeBannerHearts",
}
</script>
