<template>
    <div class="offer-card-medium">
        <div class="flex items-center w-full">
            <div class="flex-none pr-3">
                <base-avatar color="neutral-secondary" skeleton-loader/>
            </div>

            <div class="flex-grow flex flex-col min-w-0">
                <div class="flex-grow flex min-w-0">
                    <div class="flex-none w-10/12 bg-neutral-300 animate-pulse rounded">
                        <p>&nbsp;</p>
                    </div>
                </div>

                <div class="h-1">&nbsp;</div>

                <div class="flex-grow flex min-w-0">
                    <div class="flex-none w-4/12 bg-neutral-300 animate-pulse rounded">
                        <p>&nbsp;</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="offer-card-actions">
            <base-button class="offer-card-action h-9 w-14" color="neutral-secondary" small skeleton-loader/>
            <base-button class=" offer-card-action h-9 w-14" color="neutral-secondary" small skeleton-loader/>
        </div>
    </div>
</template>

<script>
import BaseAvatar from "../elements/BaseAvatar.vue";
import BaseButton from "../elements/BaseButton.vue";
import BaseBadge from "../elements/BaseBadge.vue";
import OfferCardActions from "./OfferCardActions.vue";

export default {
    name: 'OfferCardMediumLoader',
    components: {OfferCardActions, BaseBadge, BaseButton, BaseAvatar}
}
</script>
