<template>
    <div v-if="this.isConversationOpen">
        <div class="conversation-input">
            <div v-if="!this.isConversationInputFocused && !image.url && canSendHearts" class="conversation-input-icon">
                <base-button icon large color="danger" @click="sendHeart">
                    <font-awesome-icon class="pointer-events-none" :icon="['fas', 'heart']"/>
                </base-button>
            </div>
            <div v-if="!this.isConversationInputFocused && canSendStickers" class="conversation-input-icon">
                <base-button icon large @click="openStickerMenu">
                    <font-awesome-icon class="pointer-events-none" :icon="['fas', 'gift']"/>
                </base-button>
            </div>
            <div :class="conversationInputInputContainerClass">
                <div v-if="image.url" class="conversation-input-image-preview-container">
                    <div class="relative inline-block">
                        <base-button fab small color="light" class="conversation-input-image-preview-delete-icon"
                                     @click="deleteImage">
                            <font-awesome-icon :icon="['far', 'xmark']" size="lg"/>
                        </base-button>
                        <img :src="image.url" class="conversation-input-image-preview"/>
                    </div>
                </div>
                <base-textarea ref="basetextarea"
                               v-model="content"
                               outlined
                               error-top
                               :max-length="maxLength"
                               :rows="rows"
                               :max-rows="3"
                               :disabled="disabled"
                               :icon-right="this.gifEnabled"
                               :error="errorSendMessage"
                               :placeholder="trans.get(disabled ? 'generic.conversation_input_placeholder_disabled' : 'generic.conversation_input_placeholder')"
                               @keydown="keydown"
                               @input="isTyping"
                               @focus="focus"
                               @blur="blur">
                    <template v-if="this.gifEnabled" #icon><span class="conversation-input-gif-icon">GIF</span>
                    </template>
                </base-textarea>
            </div>
            <div class="grid grid-cols-2">
                <div v-if="photoEnabled" class="conversation-input-icon">
                    <base-button icon large :disabled="!canSendFiles" color="neutral" @mousedown="openInputFile">
                        <font-awesome-icon class="pointer-events-none" :icon="['fal', 'camera']"/>
                    </base-button>

                    <input ref="image_file_input"
                           type="file"
                           accept="image/png, image/jpeg, image/jpg, image/gif"
                           @change="onPhotoSelect"
                           class="conversation-input-image-input"/>
                </div>
                <div class="conversation-input-icon">
                    <base-button v-if="displayAudioIcon" icon large color="primary" :disabled="!canSendFiles"
                                 @mousedown="sendAudioMessage">
                        <font-awesome-icon class="pointer-events-none" :icon="['fal', 'microphone-lines']"/>
                    </base-button>

                    <base-button v-if="displaySendIcon" :disabled="!hasContent" icon large @mousedown="sendMessage"
                                 color="neutral">
                        <font-awesome-icon class="pointer-events-none" :icon="['fal', 'paper-plane']"/>
                    </base-button>
                </div>
                <div v-show="this.isConversationInputFocused && !image.url && canSendHearts" class="conversation-input-icon">
                    <base-button icon large color="danger" @mousedown="sendHeartThenFocusInput">
                        <font-awesome-icon class="pointer-events-none" :icon="['fas', 'heart']"/>
                    </base-button>
                </div>
                <div v-show="this.isConversationInputFocused && canSendStickers" class="conversation-input-icon">
                    <base-button icon large @mousedown="openStickerMenu">
                        <font-awesome-icon class="pointer-events-none" :icon="['fas', 'gift']"/>
                    </base-button>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.conversation-input {
    @apply w-full h-full flex;
}

.conversation-input-icon {
    @apply flex-none;
}

.conversation-input-input-container {
    @apply flex-grow relative self-center py-1;
}

.conversation-input-image-preview-container {
    @apply border border-b-0 border-neutral-400 rounded-md rounded-b-none p-2 text-primary;
}

.conversation-input-image-preview-delete-icon {
    @apply absolute flex top-2 right-2 justify-center items-center;
}

.conversation-input-image-preview {
    @apply block h-auto max-h-60 w-auto md:max-w-sm rounded-lg;
}

.conversation-input-gif-icon {
    @apply bg-neutral-400 rounded-md text-sm text-white px-2 py-1 mr-2 cursor-pointer;
}

.conversation-input-image-input {
    @apply hidden;
}

</style>

<script>
import BaseButton from '../elements/BaseButton';
import BaseMenu from "../elements/BaseMenu.vue";
import BaseTextarea from '../elements/BaseTextarea';
import ChatMixin from './ChatMixin';
import MediaDevicesManager from '../media-devices/MediaDevicesMixin';
import MessageMixin from "./MessageMixin";
import ValidationLang from '../ValidationLang';
import requests from './requests';
import {mapActions, mapGetters} from 'vuex';

export default {
    name: 'ConversationInput',
    components: {BaseMenu, BaseButton, BaseTextarea},
    mixins: [ChatMixin, MessageMixin, MediaDevicesManager],
    props: {
        photoEnabled: {
            type: Boolean,
            default: false,
        },
        audioEnabled: {
            type: Boolean,
            default: false
        },
        gifEnabled: {
            type: Boolean,
            default: false
        },
        minLength: {
            type: Number,
            default: 1
        },
        maxLength: {
            type: Number,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            content: '',
            image: {
                file: null,
                url: null,
            },
            IsTypingSent: false,
            errorSendMessage: "",
            rows: 1,
        }
    },
    computed: {
        ...mapGetters('chat', [
            'canSendHearts',
            'canSendStickers',
            'getConversation',
            'getConversationMessages',
            'getOfferIdFromParticipants',
            'getSelectedParticipants',
            'iAmMaster',
            'isChatBotAnswerMode',
            'isChatSessionAlmostOver',
            'isConversationOpen',
            'isFollowUpMode',
            'isLiveConversation',
            'isLiveConversationInProgress',
            'isLiveConversationWaiting',
            'isConversationInputFocused'
        ]),
        ...mapGetters('modals', ['isShown']),
        ...mapGetters('media_devices', ['getUserMicrophonePermissionState']),
        displayInput() {
            return this.getSelectedParticipants !== undefined && this.getSelectedParticipants !== null;
        },
        displayAudioIcon() {
            return this.audioEnabled && !this.hasContent;
        },
        displaySendIcon() {
            return !this.displayAudioIcon || this.hasContent;
        },
        messageType() {
            return (this.image.url !== null) ? "image" : "text";
        },
        hasContent() {
            return !this.disabled && (this.content.trim().length > 0 || this.image.url !== null);
        },
        canSendFiles() {
            return !this.disabled && !this.isFollowUpMode;
        },
        conversationInputInputContainerClass() {
            return {
                "conversation-input-input-container": true,
                'pl-1': this.isConversationInputFocused,
                'pl-2': !this.isConversationInputFocused && !this.canSendHearts && !this.canSendStickers,
            };
        },
    },
    methods: {
        ...mapActions('chat', [
            'addMessageToConversation',
            'handlingChatStoppedForClientEventMissing',
            'handlingChatStoppedForMasterEventMissing',
            'liveChatStopped',
            'removeFollowUpMode',
            'sendHeart',
            'sendIsTyping',
            'startLiveChatProcess',
            'toggleStickersMenu',
            'setIsConversationInputFocused'
        ]),
        ...mapActions('media_devices', ['setUserMicrophonePermissionState', 'startAudioRecord']),
        ...mapActions('modals', ['closeModal', 'openModal', 'openModalMessage']),
        ...mapGetters('chat', ['isAddFundAlertDisplayed', 'isContinueWithCreditAlertDisplayed']),
        keydown(e) {
            this.resetErrorMessages();

            const keyCode = e.which || e.keyCode;
            if (keyCode === 13 && !e.shiftKey) {
                e.preventDefault();
                this.sendMessage();
            }
        },
        startLiveChatProcessIfRequired() {
            if (!this.iAmMaster && !(this.isLiveConversationInProgress || this.isFollowUpMode || this.isChatBotAnswerMode)) {
                this.startLiveChatProcess({offerId: this.getOfferIdFromParticipants(this.getSelectedParticipants)});
                return true;
            }

            return false;
        },
        focus(e) {
            if (this.startLiveChatProcessIfRequired()) {
                e.target.blur();
            } else {
                this.setIsConversationInputFocused(true);
                this.setRows(4);
            }
        },
        blur() {
            this.setIsConversationInputFocused(false);
            this.setRows(1);
        },
        setRows(rows) {
            if (this.rows !== rows) {
                this.rows = rows;
                this.$nextTick(() => {
                    this.$refs.basetextarea.updateInputSize();
                });
            }
        },
        isTyping() {
            if (!this.IsTypingSent) {
                this.IsTypingSent = true;
                this.sendIsTyping('text');

                setTimeout(() => {
                    this.IsTypingSent = false;
                }, 2000);
            }
        },
        resetErrorMessages() {
            this.errorSendMessage = '';
        },
        openInputFile() {
            if (!this.startLiveChatProcessIfRequired()) {
                this.$refs.image_file_input.click();
            }
        },
        deleteImage() {
            this.image.file = null;
            this.image.url = null;
            this.$refs.image_file_input.value = null;
            if (this.$refs.basetextarea !== undefined) {
                this.$refs.basetextarea.$refs.input_container.style.borderTop = "";
                this.$refs.basetextarea.$refs.input_container.style.borderTopLeftRadius = "";
                this.$refs.basetextarea.$refs.input_container.style.borderTopRightRadius = "";
            }
        },
        onPhotoSelect(e) {
            const file = e.target.files[0];
            this.image.file = file;
            this.image.url = URL.createObjectURL(file);
            this.resetErrorMessages();

            this.$refs.basetextarea.$refs.input_container.style.borderTop = "none";
            this.$refs.basetextarea.$refs.input_container.style.borderTopLeftRadius = "0";
            this.$refs.basetextarea.$refs.input_container.style.borderTopRightRadius = "0";
        },
        emptyAllFields() {
            this.content = '';
            this.$refs.basetextarea.value = '';
            this.deleteImage();
            this.$refs.basetextarea.updateInputSize();
        },
        getIndexLastMessageByMe() {
            const me = this.me();
            const participantIsMe = function (message) {
                return message.id === null && message.participant.id === me.id && !message.error;
            };
            return this.getConversationMessages(this.getSelectedParticipants).slice().findIndex(participantIsMe);
        },
        sendMessageErrorHandling(errorResponse) {
            if (errorResponse.response) {
                if (errorResponse.response.data.errors) {
                    let errorsInResponse = errorResponse.response.data.errors;
                    let validationLang = new ValidationLang();
                    let validationErrorsMap = validationLang.getMessageTranslated(errorsInResponse);
                    for (let value of validationErrorsMap.values()) {
                        this.errorSendMessage += value + '<br/>';
                    }
                } else {
                    this.errorSendMessage = errorResponse.response.data.message;
                }
            } else if (errorResponse.request) {
                this.errorSendMessage = errorResponse.toString();
            } else {
                this.errorSendMessage = errorResponse.message;
            }
        },
        sendHeartThenFocusInput(event) {
            this.sendHeart();
            this.$refs.basetextarea.focusInput();

            if (event !== undefined) {
                event.preventDefault();
            }
        },
        sendMessage(event) {
            if (this.hasContent) {
                this.resetErrorMessages();
                if (this.minLength !== null && this.image.url === null && this.content.length < this.minLength) {
                    this.errorSendMessage = this.trans.get('validation.gt.string', {
                        attribute: '',
                        value: this.minLength
                    });
                } else {
                    let message = this.createMessage();
                    message.content.text = this.content;
                    message.content.url = this.image.url;
                    message.content.file = this.image.file;
                    message.participant = this.me();
                    message.type = this.messageType;

                    this.emptyAllFields();

                    // CHAT BOT ANSWER MODE
                    if (this.isChatBotAnswerMode) {
                        requests.answerChatBotQuestion(message).then((response) => {
                            this.sendMessageSucceed = true;
                            message.id = response.data.data.id;
                            message.read = false;
                        }).catch((errorResponse) => {
                            this.messageNotSent(message);
                            this.sendMessageErrorHandling(errorResponse);
                        });
                    } else if (this.isFollowUpMode) {
                        this.addMessageToConversation({
                            conversation: this.getConversation(this.getSelectedParticipants),
                            message: message,
                        });

                        requests.sendFollowUpMessage(message).then((response) => {
                            if (response.data.data && response.data.data.id) {
                                message.id = response.data.data.id;
                                message.content.text = response.data.data.content.text;
                                message.read = false;
                            }
                            this.removeFollowUpMode();
                        }).catch((errorResponse) => {
                            this.messageNotSent(message);
                            this.sendMessageErrorHandling(errorResponse);
                        });
                    } else {
                        this.addMessageToConversation({
                            conversation: this.getConversation(this.getSelectedParticipants),
                            message: message,
                        });

                        requests.sendMessage(message, null, this.sendMessageErrorHandling);
                    }
                }

                this.$refs.basetextarea.focusInput();

                if (event !== undefined) {
                    event.preventDefault();
                }
            }
        },
        displayAuthorizeMicrophoneModal() {
            this.openModal({
                name: globals.AUTHORIZE_MICROPHONE
            });
        },
        sendAudioMessage() {
            if (!this.startLiveChatProcessIfRequired()) {
                this.getConnectedDevices('audioinput')
                    .then(microphones => {
                        if (microphones.length === 0) {
                            throw new this.DeviceNotFoundError();
                        }

                        // CHECK IF LABEL NOT EMPTY ==> PERMISSIONS GRANTED PERMANENTLY
                        microphones.every(mic => {
                            if (mic.label !== "") {
                                this.setUserMicrophonePermissionState('granted');
                                return false;
                            }
                        });
                    })
                    .then(() => {
                        if (this.getUserMicrophonePermissionState === 'prompt') {
                            this.displayAuthorizeMicrophoneModal();
                        }
                    })
                    .then(() => {
                        this.getBrowserDevicePermission(
                            {audio: true},
                            (stream) => {
                                this.setUserMicrophonePermissionState('granted');
                                if (this.isShown(globals.AUTHORIZE_MICROPHONE)) {
                                    this.closeModal();
                                }
                                this.startAudioRecord(stream);
                                if (this.getChatLiveSessionId()) {
                                    requests.setStartRecord(
                                        {sessionId: this.getChatLiveSessionId()},
                                        () => {
                                        },
                                        () => {
                                        }
                                    );
                                }
                            },
                            (error) => {
                                // ERROR OR USER DENIED MICROPHONE ACCESS
                                if (error.name === "NotAllowedError") {
                                    this.setUserMicrophonePermissionState('denied');
                                    this.openModalMessage({
                                        small: true,
                                        title: this.trans.get('chat.modals.cannot_access_microphone_title'),
                                        description: this.trans.get('chat.modals.cannot_access_microphone_text'),
                                        buttonText: this.trans.get('generic.i_understood'),
                                    });
                                } else {
                                    this.openModal({name: globals.GENERIC_ERROR});
                                }
                            });
                    })
                    .catch(error => {
                        if (error.name === "DeviceNotFound") {
                            this.openModalMessage({
                                small: true,
                                title: this.trans.get('chat.modals.no_microphone_detected_title'),
                                description: this.trans.get('chat.modals.no_microphone_detected_text'),
                                buttonText: this.trans.get('generic.i_understood'),
                            });
                        } else {
                            this.openModal({name: globals.GENERIC_ERROR});
                        }

                    });
            }
        },
        openStickerMenu() {
            this.toggleStickersMenu(true);
        },
    },
    created() {
        this.unwatchLiveChatRefillAlertDisplayed = this.$store.watch(this.isAddFundAlertDisplayed, () => {
            if (this.isAddFundAlertDisplayed() && this.isChatSessionAlmostOver) {
                this.$nextTick(() => {
                    this.$refs.basetextarea.blurInput();
                });
            }
        }, {immediate: true});

        this.unwatchLiveChatContinueWithCreditAlertDisplayed = this.$store.watch(this.isContinueWithCreditAlertDisplayed, () => {
            if (this.isContinueWithCreditAlertDisplayed() && this.isChatSessionAlmostOver) {
                this.$nextTick(() => {
                    this.$refs.basetextarea.blurInput();
                });
            }
        }, {immediate: true});
    },
    beforeDestroy() {
        this.unwatchLiveChatRefillAlertDisplayed();
        this.unwatchLiveChatContinueWithCreditAlertDisplayed();
    }
}

</script>
