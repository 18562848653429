<template>
    <base-modal disable-closing>
        <div>
            <div v-if="isLoading">
                <modal-heading>{{ trans.get('payment.please_wait') }}</modal-heading>
            </div>

            <div v-else-if="isAuthorized">
                <modal-heading>{{ trans.get('payment.payment_authorized') }}</modal-heading>
            </div>

            <div v-else>
                <modal-heading>{{ trans.get('errors.payment.generic') }}</modal-heading>
            </div>

            <div v-if="isLoading">
                <modal-paragraph>{{ trans.get('payment.loading') }}</modal-paragraph>
            </div>

            <div v-else-if="isAuthorized">
                <modal-paragraph>{{ trans.get('payment.payment_authorized') }}</modal-paragraph>

                <base-button autofocus block @click="closeModal">
                    {{ trans.get('generic.back_to_site') }}
                </base-button>
            </div>

            <div v-else>
                <modal-paragraph>{{ trans.get('errors.payment.generic') }}</modal-paragraph>

                <base-button autofocus block @click="closeModal">
                    {{ trans.get('generic.back_to_site') }}
                </base-button>
            </div>
            <base-timer auto-start :threshold-events="thresholdEvents"/>
        </div>
    </base-modal>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import ModalHeading from "../../modals/elements/ModalHeading";
import ModalParagraph from "../../modals/elements/ModalParagraph";
import BaseTimer from "../../elements/BaseTimer";

export default {
    name: "ModalPaymentProcessing",
    components: {BaseTimer, ModalParagraph, ModalHeading},
    computed: {
        ...mapGetters('payment', ['getPaymentStatus', 'getPaymentPrivateChannel']),
        isLoading() {
            return this.paymentStatus === "loading";
        },
        isAuthorized() {
            return this.paymentStatus === "authorized";
        },
        paymentStatus() {
            return this.getPaymentStatus;
        }
    },
    methods: {
        ...mapActions('user', ['updateUserCredits']),
        ...mapActions('modals', ['closeModal']),
        ...mapActions('payment', ['getPaymentStatusFromServer']),
        paymentProcessingTimedOut(canRetry) {
            if (this.isLoading) {
                this.getPaymentStatusFromServer({canRetry: canRetry});
                if (!canRetry) {
                    this.getPaymentPrivateChannel.stopListening('PaymentProcessed');
                }
            }
        },
        generateThresholdEvents(numTries) {
            let thresholdEvents = {};
            const baseTime = this.config.get('payment.apple_pay.time_left_to_the_closing_of_the_payment_processing_modal');

            for (let i = 1; i <= numTries; i++) {
                thresholdEvents[`try${i}`] = {
                    time: baseTime * i,
                    callback: () => this.paymentProcessingTimedOut(i !== numTries),
                };
            }

            return thresholdEvents;
        }
    },
    data() {
        return {
            thresholdEvents: this.generateThresholdEvents(3),
        };
    },
}
</script>
