<template>
    <base-modal :error="loadingError">
        <modal-heading>
            {{ trans.get('chat.modals.add_comment_title', {'master_name': master_name}) }}
        </modal-heading>

        <modal-paragraph>
            <p class="font-smaller">{{ trans.get('chat.modals.add_comment_text') }}</p>
        </modal-paragraph>

        <modal-paragraph>
            <base-text-input
                autofocus
                outlined
                v-model="client_nick_name"
                :error="errorClientNickName"
                :label="this.trans.get('chat.modals.add_comment_input_label')"
                :placeholder="this.trans.get('chat.modals.add_comment_input_label')"
            >
                <template #icon><font-awesome-icon :icon="['fal', 'circle-user']"/></template>
            </base-text-input>
        </modal-paragraph>

        <modal-paragraph>
            <span class="font-bold">{{ trans.get('generic.your_rating') }} : </span>
            <base-rating v-model="comment_rating"></base-rating>
        </modal-paragraph>

        <modal-paragraph>
            <base-textarea
                outlined
                v-model="comment_text"
                :error="errorCommentText"
                :label="this.trans.get('generic.your_comment')"
                :placeholder="this.trans.get('generic.your_comment')"
                :rows="5"
                icon-top>
                <template #icon><font-awesome-icon :icon="['fal', 'message-lines']"/></template>
            </base-textarea>
        </modal-paragraph>

        <div class="grid grid-cols-2 gap-4 mt-5">
            <base-button outlined @click="closeModal">
                {{ trans.get('generic.cancel') }}
            </base-button>
            <base-button @click="postComment">
                {{ trans.get('generic.validate') }}
            </base-button>
        </div>

    </base-modal>
</template>

<script>

import ModalHeading from "./elements/ModalHeading";
import {mapActions, mapGetters} from "vuex";
import BaseTextInput from "../elements/BaseTextInput";
import BaseRating from "../elements/BaseRating";
import ModalParagraph from "./elements/ModalParagraph";
import BaseTextarea from "../elements/BaseTextarea";
import BaseButton from "../elements/BaseButton";
import ValidationLang from '../ValidationLang';
import requests from "../chat/requests";

export default {
    name: "ModalChatAddComment",
    components: {BaseButton, BaseTextarea, ModalParagraph, BaseRating, ModalHeading, BaseTextInput},
    computed: {
        ...mapGetters('modals', ['getParam']),
    },
    methods: {
        ...mapActions('modals', ['openModalMessage', 'closeModal']),
        postComment() {
            this.errorCommentText = '';
            this.errorClientNickName = '';

            requests.addComment(
                {
                  chatSessionId: this.sessionId,
                  nickName: this.client_nick_name,
                  rating: this.comment_rating,
                  message: this.comment_text
                },
                () => {
                  // Open popup success
                  this.openModalMessage({
                      title: this.trans.get('chat.modals.add_comment_success_text'),
                      icon: ['fal', 'face-smile'],
                      buttonText: this.trans.get('chat.modals.continue_on_kang')
                  });
                },
                (errorResponse) => {
                  let errorsInResponse = errorResponse.response.data.errors;
                  let validationLang = new ValidationLang();
                  let validationErrorsMap = validationLang.getMessageTranslated(errorsInResponse);
                  this.errorCommentText = validationErrorsMap.get('message').join('<br/>');
                  this.errorClientNickName = validationErrorsMap.get('nickName').join('<br/>');
                }
            );
        }
    },
    data() {
        return {
            loadingError: '',
            master_name: '',
            client_nick_name: '',
            comment_rating: 5,
            comment_text: '',
            errorCommentText: '',
            errorClientNickName: '',
        };
    },
    mounted()
    {
        this.master_name = this.getParam['chat-add-comment']['master_name'];
        this.client_nick_name = this.getParam['chat-add-comment']['client_nick_name'];
        this.sessionId = this.getParam['chat-add-comment']['sessionId'];
    },

}
</script>

<style scoped>

</style>
