import {ziggy_route} from "../ZiggyMixin";

export default {
    namespaced: true,

    state: {
        basketDetails: null,
        nbItemsBasket: 0,
        urlAction: '',
    },

    mutations: {
        setBasketDetails: (state, basketDetails) => {
            state.basketDetails = basketDetails;
        },
        setNbItemsBasket: (state, nbItems) => {
            state.nbItemsBasket = nbItems;
        },
        setUrlAction: (state, urlAction) => {
            state.urlAction = urlAction;
        },
    },

    getters: {
        getBasketDetails: (state) => state.basketDetails,
        getNbItemsBasket: (state) => state.nbItemsBasket,
        getUrlAction: (state) => state.urlAction,
        isBasketEmpty: (state) => state.nbItemsBasket === 0,
    },

    actions: {
        updateBasket({commit}, params) {
            commit('setBasketDetails', params.basketDetails);
            commit('setNbItemsBasket', params.nbItems);
            commit('setUrlAction', params.urlAction);
        },
        fetchBasket({dispatch}) {
            axios.get(ziggy_route('digital.basket.get'))
                .then(response => {
                    dispatch('updateBasket', {
                        nbItems: parseInt(response.data.data.nbItemsBasket),
                        basketDetails: response.data.data.basketDetails,
                        urlAction: response.data.data.urlAction
                    });
                });
        },
        emptyBasket({dispatch}) {
            axios.post(ziggy_route('digital.basket.empty'))
                .then(response => {
                    if (response.data.status === 'success') {
                        dispatch('updateBasket', {
                            nbItems: 0,
                            basketDetails: null,
                            urlAction: ''
                        });
                    }
                });
        },
    }
}
