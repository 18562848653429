<template>
    <base-modal :small="small">
        <img :src="url"
             :alt="trans.get('generic.close')" @click="showGame"/>
        <div class="grid grid-cols-1 gap-4 mt-5">
            <base-button @click="closeAndRedirect">{{ trans.get('game.play') }}</base-button>
        </div>
    </base-modal>
</template>

<script>
import ModalHeading from "../../modals/elements/ModalHeading";
import BaseButton from "../../elements/BaseButton";
import {mapActions, mapGetters} from "vuex";
export default {
    name: "ModalGameFirstDay",
    components: {BaseButton, ModalHeading},
    computed: {
        ...mapGetters('modals', ['getParam']),
    },
    methods: {
        ...mapActions('modals', ['closeModal']),
        ...mapActions('game', ['showGame']),
        closeAndRedirect() {
            this.closeModal();
            this.showGame();
        }
    },
    data() {
        return {
            small: true,
            url: '',
        }
    },
    mounted() {
        this.url = this.getParam['game-first-day']['url'];
    },
}
</script>
