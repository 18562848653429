<template>
    <a :href="url">
        <img class="h-12 inline-block"
             :src="assetManager.getImageAsset(logo)"
             :alt="trans.get('generic.site_name')"/>
    </a>
</template>

<script>
export default {
    name: 'Logo',
    props: {
        type: {
            type: String
        },
        url: {
            type: String,
            default: "/",
            required: false
        }
    },
    computed: {
        logo() {
            switch (this.type) {
                case "prime":
                    return 'global/logo_prime.svg';
                case 'christmas':
                    return 'global/logo_christmas.png';
                default:
                    return 'global/logo.svg';
            }
        },
    },
}
</script>
