<template>
    <a :href="ziggy_route('prime.page')" class="hidden lg:block">
        <div class="flex flex-col justify-center text-left" style="height: 55px;">
            <span class="prime-title">{{ title }}</span>
            <span class="prime-subtitle">{{ subtitle }}</span>
        </div>
    </a>
</template>

<style lang="scss">
.prime-title {
    @apply text-xs text-header-top uppercase tracking-wider leading-none;
}

.prime-subtitle {
    @apply text-sm text-prime uppercase tracking-wide font-bold leading-none;
}
</style>

<script>
export default {
    name: 'Prime',
    props: {
        subscribed: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        title() {
            if (this.subscribed) {
                return this.trans.get('header.prime.my');
            }

            return this.trans.get('generic.site_name');
        },
        subtitle() {
            return this.trans.get('header.prime.prime');
        }
    }
}
</script>
