import { render, staticRenderFns } from "./BaseRadioInputBillingType.vue?vue&type=template&id=489a7654&scoped=true"
import script from "./BaseRadioInputBillingType.vue?vue&type=script&lang=js"
export * from "./BaseRadioInputBillingType.vue?vue&type=script&lang=js"
import style0 from "./BaseRadioInputBillingType.vue?vue&type=style&index=0&id=489a7654&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "489a7654",
  null
  
)

export default component.exports