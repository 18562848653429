import Vue from 'vue';

import {library} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon, FontAwesomeLayers} from '@fortawesome/vue-fontawesome';

// Free Solid icons
import {
    faBell as fasBell,
    faCamera as fasCamera,
    faCaretDown as fasCaretDown,
    faCaretUp as fasCaretUp,
    faCircleCheck as fasCircleCheck,
    faCircleChevronRight as fasCircleChevronRight,
    faCircleExclamation as fasCircleExclamation,
    faCircleInfo as fasCircleInfo,
    faEllipsisVertical as fasEllipsisVertical,
    faEnvelope as fasEnvelope,
    faEnvelopeOpenText as fasEnvelopeOpenText,
    faGem as fasGem,
    faGift as fasGift,
    faHeart as fasHeart,
    faLock as fasLock,
    faMessage as fasMessage,
    faNoteSticky as fasNoteSticky,
    faPaperPlane as fasPaperPlane,
    faPhone as fasPhone,
    faPhoneSquareAlt as fasPhoneSquareAlt,
    faShareNodes as fasShareNodes,
    faSpinner as fasSpinner,
    faSquare as fasSquare,
    faStar as fasStar,
    faTimesCircle as fasTimesCircle,
    faTriangleExclamation as fasTriangleExclamation,
    faTrophy as fasTrophy,
    faUser as fasUser,
    faUserCheck as fasUserCheck,
    faUserPlus as fasUserPlus,
    faUsers as fasUsers,
} from '@fortawesome/free-solid-svg-icons';

// Free brands icons
import {
    faApple as fabApple,
    faFacebookF as fabFacebookF,
    faInstagram as fabInstagram,
    faLinkedinIn as fabLinkedinIn,
    faPinterestP as fabPinterestP,
    faTiktok as fabTiktok,
    faTwitter as fabTwitter,
    faYoutube as fabYoutube,
} from '@fortawesome/free-brands-svg-icons';

// Pro Solid icons
import {
    faBasketShopping as fasBasketShopping,
    faChevronDown as fasChevronDown,
    faCircleHeart as fasCircleHeart,
    faCirclePhoneFlip as fasCirclePhoneFlip,
    faCirclePlus as fasCirclePlus,
    faCircleQuestion as fasCircleQuestion,
    faExclamation as fasExclamation,
    faFaceSmilePlus as fasFaceSmilePlus,
    faMessageLines as fasMessageLines,
    faMessageSlash as fasMessageSlash,
    faPause as fasPause,
    faPhoneSlash as fasPhoneSlash,
    faPhoneVolume as fasPhoneVolume,
    faPlay as fasPlay,
    faRotateRight as fasRotateRight,
    faStars as fasStars,
    faTrophyStar as fasTrophyStar,
    faUserClock as fasUserClock,
    faUserHair as fasUserHair,
    faUserHairLong as fasUserHairLong,
} from '@fortawesome/pro-solid-svg-icons'

// Pro Regular icons
import {
    faBell as farBell,
    faCakeCandles as farCakeCandles,
    faCheck as farCheck,
    faCheckDouble as farCheckDouble,
    faCircle as farCircle,
    faCircleCheck as farCircleCheck,
    faCircleExclamation as farCircleExclamation,
    faCircleXmark as farCircleXmark,
    faEllipsisVertical as farEllipsisVertical,
    faFaceFrown as farFaceFrown,
    faFaceMeh as farFaceMeh,
    faFaceSleeping as farFaceSleeping,
    faFaceSmile as farFaceSmile,
    faFaceSmilePlus as farFaceSmilePlus,
    faGift as farGift,
    faHeart as farHeart,
    faImage as farImage,
    faMessageLines as farMessageLines,
    faMessageXmark as farMessageXmark,
    faMessages as farMessages,
    faMobileScreenButton as farMobileScreenButton,
    faPhone as farPhone,
    faXmark as farXmark,
} from '@fortawesome/pro-regular-svg-icons';

// Pro Light icons
import {
    faBan as falBan,
    faBrowser as falBrowser,
    faBuildingColumns as falBuildingColumns,
    faCalendarStar as falCalendarStar,
    faCamera as falCamera,
    faChevronDown as falChevronDown,
    faChevronLeft as falChevronLeft,
    faChevronRight as falChevronRight,
    faCircleChevronRight as falCircleChevronRight,
    faCircleExclamation as falCircleExclamation,
    faCircleInfo as falCircleInfo,
    faCircleQuestion as falCircleQuestion,
    faCircleUser as falCircleUser,
    faCircleXmark as falCircleXmark,
    faCity as falCity,
    faEllipsisVertical as falEllipsisVertical,
    faEnvelope as falEnvelope,
    faEnvelopeCircleCheck as falEnvelopeCircleCheck,
    faFaceSadTear as falFaceSadTear,
    faFaceSmile as falFaceSmile,
    faHashtag as falHashtag,
    faIdCard as falIdCard,
    faLink as falLink,
    faLocationDot as falLocationDot,
    faMagnifyingGlass as falMagnifyingGlass,
    faMailbox as falMailbox,
    faMessageLines as falMessageLines,
    faMicrophoneLines as falMicrophoneLines,
    faNewspaper as falNewspaper,
    faPaperPlane as falPaperPlane,
    faPenToSquare as falPenToSquare,
    faRoad as falRoad,
    faStar as falStar,
    faSterlingSign as falSterlingSign,
    faTrashCan as falTrashCan,
    faXmark as falXmark,
} from '@fortawesome/pro-light-svg-icons';

library.add(
    fabApple,
    fabFacebookF,
    fabInstagram,
    fabLinkedinIn,
    fabPinterestP,
    fabTiktok,
    fabTwitter,
    fabYoutube,
    falBan,
    falBrowser,
    falBuildingColumns,
    falCalendarStar,
    falCamera,
    falChevronDown,
    falChevronLeft,
    falChevronRight,
    falCircleChevronRight,
    falCircleExclamation,
    falCircleInfo,
    falCircleQuestion,
    falCircleUser,
    falCircleXmark,
    falCity,
    falEllipsisVertical,
    falEnvelope,
    falEnvelopeCircleCheck,
    falFaceSadTear,
    falFaceSmile,
    falHashtag,
    falIdCard,
    falLink,
    falLocationDot,
    falMagnifyingGlass,
    falMailbox,
    falMessageLines,
    falMicrophoneLines,
    falNewspaper,
    falPaperPlane,
    falPenToSquare,
    falRoad,
    falStar,
    falSterlingSign,
    falTrashCan,
    falXmark,
    farBell,
    farCakeCandles,
    farCheck,
    farCheckDouble,
    farCircle,
    farCircleCheck,
    farCircleExclamation,
    farCircleXmark,
    farEllipsisVertical,
    farFaceFrown,
    farFaceMeh,
    farFaceSleeping,
    farFaceSmile,
    farFaceSmilePlus,
    farGift,
    farHeart,
    farImage,
    farMessageLines,
    farMessageXmark,
    farMessages,
    farMobileScreenButton,
    farPhone,
    farXmark,
    fasBasketShopping,
    fasBell,
    fasCamera,
    fasCaretDown,
    fasCaretUp,
    fasChevronDown,
    fasCircleCheck,
    fasCircleChevronRight,
    fasCircleExclamation,
    fasCircleHeart,
    fasCircleInfo,
    fasCirclePhoneFlip,
    fasCirclePlus,
    fasCircleQuestion,
    fasEllipsisVertical,
    fasEnvelope,
    fasEnvelopeOpenText,
    fasExclamation,
    fasFaceSmilePlus,
    fasGem,
    fasGift,
    fasHeart,
    fasLock,
    fasMessage,
    fasMessageLines,
    fasMessageSlash,
    fasNoteSticky,
    fasPaperPlane,
    fasPause,
    fasPhone,
    fasPhoneSlash,
    fasPhoneSquareAlt,
    fasPhoneVolume,
    fasPlay,
    fasRotateRight,
    fasShareNodes,
    fasSpinner,
    fasSquare,
    fasStar,
    fasStars,
    fasTimesCircle,
    fasTriangleExclamation,
    fasTrophy,
    fasTrophyStar,
    fasUser,
    fasUserCheck,
    fasUserClock,
    fasUserHair,
    fasUserHairLong,
    fasUserPlus,
    fasUsers,
)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
