<template>
    <div v-if="isImage && repeat" :class="backgroundClass" :style="backgroundStyle"></div>
    <img v-else-if="isImage" :class="backgroundClass" :style="backgroundStyle" :src="imageSrc"/>

    <video v-else-if="isVideo" :class="backgroundClass" :style="backgroundStyle" autoplay muted loop>
        <source v-if="hasWebm" :src="webmSrc" type="video/webm">
        <source v-if="hasMp4" :src="mp4Src" type="video/mp4">
    </video>

    <div v-else-if="isColor" :class="backgroundClass" :style="backgroundStyle"></div>

    <game-wheel-background-snowfall v-else-if="isSnowfall" :class="backgroundClass" :style="backgroundStyle"/>
</template>

<script>
import GameWheelBackgroundSnowfall from "./GameWheelBackgroundSnowfall";

export default {
    name: "GameWheelBackground",
    components: {GameWheelBackgroundSnowfall},
    props: {
        color: String,
        fullscreen: Boolean,
        image: String,
        scale: Number,
        snowfall: Boolean,
        spin: Boolean,
        repeat: Boolean,
        video: Object,
    },
    computed: {
        isImage() {
            return !!this.image;
        },
        backgroundClass() {
            return {
                'absolute w-full h-full top-0 left-0': !this.fullscreen,
                'fixed object-cover w-full h-full top-0 left-0': this.fullscreen,
                'transform scale-125': this.scale === 125,
                'transform scale-150': this.scale === 150,
                'transform scale-200': this.scale === 200,
                'animate-wheel-background-spin': this.spin,
                'pointer-events-none select-none': true,
            }
        },
        backgroundStyle() {
            return {
                'background-color': this.color,
                'background-repeat': this.repeat ? 'repeat' : null,
                'background-image': this.repeat ? 'url(' + this.imageSrc + ')' : null,
            }
        },
        isVideo() {
            return !!this.video;
        },
        isSnowfall() {
            return this.snowfall;
        },
        imageSrc() {
            return this.urlManager.isUrl(this.image) ? this.image : this.assetManager.getImageAsset(this.image);
        },
        hasWebm() {
            return !!this.video.webm;
        },
        webmSrc() {
            return this.urlManager.isUrl(this.video.webm) ? this.video.webm : this.assetManager.getImageAsset(this.video.webm);
        },
        hasMp4() {
            return !!this.video.mp4;
        },
        mp4Src() {
            return this.urlManager.isUrl(this.video.mp4) ? this.video.mp4 : this.assetManager.getImageAsset(this.video.mp4);
        },
        isColor() {
            return !!this.color;
        }
    }
}
</script>
