<template>
    <div>
        <div class="payment-method-paypal">
            <div class="flex-none">
                <img :src="assetManager.getImageAsset('payment/brands/paypal.png')"
                     class="h-8 inline-block" alt="Paypal"/>
            </div>
            <div class="flex-grow overflow-ellipsis overflow-hidden">
                <span><strong>{{ paypal.email }}</strong></span>
            </div>
            <base-button class="flex-none" color="danger" outlined @click="toggleDeleteModal(true)">
                <font-awesome-icon :icon="['fal', 'trash-can']"/>
            </base-button>
        </div>

        <base-modal v-if="showDeleteModal" @close="toggleDeleteModal(false)" small>
            <base-title>{{ trans.get('payment.remove_paypal') }}</base-title>

            <div class="payment-method-paypal">
                <div class="flex-none">
                    <img :src="assetManager.getImageAsset('payment/brands/paypal.png')"
                         class="h-8 inline-block" alt="Paypal"/>
                </div>
                <div class="flex-grow overflow-ellipsis overflow-hidden">
                    <span><strong>{{ paypal.email }}</strong></span>
                </div>
            </div>

            <div class="grid grid-cols-1 gap-4 mt-5">
                <base-button :loading="loading" color="danger" @click="deletePaypal">
                    <font-awesome-icon :icon="['fal', 'trash-can']"/>&nbsp;{{ trans.get('payment.remove') }}
                </base-button>
            </div>
        </base-modal>
    </div>
</template>

<style lang="scss">
.payment-method-paypal {
    @apply flex items-center bg-white border border-neutral-500 rounded-lg p-2;

    span {
        @apply text-main whitespace-nowrap;
    }
}
</style>

<script>
import BaseList from "../elements/BaseList.vue";
import BaseListItem from "../elements/BaseListItem.vue";
import BaseTitle from "../elements/BaseTitle.vue";
import axios from "axios";

export default {
    name: "PaymentMethodPaypal",
    components: {BaseTitle, BaseList, BaseListItem},
    props: {
        paypal: {
            type: Object,
        }
    },
    methods: {
        toggleDeleteModal(showModal) {
            if (showModal !== undefined) {
                this.showDeleteModal = showModal;
            } else {
                this.showDeleteModal = !this.showDeleteModal;
            }
        },
        deletePaypal() {
            this.loading = true;

            let formData = new FormData();
            formData.append('paypal_id', this.paypal.id);
            axios.post('/ajax/del_paypal.js', formData).then(response => {
                location.reload();
            });
        }
    },
    data: function () {
        return {
            showDeleteModal: false,
            loading: false,
        }
    },
}
</script>
