<template>
    <base-modal fullscreen dense disable-closing>
        <chat/>
    </base-modal>
</template>

<script>

export default {
    name: "ModalChat",
    methods: {
        sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        }
    },
    created() {
        if (window.globalVue !== undefined && window.globalVue.$store !== undefined && window.globalVue.$store.getters['chat/getConversations'].length === 0) {
            window.globalVue.$store.dispatch('chat/loadConversations');
            this.sleep(1000).then(() => {
                let firstConversation = window.globalVue.$store.getters['chat/getConversations'][0];
                window.globalVue.$store.dispatch('chat/selectParticipants', firstConversation.participants);
            });
        }
    }
}
</script>

