<template>
    <development>
        <div>
            <development-heading>Title</development-heading>
            <base-title>Base title</base-title>

            <development-heading :level="2">Props :</development-heading>
            <ul>
                <li><strong>level</strong>: integer - <em>1 (default) / 2 / 3 / 4 / 5 / 6</em></li>
                <li><strong>color</strong>: String - <em>primary (default) / secondary / available / busy / neutral /
                    light / white / danger / prime / whatsapp</em></li>
                <li><strong>center</strong>: Boolean</li>
            </ul>

            <development-heading>Level</development-heading>
            <development-paragraph><strong>level</strong> : 1 (default) / 2 / 3 / 4 / 5 / 6</development-paragraph>
            <div class="space-y-2">
                <base-title :level="1">Title level 1</base-title>
                <base-title :level="2">Title level 2</base-title>
                <base-title :level="3">Title level 3</base-title>
                <base-title :level="4">Title level 4</base-title>
                <base-title :level="5">Title level 5</base-title>
                <base-title :level="6">Title level 6</base-title>
            </div>

            <development-heading>Colors</development-heading>
            <development-paragraph><strong>color</strong> : primary (default) / secondary / available / busy / neutral /
                light / white / danger / prime / whatsapp
            </development-paragraph>
            <div class="space-y-2">
                <base-title color="primary">Title primary</base-title>
                <base-title color="secondary">Title secondary</base-title>
                <base-title color="available">Title available</base-title>
                <base-title color="busy">Title busy</base-title>
                <base-title color="neutral">Title neutral</base-title>
                <base-title color="light">Title light</base-title>
                <base-title color="white">Title white</base-title>
                <base-title color="danger">Title danger</base-title>
                <base-title color="prime">Title prime</base-title>
                <base-title color="whatsapp">Title whatsapp</base-title>
            </div>

            <development-heading>Center</development-heading>
            <base-title center>Title centered</base-title>
        </div>
    </development>
</template>
<script>
import Development from "./Development";
import BaseAlert from "../elements/BaseAlert";
import DevelopmentHeading from "./elements/DevelopmentHeading";
import DevelopmentParagraph from "./elements/DevelopmentParagraph";
import BaseButton from "../elements/BaseButton";
import BaseTimer from "../elements/BaseTimer";
import BaseTitle from "../elements/BaseTitle";

export default {
    components: {BaseTitle, BaseTimer, BaseButton, DevelopmentParagraph, DevelopmentHeading, BaseAlert, Development}
}
</script>
