<template>
    <h1 class="text-primary text-xl font-bold mb-4 leading-6" v-if="level === 1">
        <slot></slot>
    </h1>
    <h2 class="text-primary text-lg font-bold mb-2" v-else-if="level === 2">
        <slot></slot>
    </h2>
    <h3 v-else-if="level === 3">
        <slot></slot>
    </h3>
    <h4 v-else-if="level === 4">
        <slot></slot>
    </h4>
    <h5 v-else-if="level === 5">
        <slot></slot>
    </h5>
    <h6 v-else-if="level === 6">
        <slot></slot>
    </h6>
</template>

<script>
export default {
    name: "ModalHeading",
    props: {
        level: {
            type: Number,
            default: 1,
            validator: function (value) {
                return [1, 2, 3, 4, 5, 6].indexOf(value) !== -1
            }
        }
    }
};
</script>
