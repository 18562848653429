<template>
    <base-modal>
        <modal-heading>{{ trans.get('generic.modals.follow_up_type_choice_title') }}</modal-heading>

        <modal-paragraph v-html="this.trans.get('generic.modals.follow_up_type_choice_text')"></modal-paragraph>

        <div class="grid grid-cols-1">
            <base-button :disabled="!getLoggedUserInfos['can_send_follow_up_to_all']" @click="openFollowUpToAllModal">
                <font-awesome-icon :icon="['fas', 'users']" />
                {{ trans.get('generic.modals.follow_up_type_choice_all_clients') }}
            </base-button>
        </div>

        <base-separator>{{ trans.get('generic.or') }}</base-separator>

        <div class="grid grid-cols-1">
            <base-button @click="openFollowUpToOneModal">
                <font-awesome-icon :icon="['fas', 'user']" />
                {{ trans.get('generic.modals.follow_up_type_choice_one_client') }}
            </base-button>
        </div>
    </base-modal>
</template>

<script>
import ModalHeading from './elements/ModalHeading';
import ModalParagraph from './elements/ModalParagraph';
import BaseButton from '../elements/BaseButton';
import BaseSeparator from '../elements/BaseSeparator';
import {mapActions, mapGetters} from 'vuex';

export default {
    name: 'ModalFollowUpTypeChoice',
    components: {BaseSeparator, BaseButton, ModalParagraph, ModalHeading},
    methods: {
        ...mapActions('modals', ['openModal']),
        openFollowUpToAllModal()
        {
            this.openModal({
                name: globals.FOLLOW_UP_TO_ALL,
            });
        },
        openFollowUpToOneModal()
        {
            this.openModal({
                name: globals.FOLLOW_UP_TO_ONE,
            });
        },
    },
    computed: {
        ...mapGetters('user', ['getLoggedUserInfos']),
    },
};
</script>

<style scoped>

</style>
